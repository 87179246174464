.groupSlider {

}

.sliderProduct {
	width: calc(25% - 7.5px)!important;
	margin-right: 10px!important;
}

.sliderProduct:last-child {
	margin-right: 0px!important;
}

.sliderProduct._1 {
	width: calc(50% - 5px)!important;
}
