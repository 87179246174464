.conatiner {
	width: 100%;
	padding-top: 30px;
	padding-bottom: 20px;
	overflow: hidden;
}

.title {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	letter-spacing: .2em;
	font-weight: 600;
	color: var(--main-grey);
	text-align: center;
}

.conatiner :global .scl-product.combine .look-modal {
	bottom: -15px;
}

.combine {
	position: relative;
	margin-right: 20px;
}

@media screen and (max-width: 768px) {
	.conatiner :global .scl-product.combine .look-modal {
		bottom: -10px;
	}
}
