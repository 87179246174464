.groupSlider :global .swiper-container {
	padding-bottom: 50px;
	margin-bottom: 30px;
}

.title {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	letter-spacing: .2em;
	font-weight: 600;
	color: var(--main-grey);
	text-align: center;
}

.sliderProduct {
	width: 20%;
	padding: 5px;
}

.sliderProduct :global .old {
	display: inline-block;
	margin-right: 10px;
	position: relative;
	padding: 0 5px;
}

.sliderProduct :global .old::before {
	content: '';
	background: rgba(0,0,0,0.5);
	width: 100%;
	height: 1px;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-45%);
}

.sliderProduct :global .old + span {
	display: inline-block;
	margin-top: 8px;
}

.sliderProduct:hover img {
	transform: scale(1.02);
	transition: 700ms;
}

.sliderProduct span {
	display: block;
	margin-top: 5px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.sliderProduct span:last-child {
	font-weight: 600;
	font-size: 15px;
}

._1 {
	width: calc(50% - 5px);
}
