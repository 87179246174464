.title {
	font-size: 2.4em;
	margin-bottom: 1em;
}

.subscribeContainer {
	justify-content: center;
	align-items: center;
	height: 500px;
}

.checkLabel {
	position: relative;
	padding-left: 35px;
	margin: 0 5px;
	width: 100%;
	text-align: left;
	display: block;
	float: left;
	padding: 0;
	padding-left: 30px;
	cursor: pointer;
}

.checked {
	padding: 0;

}

.success {
	background: var(--main-green);
	color: #fff;
	padding: 20px 0;
	font-weight: 500;
	font-size: 1.5em;
	text-align: center;
	border-radius: 5px;
}


.tick {
	background: #f8f8f8;
	width: 50px;
	height: 50px;
	color: var(--main-green);
	margin-right: 30px;
	border-radius: 50%;
}
