.nonItem {
	width: 100%;
	min-height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.nonItem .container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 400px;
}

.nonItem span {
	font-size: 1.3em;
	font-weight: 500;
	margin: 20px;
	display: block;
}

.nonItem svg {
	font-size: 4em;
}
