:root {
	--black: #000;
	--white: #fff;
	--theme-color: #ef6454;
	--main-color: #000;
	--main-grey: #5a5a5a;
	--main-lightgrey: #ddd;
	--main-blue: #7584d0;
	--main-green: #1cab81;
	--main-red: #ff4545;
	--main-active: #f63a6b;
	--main-hover: #1cab81;
	--form-border: #d0d0d0;
	--quick-silver: #000;
	--button-color: #000;
	--swiper-pagination-color: #7a7a7a
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;

}

html  {
	height: 100%;
	width: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
}

body {
	color: var(--main-grey);
	overflow: auto;
	scroll-behavior: smooth;
	position: relative;
}

a {
	display: inline-block;
	color: var(--main-color);
	-webkit-transition: color .2s ease-in;
	transition: color .2s ease-in;
	text-decoration: none;
	cursor: pointer;
}

ul {
	padding-left: 0;
	list-style-type: none;
}

img {
	max-width: 100%;
	height: auto;
	display: block;
}

iframe {
	max-width: 100%;
	width: 100%;
	height: calc(((100vw * 0.8) * 0.75) * 0.56);
}

pre {
	font-family: 'Poppins', sans-serif;
	white-space: pre-wrap;
}

.tabs,
.choices-select,
.input-number,
#detail .detail-right .sizes,
.header .head,
.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

input:-ms-input-placeholder,
input::-ms-input-placeholder,
input::-webkit-input-placeholder,
input::placeholder {
	color: var(--main-grey);
}

input {
	outline: none;
}

h1,
h2,
h3,
h4,
h5,
b,
p {
	font-weight: 400;
}

h1,
h2,
h3,
h4,
p {
	line-height: 1.6em;
}

strong {
	font-weight: 700;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number],
input[type=password],
input[type=tel],
input[type=text],
textarea {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: textfield;
}

::-moz-focus-inner {
	padding: 0;
	border: 0;
}

::-webkit-input-placeholder {
	color: #a6a6a8;
}

:-moz-placeholder {
	color: #a6a6a8;
	opacity: 1;
}

::-moz-placeholder {
	color: #a6a6a8;
	opacity: 1;
}

::-ms-input-placeholder {
	color: #a6a6a8;
}

.file-upload {
	border: 2px dashed #ccc;
	background: white;
	font-size: 1.4em;
	margin: 0 12px;
	width: calc(100% - 24px);
}
.file-upload span {
	padding: 2em;
	text-align: center;
	cursor: pointer;
	display: block;
}

.file-upload input[type=file]{
	opacity: 1;
	z-index: -1;
	position: absolute;
}

button,
button:focus {
	outline: none;
	border-radius: unset;
}

.base-light {
	font-weight: 400;
}

.base-regular {
	font-weight: 500;
}

.base-bold {
	font-weight: 700;
}

.modal-open {
	padding-right: 17px!important;
	overflow-x: hidden;
	overflow-y: hidden;
	max-height: 100vh;
}

.head {
	color: var(--main-color);
}

.container {
	position: relative;
}

.pointer{
	cursor: pointer
}

.absolute {
	position: absolute;
}
h2.hr {
	color: var(--main-grey);
	position: relative;
	margin-bottom: 1em;
	text-align: center;
}

h2.hr::before {
	content: '';
	width: 108%;
	height: 2px;
	background: var(--main-lightgrey);
	position: absolute;
	top: 50%;
	left: -4%;
	z-index: 1;
}

.grey {
	color: var(--main-grey);
}

.green {
	color: var(--main-green);
}

.blue {
	color: var(--main-blue);
}

.red {
	color: var(--main-red);
}

.orange {
	color: #ff6400!important;
}

.danger {
	color: var(--main-red);
}

h2.hr span {
	position: relative;
	background: white;
	padding: 0 95px;
	letter-spacing: 1em;
	z-index: 2;
}

h3.hr {
	color: var(--main-grey);
	position: relative;
	text-align: center;
}

h3.hr::before {
	content: '';
	width: 100%;
	height: 1px;
	background: var(--main-lightgrey);
	position: absolute;
	top: 55%;
	left: 0;
	transform: translateY(-50%);
	z-index: 1;
}

h3.hr span {
	position: relative;
	background: white;
	padding: 0 30px;
	z-index: 2;
	letter-spacing: .03em;
}

span.hr {
	clear: both;
	height: 1px;
	background: var(--main-lightgrey);
	display: block;
	margin: 3% auto;
}

.r45 {
	transform: rotate(45deg);
}

.r90 {
	transform: rotate(90deg);
}

.r180 {
	transform: rotate(180deg);
}

.r270 {
	transform: rotate(270deg);
}

.r180,
.r270,
.r45,
.r90 {
	display: inline-block;
}
/* FORM */
form {
	letter-spacing: .03em;
}

form .form-label {
	color: var(--main-grey);
	line-height: 1.6em;
	margin: 7px 0;
	position: relative;
	display: block;
	text-align: left;
	text-transform: uppercase;
}

form .form-control {
	padding: 0 20px;
	width: 100%;
	box-sizing: border-box;
	height: 50px;
	line-height: 1.4em;
	border: 1px solid var(--main-lightgrey);
	border-radius: 0;
	color: var(--main-grey);
}

form textarea.form-control {
	padding: 15px 20px;
	height: auto;
}

form .form-control:focus {
	box-shadow: unset;
	border-color: var(--main-lightgrey);
}

form .form-control.red {
	color: var(--main-red);
	border-color: currentColor;
}

form .form-control.red::placeholder {
	color: var(--main-red);
	opacity: .9
}

form .error {
	width: 100%!important;
	text-align: left;
	margin-bottom: 10px;
	display: block;
}

.form-btn {
	text-align: center;
	margin: 40px 0!important;
}

.btn {
	background: var(--button-color);
	border-radius: 0;
	border: 1px solid var(--button-color);
	color: var(--white);
	outline: 0;
	box-shadow: none!important;
	transition: 300ms;
	padding: 10px 20px;
}

.btn:hover,
button:hover {
	background-color: transparent;
	color: #000;
}

.btn:active {
	background: var(--white);
	border: 1px solid var(--main-active);
	color: var(--main-active);
}

.btn-reverse {
	background: transparent;
	color: var(--main-color);
}

.btn-small {
	padding: 5px 8px;
	font-size: .9em;
}

.form-check {
	position: relative;
	font-size: 14px;
	padding-left: 30px;
}
.form-check-label {
	cursor: pointer;
	line-height: 1.5;
}
input[type=checkbox] {
	display: none!important;
}

input[type=checkbox] + span {
	width: 16px;
	height: 16px;
	display: block;
	background-color: var(--white);
	cursor: pointer;
	border: 1px solid var(--main-grey);
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-60%);
}

input[type=checkbox] + span svg {
	opacity: 0;
	line-height: 1;
	color: currentColor;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	-webkit-transition: opacity .2s cubic-bezier(0.4, 0, 1, 1);
	transition: opacity .3s cubic-bezier(0.4, 0, 1, 1);
}

input[type=checkbox]:checked + span {
	border: 1px solid var(--main-grey);
}

input[type=checkbox]:checked + span svg {
	opacity: 1;
}

.modal-backdrop.show {
	opacity: .3!important;
}

.scl-select {
	width: 100%;
	height: 50px;
	line-height: 50px;
	border: 1px solid var(--form-border);
	position: relative;
	font-size: 16px;
}

.scl-select span:nth-child(1) {
	white-space: nowrap;
}

.scl-select span:nth-child(2) {
	float: right;
	padding-right: 25px;
	color: var(--main-hover);
}

.scl-select .selected-item {
	padding: 0 10px;
	cursor: pointer;
}

.scl-select .selected-item.only-text {
	height: 48px;
	line-height: 48px;
}

.scl-select .selected-item.active + .scl-select-options {
	display: block;
}

.scl-select .selected-item span:nth-child(2):after {
	content: '';
	border: 5px solid transparent;
	border-top-color: black;
	position: absolute;
	top: 50%;
	right: 10px;
}

.scl-select-options {
	background: white;
	width: calc(100% + 2px);
	max-height: 300px;
	border: 1px solid var(--main-lightgrey);
	box-shadow: 0 1px 0 0 rgba(0,0,0,.5);
	position: absolute;
	top: 48px;
	left: -1px;
	z-index: 10;
	overflow: auto;
	display: none;
}

.scl-select-options .scl-select-item {
	padding: 0 10px;
	cursor: pointer;
}

.scl-select-options.only-text .scl-select-item {
	height: 50px;
	line-height: 50px;
}

.scl-select-options .scl-select-item.active span:nth-child(2) {
	color: white;
}

.scl-select-options .scl-select-item.active {
	background: var(--main-grey);
	color: white;
}

.scl-select-options .scl-select-item.not span {
	color: var(--main-lightgrey);
}

.scl-select .danger span:nth-child(2) {
	color: red;
}

.input-number {
	width: 70px;
	margin-right: 2%;
	position: relative;
}

.input-number svg {
	margin-right: 0!important;
}

input[type=number] {
	width: 100%;
	height: 46px;
	padding: 0 20px;
	text-align: center;
	border: 1px solid var(--form-border);
}

input[type=number] + span,
input[type=number] + span + span {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	line-height: 46px;
	border: 1px solid var(--form-border);
	color: var(--quick-silver);
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	transition: 300ms;
}

input[type=number] + span + span {
	right: auto;
	left: 0
}

input[type=number] + span + span:active,
input[type=number] + span:active {
	color: var(--main-active);
}

.input {
	position: relative;
}

.show-password {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	padding: 15px;
	z-index: 25;
}

.input-button {
    position: absolute;
    left: 82%;
    top: 0;
    bottom: 0;
    height: 40px;
    margin: auto;
    font-size: 30px;
    cursor: pointer;
    color: #ccc;
		z-index: 5;
}

/** FORM **/
/* MODAL */
.successMessage, .errorMessage {
	padding: 20px 0 20px 70px;
	background: var(--main-green);
	color: #fff;
	position: relative;
	line-height: 1.5;
	text-align: left;
}

.errorMessage {
	background: var(--main-red);
	text-align: left;
}

.paymentError {
	font-size: 1.6em;
	border-radius: 3px;
}

.successMessage svg, .errorMessage svg {
	position: absolute;
	top: 50%;
	left: 17px;
	transform: translateY(-50%);
	font-size: 2.2rem;
	color: white;
}

.modal-backdrop.show {
	opacity: .8;
}

.scl-modal {
	width: 50%;
	max-height: 100vh;
	overflow-x: unset;
}

.scl-modal .modal-content {
	padding: 40px;
	padding-bottom: 20px;
	background-color: var(--white);
	border: 3px solid var(--main-hover);
	box-sizing: border-box;
	border-radius: 0;
	position: absolute;
	overflow: unset;
	top: 0;
	left: 0;
	transition: margin 500ms;

}

.scl-modal .scl-modal-close {
	width: 44px;
	height: 44px;
	line-height: 1.1em;
	position: absolute;
	right: -20px;
	top: -20px;
	font-size: 38px;
	text-align: center;
	color: var(--main-color);
	background-color: var(--white);
	border: 3px solid var(--main-hover);
	border-radius: 50%;
	box-sizing: border-box;
	font-family: Arial, Baskerville, monospace;
	transition: 0s;
	cursor: pointer;
	z-index: 10;
}

.scl-modal .modal-content .modal-body {
	padding: 0;
	overflow: visible;
}

.scl-modal a {
	transition: 500ms color;
}

.scl-modal .modal-content .scl-modal-close:hover {
	color: var(--white);
	background-color: var(--main-active);
}

.nav-tabs .nav-item {
	width: 50%;
}

.nav-tabs .nav-link {
	border: none;
	text-align: center;
	padding-bottom: 15px;
	color: var(--quick-silver);
}

#basket .nav-tabs .nav-link {
	font-size: 1.15em;
}

.nav-tabs .nav-link.active {
	border-bottom: 2px solid var(--main-color);
	color: var(--main-color);
}

.nav-tabs .nav-link:hover {
	color: var(--main-color);
}

.scl-modal .modal-content h2 {
	text-align: center;
	width: 100%;
	box-sizing: border-box;
	color: var(--quick-silver);
	padding: 3.7% 0;
	position: relative;
	margin-bottom: 5%;
	line-height: 1.6em;
}

.scl-modal .fb-btn {
	text-align: center;
}

.scl-modal .fb-btn button {
	display: inline-block;
	box-sizing: border-box;
	width: 313px;
	max-width: 100%;
	height: 57px;
	color: var(--white);
	background: url("/img/facebook-login.png") no-repeat;
	outline: none;
	border: none;
	box-shadow: none;
}

.scl-modal .fb-btn button:hover {
	background-position: left -57px;
}
/* LOGIN MODAL */
#loginModal  {
	z-index: 1051;
}

#loginModal .scl-modal {
	width: 500px;
}
/** LOGIN MODAL **/
/* QUİCK MODAL */

.quickModal {
	overflow: hidden;
}

.quickModal .scl-modal {
	width: 980px;
	max-width: 980px;
}

.quickModal .scl-modal .modal-content {
	padding: 1%;
	height: 100%;
	max-height: 525px;
}

.quickModal .scl-modal .modal-content .modal-body {
	overflow-x: hidden;
	overflow-y: auto;
}

.quickModal .container {
	width: 100%;
}

.quickModal #detail {
	margin-top: 15px;
}

.quickModal #detail .detail-left .thump-slider .thump-image li {
	height: calc(980px * .48 * .9 * .2 * 1.25);
}

.quickModal #detail .detail-left .big-image {
	height: calc((980px * 0.50 * 0.80) * 1.25);
}

#detail .go-detail {
	margin-top: 10px;
	float: right;
	text-transform: uppercase;
	letter-spacing: .5px;
	font-weight: 500;
	position: relative;
}

#detail .go-detail span {
	transition: 500ms;
	margin-right: 10px;
}

#detail .go-detail:hover span {
	margin-left: 10px;
	margin-right: 0;
}

.quickModal #detail .detail-right {
	padding-right: 20px;
	width: 50%;
	padding-bottom: 0;
	height: 470px;
	overflow-y: scroll;
}

.quickModal #detail .detail-right .social {
	display: none;
}

.quickModal #detail .detail-content .prod-tabs {
	width: 100%;
}

.quickModal #detail .detail-content .tab-content{
	margin-top: 0;
}

.quickModal .detail-right .thump-slider .up, .quickModal .detail-right .thump-slider .down {
	display: none;
}
.quickModal .detail-right .services ul li:first-child {
	width: 50%!important;
}
/** QUİCK MODAL **/

.word-show  {
	z-index: 1052;
}

/* COMBİNE MODAL */

.combimeModal .scl-modal .modal-content {
	overflow: hidden;
}

.combimeModal .scl-modal .modal-content {
	padding: 0;
	height: 100%;
	max-height: 675px;
}

.combimeModal .scl-modal {
	width: 1080px;
	max-width: 1080px;
}

.combimeModal #detail {
	width: 950px;
	float: right;
	margin-top: 15px;
}

.combimeModal .scl-modal .scl-modal-close {
	top: 0;
	right: 0;
}

.combimeModal .combine-products {
	width: 125px;
	float: left;
	padding: 45px 5px 0 5px;
	overflow: auto;
	height: 650px;
	background: #f9f9f9;
}

.combimeModal .combine-products span.active {
	border: 1px solid #000;
}

.combimeModal .combine-products::after {
	content: 'ÜRÜNLERİ KEŞFET';
	background: #000;
	color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	width: 125px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	letter-spacing: 2px;
	font-size: 10px;
	white-space: nowrap;
}

.combimeModal .combine-products span {
	margin-bottom: 5px;
	cursor: pointer;
	display: block;
}

.combimeModal .scl-modal .modal-content .modal-body {
	overflow-x: hidden;
	overflow-y: scroll;
}

.combimeModal .container {
	width: 100%;
}

.combimeModal .detail-right {
	padding-right: 20px;
	max-height: 650px;
	overflow: scroll;
	padding-bottom: 150px
}

.combimeModal #detail .detail-left .big-image {
	width: 100%;
	height: calc((1080px - 130px) * .48 * 1.25);
}

#detail .detail-left .big-image img {
	width: 100%!important;
}

.combimeModal #detail .detail-left .thump-slider {
	text-align: center;
	width: 100%;
	padding-top: 10px;
}

.combimeModal #detail .detail-left .thump-image li img {
	display: none;
}

.combimeModal #detail .detail-left .thump-slider .thump-image li {
	width: 12px;
	height: 12px;
	border: 1px solid #000;
	border-radius: 50%;
	overflow: hidden;
	display: inline-block;
}
.combimeModal #detail .detail-left .thump-slider .thump-image img { display: none}

.combimeModal #detail .detail-left .thump-image li.active {
	background: #000;
}

.combimeModal #detail .detail-left .thump-image li.active::before {
	display: none;
}

.combimeModal #detail .detail-left .thump-slider span.up, .combimeModal #detail .detail-left .thump-slider span.down {
	position: relative!important;
	display: inline-block;
	width: auto!important;
}
/** COMBİNE MODAL **/
/* QUESTİON MODAL */
#questionModal .scl-modal .modal-content {
	padding-top: 2%;
}
/** QUİCK MODAL **/
/* PAYMENT MODAL */
#paymentOptionsModal .scl-modal {
	width: 65%;
	max-width: 65%;
}

#paymentOptionsModal .bank {
	border: 2px solid #ddd;
	padding: 10px 20px;
	text-align: center;
	cursor: pointer;
}

#paymentOptionsModal .bank.active {
	border-color: #5e5e5e;
}

#paymentOptionsModal .bank img {
	display: inline-block;
}

#paymentOptionsModal .scl-modal .modal-content {
	padding-top: 2%;
}

#sizeChartModal .scl-modal {
	width: 60%;
	max-width: 65%;
}

#sizeChartModal h3 span.green {
	font-weight: 600;
}

#sizeChartModal .product-chart {
	font-size: 1.2rem;
	text-transform: uppercase;
	color: #000;
}

#sizeChartModal .product-chart li {
	margin: 0 1rem;
	opacity: .8;
	cursor: pointer;
}

#sizeChartModal .product-chart li.active {
	opacity: 1;
	font-weight: 500;
}

#paymentOptionsModal .table tr.active td {
	padding: 5px 15px;
}

.table {
	margin-top: 20px;
}

.table, .table td {
	border:1px solid #ddd;
	text-align: center;
	vertical-align: middle;
}

.table tr:nth-child(2n+1) {
	background: #fafafa;
}

.table tr.active,.table td.active {
	background: #eee;
}

.table tr.hover, .table td.hover {
	background: #ccc;
}

.table .active .hover, .table .hover .active {
	background: #2e2e2e;
	color: #fff;
}

.table tr td {
	padding: 15px 5px;
}

.table.small tr td {
	padding: 10px 5px;
}

/** PAYMENT MODAL **/
/* BASKET MODAL */

#basketModal {
	display: none;
}

#basketModal .content {
	position: fixed;
	width: 40%;
	height: 100%;
	top: 0;
	right: -100%;
	background: var(--white);
	border-left: 4px solid var(--main-hover);
	z-index: 27;
	overflow: scroll;
}

#basketModal .basket-bg {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background: rgba(0,0,0,.2);
	z-index: 26;
	opacity: 0;
}

#basketModal .basket-modal-close {
	width: 44px;
	height: 44px;
	line-height: 1.1em;
	position: absolute;
	top: 10px;
	right: 15px;
	font-size: 38px;
	text-align: center;
	color: var(--main-color);
	background-color: var(--white);
	border: 3px solid var(--main-hover);
	border-radius: 50%;
	box-sizing: border-box;
	font-family: Arial,Baskerville,monospace;
	transition: 0s;
	cursor: pointer;
	z-index: 10;
}

#basketModal .basket-empty {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-weight: 100;
	text-align: center;
	padding-top: 30px;
}

#basketModal .basket-empty svg {
	font-size: 5em;
}

#basketModal .basket-empty span {
	margin: 30px 0 0;
	display: block;
}

#basketModal .basket-empty .btn {
	padding: 15px 55px;
}

#basketModal .basket-empty .btn {
	padding: 15px 55px;
}

#basketModal .basket-info,
#basketModal .basket-product-list {
	width: 90%;
	padding: 60px 0 0 60px;
}

#basketModal .basket-info {
	width: 80%;
	padding: 20px 60px;
	margin: 10% 10% 0;
	/*border: 1px solid var(--main-lightgrey);*/
	top: -30px;
	position: relative;
}

#basketModal .basket-info h2 {
	border-bottom: 2px solid var(--main-color);
	padding: 0 0 15px;
	text-align: center;
}

#basketModal .basket-info .row:not(:first-child) {
	padding: 10px 0;
	border-bottom: 1px solid var(--main-lightgrey);
}

#basketModal .basket-info .row:not(:first-child) span {
	width: 60%;
	float: left;
	padding: 0;
}

#basketModal .basket-info .row:not(:first-child) span:nth-child(2) {
	text-align: right;
	width: 40%;
}

#basketModal .basket-info .row:not(:first-child) span small {
	display: block;
	margin-top: 5px;
	color: var(--main-grey);
}

#basketModal .row .col-5 a,
#basketModal .row .col-5 span {
	display: block;
	margin-bottom: 8px;
}

#basketModal .row .col-2.p-4 {
	padding: 5px 15px!important;
}

#basketModal .row.dashedTB .col-1 {
	text-align: right;
	padding-top: 25px;
}

#basketModal .row.dashedTB .col-6 span {
	padding-top: 7px;
	display: block;
}
/** BASKET MODAL **/
/** BASKET HOVER **/

#basketHover {
	background: var(--white);
	position: absolute;
	top: 47px;
	right: -60px;
	border-radius: 2px;
	transition: 300ms opacity ease-in;
	opacity: 0;
	z-index: 27;
	height: 0;
	overflow: hidden;
	opacity: 0;
}

header .user-menu .open-basket:hover {
	height: 50px;
}

header .user-menu .open-basket:hover #basketHover {
	width: 450px;
	height: auto;
	border: 1px solid var(--main-grey);
	overflow: visible;
	opacity: 1;
	min-height: 70px;
}

#basketHover::before, #basketHover::after {
	content: '';
	width: 8px;
	height: 8px;
	border: 8px solid var(--main-grey);
	display: block;
	transform: rotate(45deg);
	position: absolute;
	top: -8px;
	right: 80px;
	z-index: 30;
}

#basketHover::after {
	content: '';
	width: 8px;
	height: 8px;
	border: 8px solid white;
	top: -7px;
}

#basketHover .content > .row {
	margin: 0;
}

.basket-hover-bg {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	z-index: 20;
}

#basketHover .basket-modal-close {
	width: 44px;
	height: 44px;
	line-height: 1.1em;
	position: absolute;
	top: 10px;
	right: 15px;
	font-size: 38px;
	text-align: center;
	color: var(--main-color);
	background-color: var(--white);
	border: 3px solid var(--main-hover);
	border-radius: 50%;
	box-sizing: border-box;
	font-family: Arial,Baskerville,monospace;
	transition: 0s;
	cursor: pointer;
	z-index: 10;
}

#basketHover .basket-empty {
	position: relative;
	top: 0;
	left: 0;
	transform: none;
	padding: 20px 0;
	text-align: center;
}

#basketHover .basket-empty svg {
	font-size: 5em;
}

#basketHover .basket-empty span {
	margin: 30px 0;
	display: block;
}

#basketHover .basket-empty .btn {
	padding: 15px 55px;
}

#basketHover .basket-empty .btn {
	padding: 15px 55px;
}

#basketHover .basket-info,
#basketHover .basket-product-list {
	width: 100%;
	padding: 10px 20px 0 10px;
}

#basketHover .basket-product-list .dashedTB .col-3 {
	padding: 0!important;
}

#basketHover  .basket-info {
	width: 90%;
	padding: 0 40px;
	margin: 2em 5% 0;
	top: -20px;
	position: relative;
}

#basketHover .row:nth-child(2) .basket-info {
	margin: 0px 5%;
	top: -10px;
}

#basketHover .basket-info h2 {
	border-bottom: 2px solid var(--main-color);
	padding: 0 0 15px;
	text-align: center;
}

#basketHover .basket-info .row:not(:first-child) {
	padding: 10px 0;
	border-bottom: 1px solid var(--main-lightgrey);
}

#basketHover .basket-info .row:not(:first-child) span {
	width: 60%;
	float: left;
	padding: 0;
}

#basketHover .basket-info .row:not(:first-child) span:nth-child(2) {
	text-align: right;
	width: 40%;
}

#basketHover .basket-info .row:not(:first-child) span small {
	display: block;
	margin-top: 5px;
	color: var(--main-grey);
}

#basketHover .row .col-2.p-4 {
	padding: 5px 15px!important;
}

#basketHover .row.dashedTB .col-6 span {
	padding-top: 7px;
	display: block;
}

#basketHover .row.dashedTB .col-6 span.price span.old {
	display: none;
}
/** BASKET HOVER **/
/* WELCOME MODAL */
#welcomeModal .modal-dialog {
	width: 500px;
}

#welcomeModal .modal-content {
	margin-top: 50%;
}

#welcomeModal #country img,
#welcomeModal #language img {
	float: left;
	margin-right: 10px;
	max-width: 40px;
}

#welcomeModal #country .selected-item span,
#welcomeModal #language .selected-item span {
	align-items: center;
	display: flex;
}
/** WELCOME MODAL **/
/** MODAL **/
/* HEADER */

header {
	position: relative;
	top: 5px;
}

header .head {
	width: 100%;
}

header .head .container {
	height: 75px;
}

header .user-menu {
	float: right;
}

header .user-menu li > a,
header .user-menu li > .a {
	padding: 0;
	cursor: pointer;
}

header .user-menu .open-basket:hover {
	z-index: 25;
}

header .user-menu > ul > li:not(:first-child):before {
	content: '';
	height: 12px;
	width: 1px;
	background: #b9b9b9;
	position: absolute;
	top: 13px;
	left: -10px;
}

header .user-menu > a:after {
	content: '';
	vertical-align: middle;
	top: 50%;
	right: 0;
	width: 1px;
	height: 18px;
	background-color: var(--main-lightgrey);
	position: absolute;
	display: inline-block;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}

header .user-menu .list-inline-item {
	margin-right: 0;
}

header .user-menu .diff {
	padding: 0;
}

header .user-menu .diff::after {
	display: none;
}

header .user-menu .diff + span {
	margin: 0 2px;
}

header .user-menu .basket-button {
	position: relative;
}

header .user-menu .basket-button .notif {
	width: 15px;
	height: 15px;
	line-height: 15px;
	display: inline-block;
	text-align: center;
	margin-left: 5px;
	background: var(--main-green);
	border-radius: 50%;
	color: #fff;
	position: relative;
	top: 1px;
}

header .user-menu .diff:last-child {
	margin-right: 20px;
}

header .user-menu .login-user {
	position: relative;
}

header .user-menu .login-user a {
	position: relative;
}

header .user-menu .login-user a:active,
header .user-menu .login-user a:hover {
	color: var(--main-color);
}

header .user-menu .login-user a span::after {
	font-family: icomoon!important;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	position: absolute;
	top: 55%;
	left: calc(100% + 10px);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	content: "\e903";
	color: var(--black);
	width: auto;
	height: auto;
	background-color: transparent;
}

header .user-menu .login-user ul {
	background-color: var(--white);
	min-width: 170px;
	position: absolute;
	z-index: 21;
}

header .user-menu .login-user ul span.hr {
	margin: 10px auto;
}

header .user-menu .login-user ul > li {
	cursor: pointer;
}

header .user-menu .login-user ul a {
	transition: color .2s ease-in;
	color: var(--main-color);
	padding: 10px 0;
}

header .user-menu .login-user ul li:hover {
	font-weight: 500;
}

header .modal-search {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 140px;
	background: white;
	z-index: 10;
	opacity: 0;
	transition: .6s opacity ease;
	display: none;
	z-index: 25;
}

header .modal-search input:-ms-input-placeholder,
header .modal-search input::-ms-input-placeholder,
header .modal-search input::-webkit-input-placeholder,
header .modal-search input::placeholder {
	color: var(--main-grey);
}

header .modal-search input {
	position: absolute;
	top: 35px;
	left: calc(50% - 225px);
	width: 450px;
	padding: 10px 30px 10px 0;
	font-weight: 100;
	border-bottom: 2px solid var(--quick-silver)!important;
	color: var(--main-grey);
	outline: none;
}

header .modal-search i.icon-search {
	position: absolute;
	top: 48px;
	left: calc(50% + 200px);
	font-weight: 100;
	color: var(--main-grey);
}

header .modal-search .close-search {
	position: absolute;
	top: 15px;
	right: 30px;
	display: inline-block;
	vertical-align: middle;
}

header .modal-search .close-search svg {
	display: inline-block;
	vertical-align: middle;
	padding-left: 10px;
}

header .search-complete {
	width: 330px;
	background: white;
	position: absolute;
	top: 50px;
	left: -30px;
	padding-top: 10px;
	border-style: solid;
	border-width: 0 1px 5px 1px;
	border-color: #ddd;
}

header .search-complete .result {
	padding: 0 0 10px 10px;
	border-bottom: 1px solid #ddd;
	display: block;
}

header .search-complete .no-result {
	text-align: center;
	display: block;
	padding: 10px 10px 20px 10px;
}

header .search-complete .btn {
	width: calc(100% + 1px);
	padding: 7px;
	margin: 0 0 -1px -1px;
	border: none;
}

header .search-complete .sugges {
	margin-top: 10px;
	border-top: 1px solid #ddd;
}
header .search-complete .sugges span {
	display: block;
	margin: 10px
}

header .search-complete .sugges a {
	text-decoration: underline;
}

header .search-complete .scl-product:hover {
	background: rgba(0,0,0,.02);
}

header .search-complete .scl-product span:not(.images),
header .search-complete .scl-product .title {
	display: block;
}

header .search-complete .scl-product span.title {
	font-size: .9em;
	line-height: 1.3em;
	overflow: hidden;
}
header .search-complete .scl-product span, header .search-complete .scl-product div {
	margin-top: 5px;
	float: left;
}

header .search-complete .scl-product .old {
	position: relative;
	font-size: .9em;
	padding: 0 2px!important;
	display: block;
}

header .search-complete .scl-product .old:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 0px;
	width: 100%;
	height: 1px;
	background: #00000080;
	transform: translateY(-50%);
	display: inline-block;
}

header .search-complete .scl-product .images, header .search-complete img {
	width: 100%;
	float: left;
	position: relative;
	height: calc((100vw - 260px) * .5 * .65 * .25 * 1.25)
}

header .search-complete .loading span {
	margin: 0;
}

header .search-complete .scl-product .hover-menu, header .search-complete .scl-product .color-palette {
	display: none;
}
/** HEADER **/
/* MENU */

#main-menu {
	background: var(--white);
	position: relative;
	z-index: 20;
	min-height: 32px;
}

#main-menu > .container {
	position: initial;
}

#main-menu > .container > ul {
	border: 1px solid #e4e4e4;
	border-width: 1px 0;
	text-align: center;
	height: 50px;
}

#main-menu > .container > ul > li {
	margin-right: 0;
}

#main-menu > .container > ul > li.two .menu-children {
	width: 460px;
	left: calc(-230px + 50%);
}

#main-menu > .container > ul > li.little {
	position: relative;
}

#main-menu > .container > ul > li.notTitle .menu-children {
	padding-top: 15px;
}

#main-menu > .container > ul > li.notTitle .menu-children::before {
	display: none;
}

#main-menu > .container > ul > li > a {
	padding: 0 33px;
	height: 50px;
	line-height: 50px;
	position: relative;
	transition: .3s;
}

#main-menu > .container > ul > li > a:hover {
	color: var(--main-color);
}

#main-menu > .container > ul > li > a::after {
	content: '';
	width: 0;
	height: 3px;
	background: var(--black);
	position: absolute;
	left: 50%;
	bottom: -1px;
	display: inline-block;
	transform: translateX(-50%);
	-webkit-transition: width .3s ease,opacity .3s ease;
	transition: width .3s ease,opacity .3s ease;
	overflow: hidden;
}

#main-menu > .container > ul > li > a:hover::after {
	width: 30%;
}

.menu-children {
	width: 100%;
	background: white;
	position: absolute;
	top: 50px;
	left: 0;
	width: 100%;
	padding: 45px 7px 35px;
	box-shadow: 0 1px 0 0 rgba(0,0,0,.07);
	text-align: center;
	z-index: 10;
	opacity: 0;
	display: none;
}

.close-page-hover, .close-page-click {
	background: #00000030;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 65px;
	left: 0;
	z-index: 1;
	display: none;
}
.close-page-click {
	z-index: 20;
}
.menu-children.marka .col {
	padding: 0 6px;
}

.menu-children.marka img {
	width: 100%;
	margin-bottom: 8px;
}

.menu-children.marka a {
	color: var(--main-grey);
}

.menu-children.marka a:hover {
	color: var(--main-hover);
}

.menu-children.reyon {
	text-align: left;
	padding: 45px 0 35px;
}

.menu-children.reyon img {
	width: 100%;
}

.menu-children.reyon > .container > .row > .col-3 {
	width: 19.2%;
	padding-right: 15px;
	margin: 15px 0;
	border-right: 1px solid var(--main-lightgrey);
	letter-spacing: .2px;
}

.menu-children.reyon > .container > .row > .col-3 .high-children {
	width: 77.8%;
	left: 21.2%;
	position: absolute;
	top: 0;
	display: none;
}

.menu-children.reyon > .container > .row > .col-3 .high-children.active {
	display: block;
}

.menu-children.reyon > .container > .row > .col-3 .high-children > .col-4 {
	padding-left: 7.4%;
	padding-right: 0;
	width: 31.4%;
}

.menu-children.reyon > .container > .row > .col-3 .high-children > .col-4:last-child {
	padding: 0;
	margin-left: 2.7%;
	width: 33%;
}

.menu-children.reyon ul {}

.menu-children.reyon li a {
	color: var(--main-grey);
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 2px;
}

.menu-children.reyon li:last-child a {
	margin-bottom: 0;
}

.menu-children.reyon ul li a:hover {
	color: var(--black);
}

.menu-children.reyon ul li a:hover::before {
	width: 100%;
	opacity: 1;
}

.menu-children.reyon ul li a::before {
	content: '';
	background: var(--black);
	width: 0;
	height: .5px;
	position: absolute;
	bottom: -1px;
	left: 50%;
	transform: translateX(-50%);
	-webkit-transition: width .3s ease,opacity .3s ease;
	transition: width .3s ease,opacity .3s ease;
	opacity: 0;
}

.menu-children.reyon ul li.images a {
	height: 40px;
	line-height: 40px;
	background-size: 100%;
	text-indent: 8px;
	color: var(--white);
	display: block;
}

.menu-children.reyon ul li.images a {
	color: var(--white);
}

.menu-children > .container > .row > .col-3 > ul > li > svg {
	display: none;
}
/** MENU **/
/* SLIDER */
.carousel {
	top: -7px;
}

.carousel-control-next,
.carousel-control-prev {
	height: 60px;
	width: 60px;
	background: white;
	position: absolute;
	bottom: 0;
	top: 50%;
	transform: translateY(-50%);
	--webkit-ransform: translateY(-50%);
	transition: opacity .15s ease;
	color: var(--main-color)!important;
	font-size: 2em;
	text-align: center;
	z-index: 1;
	opacity: 0;
	-webkit-transition: visibility 0s linear .3s,opacity .3s linear;
	-moz-transition: visibility 0s linear .3s,opacity .3s linear;
	-o-transition: visibility 0s linear .3s,opacity .3s linear;
	transition: visibility 0s linear .3s,opacity .3s linear;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
	color: var(--main-color);
}

.carousel:hover .carousel-control-next,
.carousel:hover .carousel-control-prev {
	opacity: 1;
}
.carousel:hover .carousel-control-prev{
	text-align: left;
}
/** SLIDER **/
/* OCCASİON */
.occasion {
	margin: 0 auto;
	text-align: center;
}

.occasion .occa {
	width: 15%;
	height: 5em;
	margin-right: 1%;
	position: relative;
	padding: 16px 0 10px 5em;
	border-right: solid 1px rgba(0,0,0,.1);
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	line-height: 1.5em;
	font-size: 13px;
}

.occasion .occa svg {
	position: absolute;
	left: 5px;
	top: 50%;
	transform: translateY(-50%);
}

.occasion .occa strong {
	letter-spacing: .1em;
}

.occasion .occa:last-child {
	border-right: none;
}
/** OCCASİON **/
/* INDEX OPACİTY BANNER */
.main-opacity-banner {
	margin-top: 5%;
}

.main-opacity-banner a {
	opacity: 1;
	transition: 500ms opacity;
}

.main-opacity-banner [disabled] {
	opacity: .5;
}

.main-opacity-banner img {
	width: 100%;
}
/** INDEX OPACİTY BANNER **/
/* FULL BANNER */
.full-banner {
	margin-top: 35px;
	position: relative;
}

.full-banner h1,
.full-banner span {
	max-width: 40%;
	color: var(--white);
	text-align: center;
	line-height: 1.8em;
	position: absolute;
	top: 55px;
	left: 50%;
	transform: translateX(-50%);
	font-size: 2.2em;
	text-shadow: 2px 2px 5px rgba(0,0,0,1)
}

.full-banner span {
	top: 100px;
	font-size: 1.5em;
}

/** FULL BANNER **/
/* HİT PRODUCTS */
.hit-product .hp-slider {
	overflow: hidden;
	padding-top: 15px;
}

.hit-product img {
	width: 100%;
}

.hit-product .hp-slider .hp-row {
	width: 100%;
	transform: translateX(0px);
	transition: all 1.5s ease 0s;
	width: 3054px;
}

.hit-product .hp-slider .hp-row a {
	width: 23.5%;
	width: 234.5px;
	margin-right: 20px;
}

.hit-product .hp-slider .hp-row a:last-child {
	margin-right: 0;
}

.hit-product .hp-slider .hp-dots {
	text-align: center;
	padding: 0;
}

.hit-product .hp-slider .hp-dots li {
	padding: 3px;
	opacity: .3;
	cursor: pointer;
	display: inline-block;
	margin: 5px;
}

.hit-product .hp-slider .hp-dots li.active,
.hit-product .hp-slider .hp-dots li:hover {
	opacity: 1;
}

.hit-product .hp-slider .hp-dots li span {
	padding: 3px;
	border: 2px solid var(--black);
	border-radius: 50%;
	display: inline-block;
}
/** HİT PRODUCTS **/
/* SCL BRANDS */
.scl-brands img {
	width: 100%;
}
/** SCL BRANDS **/
/* FOOTER */
footer {
	padding-bottom: 60px;
	text-align: center;
	clear: both;
}

footer a {
	color: var(--main-grey);
}

footer a:hover {
	color: var(--main-color);
}

footer ul {
	border-top: 1px solid var(--main-lightgrey);
	padding-top: 15px;
	text-align: center;
}

footer ul li {
	padding: 5px 15px;
	border-right: solid 1px var(--main-lightgrey);
}

footer ul li:first-child {
	padding-left: 0;
}

footer ul li:last-child {
	padding-right: 0;
	border-right: none;
}

footer .social a {
	color: #888;
	margin-left: 15px;
}

footer .social a:hover {
	color: var(--main-color);
}

.go-head {
	background: var(--white);
	width: 40px;
	height: 40px;
	line-height: 43px;
	color: var(--quick-silver);
	border: 2px solid var(--quick-silver);
	text-align: center;
	position: fixed;
	right: 25px;
	bottom: 70px;
	z-index: 10;
	transition: 300ms ease-out;
	cursor: pointer;
	display: none;
}

.go-head svg {
	transform: rotate(180deg);
	display: inline-block;
}

.go-head:hover {
	color: var(--white);
	background: var(--quick-silver);
}

#ETBIS {
	position: absolute;
	bottom: 0;
	right: 220px;
}

#ETBIS img {
	width: 70px;
}

.popup {
	width: auto;
	position: fixed;
	bottom: 15px;
	left: 20px;
	background: white;
	border-radius: 30px;
	padding: 10px 40px 10px 20px;
	border: 1px solid #7e7e7e;
	z-index: 20;
	line-height: 2em;
	color: #000;
	max-width: calc(100% - 40px);
}

.popup .container {
	width: 100%;
}

.popup a {
	text-decoration: underline;
	font-weight: 600;
}

.popup a:hover {
	color: var(--main-hover);
}

.popup span {
	position: absolute;
	right: 5px;
	top: 50%;
	transform: translateY(-50%);
	font-size: 20px;
	border-radius: 50%;
	cursor: pointer;
}

.popup span:hover {
	font-weight: 600;
}
/** FOOTER **/
/* CONTENTS */

/* SIDERBAR */

.combimeModal .combine-products::-webkit-scrollbar,
.scl-product.combine .combine-list::-webkit-scrollbar,
#content .scl-sidebar::-webkit-scrollbar
{
	width: 3px;
	height: 3px;
	background: #eee;
}

.combimeModal .combine-products::-webkit-scrollbar-thumb,
.scl-product.combine .combine-list::-webkit-scrollbar-thumb,
#content .scl-sidebar::-webkit-scrollbar-thumb
{
	background: #ccc;
}

.combimeModal .combine-products::-webkit-scrollbar-thumb:hover,
.scl-product.combine .combine-list::-webkit-scrollbar-thumb:hover,
#content .scl-sidebar::-webkit-scrollbar-thumb:hover
{
	background: #999;
}

#content .scl-sidebar {
	width: 22%;
	padding-right: 1%;
	float: left;
	transition: 300ms all;
	overflow-y: auto;
	overflow-x: hidden;
	position: -webkit-sticky; /* Safari */
	position: sticky;
	top: 115px;
	padding-bottom: 0px;
	margin-bottom: 0;
	scrollbar-width: thin;
}

#content .scl-sidebar h3.section-title {
	padding-bottom: 5%;
	color: var(--main-grey);
	width: 100%;
	text-align: left;
}

#content .scl-sidebar .sections {
	float: left;
	margin-bottom: 25px;
}

#content .scl-sidebar .sections .kutu {
	float: left;
	color: var(--main-grey);
	padding: 10px 30px 10px 10px;
	border: 1px solid var(--quick-silver);
	margin: 0 5px 5px 0;
	position: relative;
	cursor: pointer;
}

#content .scl-sidebar .sections .arasi {
	float: left;
	color: var(--main-grey);
	padding: 10px 10px 10px 10px;
	position: relative;
	cursor: pointer;
}
#content .scl-sidebar .clear-all {
	float: right;
	font-size: 11px;
	font-weight: 500;
}

#content .scl-sidebar .sections svg {
	position: absolute;
	top: 50%;
	right: 10px;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

#content .scl-sidebar .sections span:active::after {
	color: var(--main-active);
}

#content .scl-sidebar h3.filter-title {
	padding-bottom: 5%;
	margin-bottom: 5.8%;
	color: var(--main-grey);
	width: 100%;
	border-bottom: solid 1px var(--main-lightgrey);
	text-align: left;
	clear: both;
}

#content .scl-sidebar ul.filter-list label {
	display: inline-block;
	cursor: pointer;
}

#content .scl-sidebar ul.filter-list h3  {
	font-size: .9rem;
	padding: 8px 5px 8px;
	border-bottom: 1px solid #eee;
	min-height: 30px;
	cursor: pointer;
	margin-bottom: 20px;
}

#content .scl-sidebar ul.filter-list h3:first-child {
	margin-top: 0;
}

#content .scl-sidebar ul.filter-list h3 svg:nth-child(1) {
	display: none;
}

#content .scl-sidebar ul.filter-list h3.active svg:nth-child(2) {
	display: none;
}

#content .scl-sidebar ul.filter-list h3.active svg:nth-child(1) {
	display: inline-block;
}

#content .scl-sidebar ul.filter-list h3 + ul {
	height: 0;
	overflow: hidden;
	transition: 500ms height;
}

#content .scl-sidebar ul.filter-list h3.active + ul {
	height: unset;
}

#content .scl-sidebar ul.filter-list ul  {
	padding-left: 5px;
}
/** SIDERBAR **/
/** CONTENT **/

#content .scl-content {
	width: 75%;
	min-height: 500px;
	float: right;
	position: relative;
}

#content .scl-content .scl-content-title h2 {
	display: inline-block;
}

#content .scl-content .scl-content-title .scl-content-sort {
	float: right;
	padding-right: 20px;
	position: relative;
	top: 10px;
	z-index: 2;
	cursor: pointer;
}

#content .scl-content .scl-content-title .scl-content-sort.active {
	z-index: 21;
}

#content .scl-content .scl-content-title .scl-content-sort svg {
	position: absolute;
	right: 2px;
	top: 6px;
	border-color: black transparent transparent transparent;
}

#content .scl-content .scl-content-title .scl-content-sort ul {
	width: 255px;
	background: white;
	border: 1px solid var(--main-hover);
	position: absolute;
	top: 20px;
	right: 0;
	display: none;
}

#content .scl-content .scl-content-title .scl-content-sort ul li span {
	padding: 20px 15px;
	display: block;
	transition: none;
	cursor: pointer;
}

#content .scl-content .scl-content-title .scl-content-sort ul li span:hover,
#content .scl-content .scl-content-title .scl-content-sort ul li.active span {
	background: var(--main-hover);
	color: white;
}

#content .scl-content .scl-content-title .scl-content-sort.active ul {
	display: block;
}

#content .pages ul {
	border: 1px solid #ddd;
	margin: 20px 0 40px 0;
	padding: 10px;
}

#content .pages ul li {
	padding: 10px 20px;
	margin: 0;
	cursor: pointer;
	position: relative;
}
#content .pages ul li::after {
	content:'';
	position: absolute;
	top: -11px;
	left: 50%;
	transform: translateX(-50%);
	border: 8px solid transparent;
	transition: .5s;
}

#content .pages ul li:hover::after, #content .pages ul li.active::after {
	border-top-color: #ccc;
}

#content .scl-product-list {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
}

#content .scl-product-list .scl-product {
	width: 32.5%;
	margin-bottom: 3em;
	transition: 300ms;
	position: relative;
	text-align: center;
}

#content .scl-product-list .scl-product._2 {
	width: 66%;
}

#content .scl-product-list .scl-product._3 {
	width: 100%;
}

#content .scl-product-list .scl-product .big-size {
	background: white;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: var(--main-hover);
	text-align: center;
	font-size: .9em;
	border-radius: 3px;
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 10;
}

#content .scl-product-list .scl-product .only-online {
	background: var(--main-hover);
	padding: 8px 10px;
	color: white;
	text-align: center;
	font-size: .9em;
	font-weight: 600;
	border-radius: 3px;
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 10;
}

#content .scl-product-list .scl-product .big-size + .only-online  {
	top: 55px;
}

#content .scl-product-list .scl-product img {
	width: 100%;
}

#content .scl-product-list .scl-product .title {
	padding: 10px 10px 5px 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: block;
}

.iprice {
	display: flex;
	font-size: 1.2em;
}

.iprice .amount {
  letter-spacing: 0.8px;
	color: var(--main-red);
	font-weight: 400;
	white-space: nowrap;
  text-align: right;
	padding: 5px 7px 0px;
	font-size: 0.9em;
	display: block;
}

.iprice .prices {
  padding-left: 7px;
	display: block;
	border-left: solid 0.5px #e8101d !important;
}

.iprice .prices .priced {
	font-weight: 400;
	white-space: nowrap;
	color: var(--black);
	display: block;
	font-size: 1em;
}

.iprice .prices .priced.default {
    line-height: normal;
    letter-spacing: 0.2px;
		font-size: 0.9em;

		color: var(--main-grey) !important;
		font-weight: 400;
		display: block;
}

.iprice .default {
    text-decoration: line-through;
}

.iprice .old {
	color: var(--main-grey);
	font-weight: 400;
  display: block;
	margin-right: 10px;
}

.price {
	font-weight: 400;
	white-space: nowrap;
	color: var(--black);
	white-space: nowrap;
	font-size: 1.2em
}

.price .old {
	color: var(--main-grey);
	font-weight: 400;
	position: relative;
	margin-right: 10px;
	text-decoration: line-through;
}

.add-to-basket .price {
	font-weight: 400;
	white-space: nowrap;
	color: var(--main-white);
	white-space: nowrap;
}

.add-to-basket .price .old {
	color: var(--main-lightgrey);
	font-weight: 400;
	position: relative;
	margin-right: 10px;
	text-decoration: line-through;
}

#content .scl-product-list .scl-product .hover-menu {
	width: 92%;
	margin: 0 4%;
	position: absolute;
	bottom: 75px;
	background: rgba(255,255,255,.5);
	padding: 30px 3px 10px;
	opacity: 0;
	transition: 600ms opacity;
	z-index: 5;
}

#content .scl-product-list .scl-product .hover-menu.quick {
	background: transparent;
	padding: 15px 3px 10px;
	bottom: 60px;
}

#content .scl-product-list .scl-product .hover-menu.quick svg {
	font-size: 0;
}

#content .scl-product-list .scl-product .hover-menu.quick button.quick {
	background: #ffffff;
	letter-spacing: 3.3px;
	width: 65%;
	padding: 12px 10px;
	font-weight: 600;
	color: var(--main-color);
	border: 1px solid var(--main-color);
}

#content .scl-product-list .scl-product .hover-menu.quick button.quick:hover {
	background: #000000;
	color: var(--white);
}

#content .scl-product-list .scl-product:hover .hover-menu {
	opacity: 1;
}

#content .scl-product-list .scl-product .hover-menu .arrows span {
	background: white;
	width: 40px;
	height: 40px;
	line-height: 40px;
	position: absolute;
	top: 0;
	left: 0;
	text-align: center;
	cursor: pointer;
	transition: 300ms;
}

#content .scl-product-list .scl-product .hover-menu .arrows span:hover {
	background: var(--main-lightgrey);
}

#content .scl-product-list .scl-product .hover-menu .arrows span:last-child {
	left: auto;
	right: 0;
}

#content .scl-product-list .scl-product .hover-menu .buttons button {
	padding: 5.5% 2%;
	width: 49%;
	border: 1px solid var(--main-color);
	background: var(--main-color);
	color: var(--white);
	transition: 300ms;
}

#content .scl-product-list .scl-product .hover-menu .buttons button.details {
	background: transparent;
	outline: 1px solid var(--main-grey);
	border-color: var(--main-grey);
	outline-offset: -6px;
	margin-right: 2%;
	color: var(--main-color);
}

#content .scl-product-list .scl-product .hover-menu .buttons button:hover {
	background: transparent;
	color: var(--main-color);
}

#content .scl-product-list .scl-product .hover-menu .buttons button:active {
	background: transparent;
	color: var(--main-active);
	border-color: var(--main-active);
}

#content .scl-product-list .scl-product .hover-menu .buttons button.details:hover {
	border-color: white;
	outline-color: white;
	color: white;
	background: var(--main-grey);
}

#content .scl-product-list .scl-product .hover-menu .body-list {
	margin-top: 25px;
}

#content .scl-product-list .scl-product .hover-menu .body-list ul li a {
	margin: 0 10px;
	color: var(--main-grey);
}

#content .scl-product-list .scl-product .hover-menu .body-list ul li.active a {
	color: var(--main-color);
}

#content .scl-product-list .scl-product .colors-num {
	position: absolute;
	bottom: 70px;
	left: -1px;
	font-weight: 500;
	text-align: left;
	overflow: hidden;
	background: #ffffff6b;
	padding: 5px 10px 5px 5px;
	border-radius: 0 0 10px 0;
	color: #000;
}

#content .scl-product-list .scl-product .favorite {
	position: absolute;
	top: 10px;
	right: 15px;
	font-weight: 500;
	color: #000;
	cursor: pointer;
	opacity: .6;
	transition: 300ms;
}

#content .scl-product-list .scl-product .favorite svg {
	font-size: 1em;
}

#content .scl-product-list .scl-product:hover .favorite {
	opacity: 1
}

#content .scl-product-list .scl-product .favorite .active {
	color: var(--main-active)
}

#content .scl-product-list .scl-product .not-in-stock {
	width: 92%;
	margin: 0 4%;
	position: absolute;
	bottom: 60px;
	background: rgba(255,255,255,.5);
	padding: 30px 3px 10px;
	z-index: 4;
}

#content .scl-product-list .scl-product .not-in-stock.quick {
	background: transparent;
	padding: 15px 3px 10px;
}

#content .scl-product-list .scl-product .not-in-stock.quick button.quick {
	background: #ffffffcc;
	letter-spacing: 3.3px;
	width: 65%;
	padding: 12px 10px;
	font-weight: 600;
	color: var(--main-color);
	border: 1px solid var(--main-color);
}

#content .scl-product-list .scl-product .not-in-stock.quick button.quick:hover {
	background: #000000;
	color: var(--white);
}

#content .scl-product-list .scl-product .not-in-stock .buttons button {
	padding: 5.5% 2%;
	width: 49%;
	border: 1px solid var(--main-color);
	background: var(--main-color);
	color: var(--white);
	transition: 300ms;
}

#content .scl-product-list .scl-product .not-in-stock .buttons button.details {
	background: transparent;
	outline: 1px solid var(--main-grey);
	border-color: var(--main-grey);
	outline-offset: -6px;
	margin-right: 2%;
	color: var(--main-color);
}

#content .scl-product-list .scl-product .not-in-stock .buttons button:hover {
	background: transparent;
	color: var(--main-color);
}

#content .scl-product-list .scl-product .not-in-stock .buttons button:active {
	background: transparent;
	color: "#000000aa";
	border-color: var(--main-active);
}

#content .scl-product-list .scl-product .not-in-stock .buttons button.details:hover {
	border-color: white;
	outline-color: white;
	color: white;
	background: var(--main-grey);
}


.scl-product.combine .combine-list {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% - 10px);
	background: #ffffffe9;
	cursor: pointer;
	transition: .5s;
	overflow-y: auto;
	overflow-x: hidden;
	opacity: 0;
}

.scl-product.combine .look-modal {
	position: absolute;
	bottom: -20px;
	left: 50%;
	transform: translateX(-50%);
	color: #000;
	font-size: 13px;
	white-space: nowrap;
}

.scl-product.combine:hover .combine-list {
	opacity: 1;
}

.scl-product.combine .combine-product {
	width: 100%;
	float: left;
	padding: 10px 5px;
	border-bottom: 1px solid #e0e0e0;
}

.scl-product.combine .combine-product .image {
	width: 70px;
	float: left;
}

.scl-product.combine .combine-product span {
	padding-top: 5px;
	padding-left: 85px;
	text-align: left;
	display: block;
}

#content .scl-product-list .scl-product .color-palette .varyant-color {
	background: blue;
	border-color: blue;
	width: 30px;
	height: 30px;
	margin: 5px;
	border-radius: 50%;
	box-shadow: 1px 1px 5px rgba(0,0,0,.3);
	position: relative;
	cursor: pointer;
	display: inline-block;
}

#content .scl-product-list .scl-product .color-palette .varyant-color.active::after {
	content: '';
	width: 38px;
	height: 38px;
	border-radius: 50%;
	border: 2px solid;
	border-color: inherit;
	position: absolute;
	top: -4px;
	left: -4px;
}

#content .scl-product-list .scl-product .color-palette .varyant-color.active::before {
	content: '';
	background: white;
	width: 6px;
	height: 6px;
	position: absolute;
	top: 12px;
	left: 12px;
	box-shadow: 0 0 2px rgba(0, 0, 0, .5);
	border-radius: 6px;
}

#content .scl-not-item {
	padding: 20px 0;
	text-align: center;
	margin: 100px auto;
	color: var(--main-grey);
}

#content .scl-not-item svg {
	font-size: 5em;
	margin: 20px 0;
	display: inline-block;
}

#content .scl-not-item h2 {
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto 20px;
	text-transform: uppercase;
	position: relative;
}

/* DETAİL */
#detail {}

#detail nav .breadcrumb {
	padding: 0;
	background: transparent;
	margin: 3.4% 0;
}

#detail nav .breadcrumb-item a {
	color: var(--quick-silver);
}

#detail nav .breadcrumb-item a:hover {
	color: var(--main-hover);
}

#detail nav .breadcrumb-item a:active {
	color: var(--main-active);
}

nav .breadcrumb-item+.breadcrumb-item::before {
	content: ">";
	font-weight: 600;
	right: 0;
	top: 0px;
	position: relative;
	font-size: 13px;
}

#detail .detail-left {
	width: 48%;
	float: left;
}

#detail .detail-left .big-image {
	height: calc((100vw - 260px) * .48 * .8 * 1.25);
	position: relative;
	float: right;
	width: 80%;
}

#detail .detail-left .sag-ust {
	width: 55px;
	height: 50px;
	line-height: 47px;
	text-align: center;
	position: absolute;
	top: 6px;
	right: 6px;
	border-radius: 50%;
	font-size: 1.2em;
	z-index: 10;
	cursor: pointer;
}

#detail .detail-left .sag-ust.favorite {
	font-weight: 500;
	opacity: .6;
	transition: 300ms;
}

#detail .detail-left:hover .favorite {
	opacity: 1
}

#detail .detail-left .sag-ust.favorite.active {
	color: var(--main-active)
}

#detail .detail-left .sol-ust {
	width: 60px;
	height: 30px;
	text-align: center;
	position: absolute;
	top: 18px;
	left: 0px;
	font-size: 1.1em;
	z-index: 10;
	display: block;
	color: var(--white);
	background: #e8101d;
	border: solid 1px #e8101d;
	font-weight: 600;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;

	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

#detail .detail-left .sol-alt {
	width: 60px;
	height: 30px;
	text-align: center;
	position: absolute;
	bottom: 18px;
	left: 0px;
	font-size: 1.1em;
	z-index: 10;
	display: block;
	color: var(--white);
	background: #e8101d;
	border: solid 1px #e8101d;
	font-weight: 600;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;

	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

#detail .detail-left .zoom {
	background: #fff;
	width: 50px;
	height: 50px;
	line-height: 47px;
	text-align: center;
	position: absolute;
	bottom: 10px;
	right: 10px;
	border-radius: 50%;
	font-size: 1.2em;
	z-index: 10;
	cursor: pointer;
}

#detail .detail-left .thump-image {
	width: 94%;
	margin: 0 3%;
	transition: .7s;
	transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
	overflow-x: hidden;
	overflow-y: scroll;
}

#detail .detail-left .thump-image li {
	width: 100%;
	position: relative;
	cursor: pointer;
	transition: 300ms;
}

#detail .detail-left .thump-image li.active::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.3);
}

#detail .detail-left .thump-slider {
	position: relative;
	float: left;
	width: 20%;
}

#detail .detail-left .thump-slider.active {
	overflow: hidden;
}

#detail .detail-left .thump-slider.active .thump-image {
	margin: 0;
}

#detail .detail-left .thump-slider .thump-image li {
	height: calc((100vw - 260px) * .48 * .9 * .2 * 1.25);
}

#detail .detail-left .thump-slider.active .thump-image li {
	margin: 7px 5px;
	cursor: pointer;
}

#detail .detail-left .thump-slider.active .thump-image li:first-child {
	margin-left: 0;
}

#detail .detail-left .thump-slider .arrow:last-child {
	left: auto;
	right: 0;
}

#detail .detail-left .thump-slider .arrow:last-child svg  {
	background-position: -130px -50px;
	left: auto;
	right: 4px;
}

#detail .detail-right {
	width: 45%;
	float: left;
	padding-bottom: 80px;
	margin-left: 2%;
	position: relative;
}

#detail .detail-right h1 {
	margin-bottom: 10px;
	color: var(--main-color);
}

#detail .detail-right .size-convert {
	font-size: 1rem;
	margin: 0 0 1rem;
}

#detail .detail-right .size-convert span {
	cursor: pointer;
	margin-right: 1rem;
	position: relative;
}

#detail .detail-right .size-convert span.active {
	color: var(--main-hover);
    font-weight: 600;
}

#detail .detail-right .size-convert span:not(:last-child)::before {
	content: '';
	width: 1px;
	height: .8rem;
	background: var(--main-grey);
	position: absolute;;
	top: .3rem;
	right: -.6rem;
}


#detail .detail-right .scl-info {
	color: var(--quick-silver);
	height: 50px;
	line-height: 50px;
	cursor: pointer;
	float: right;
	height: auto;
	line-height: initial;
	display: inline-block;
	transition: 300ms all;
}

#detail .detail-right .scl-info:hover {
	color: var(--main-color);
}

#detail .detail-right .scl-select-option > span {
	color: var(--quick-silver);
	margin-bottom: 10px;
	display: inline-block;
	text-transform: capitalize;
}

#detail .detail-right .size-chart {
	color: var(--main-green);
	font-weight: 500;
	text-decoration: underline;
	float: right;
	cursor: pointer;
}

#detail .detail-right .scl-select-option ul li {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	position: relative;
	cursor: pointer;
	border: 1px solid #eee;
	margin-right: .8rem;
	margin-bottom: 10px;
}

#detail .detail-right .scl-select-option ul li:before {
	content: '';
	width: 34px;
	height: 34px;
	border: 1px solid #ddd;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

#detail .detail-right .scl-select-option ul li.active:before {
	border-color: #000;
}
#detail .detail-right .scl-select-option ul li svg {display: none;}
/*
#detail .detail-right .scl-select-option ul li svg {
	line-height: 1;
	color: #fff;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	transition: opacity .3s cubic-bezier(0.4, 0, 1, 1);
	font-size: 2em;
	filter: drop-shadow(0 0 2px rgba(0,0,0,0.4));
}

#detail .detail-right .scl-select-option ul li.whiteColor svg {
	color: #000;
	filter: unset;
}
*/
#detail .detail-right .scl-select-option ul li:first-child {
	margin-left: .3rem;
}

#detail .detail-right .sizes {
	margin: 10px 0;
}

#detail .detail-right .sizes span {
	width: 34px;
	height: 34px;
	line-height: 34px;
	border: 1px solid #ddd;
	color: #5a5a5a;
	margin-right: 10px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	border-radius: 50%;
	position: relative;
	z-index: 1
}

#detail .detail-right .sizes span::before {
	content: "";
	width: 26px;
	height: 26px;
	background: #ededed;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 30px;
	z-index: -1
}

#detail .detail-right .sizes span.active {
	border-color: black;
}

#detail .detail-right .sizes span.not {
	opacity: .5;
	position: relative;
}

#detail .detail-right .sizes span.not.active {
	opacity: .7;
	position: relative;
}

#detail .detail-right .sizes span.not::before {
	content: '';
	width: 24px;
	height: 1px;
	background: #000;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(45deg);
	display: block;
}

#detail .detail-right .social {
	height: 80px;
	position: relative;
	margin-top: 30px;
	display: block;
}

#detail .detail-right .social > ul li {
	width: 26%;
	margin: 0 1%;
	text-align: center;
	border: 1px solid #8a8a8a;
	color: #8a8a8a;
	border-radius: 3px;
	padding: 5px;
	height: 80px;
	float: left;
	font-size: 12px;
	cursor: pointer;
}

#detail .detail-right .social > ul li a {
	color: #8a8a8a;
}

#detail .detail-right .social > ul li:last-child {
	width: 14%;
	margin: 0;
}

#detail .detail-right .social  >ul li svg {
	width: 100%;
	margin-bottom: 10px;
	font-size: 40px;
	height: 40px;
	display: inline-block;
	color: #5e5e5e;
}

#detail .detail-right .social > ul li.scl-wp svg {
	color: #25D366;
}

#detail .detail-right .social > ul li.scl-shareopen svg,
#detail .detail-right .social > ul li.scl-friend svg,
#detail .detail-right .social > ul li.scl-phone svg {
	height: 30px;
	margin-bottom: 13px;
	margin-top: 7px;
}

#detail .detail-right .social > ul li.scl-shareopen svg
{
	color: #999;
}

#detail .detail-right .social > ul li:hover , #detail .detail-right .social ul li:hover a, #detail .detail-right .social ul li:not(.scl-wp):hover svg {
	border-color: #333;
	color: #333;
}

#detail .detail-right .social > ul li:hover {
	margin-top: .2em;
	transform: scale(1.05);
}

#detail .detail-right .social .share-window {
	background: #fff;
	position: absolute;
	bottom: 15px;
	right: 35px;
	padding: 30px 10px 20px;
	border: 1px solid #999;
	border-radius: 3px;

}

#detail .detail-right .social .share-window .close {
	position: absolute;
	right: 0;
	top: 0;
	font-size: 18px;
	cursor: pointer;
}

#detail .detail-right .social .share-window ul li {
	width: 40px;
	height: 40px;
	line-height: 35px;
	text-align: center;
	border: 1px solid #ccc;
	border-radius: 3px;
	float: left;
	margin: 0 5px;
	cursor: pointer;
}

#detail .detail-right .social .share-window input {
	width: 220px;
	font-size: 10px;
	padding: 10px;
	box-shadow: none;
}


#detail .detail-right .non-stock {
	color: #aeaeae;
	font-weight: 900;
	padding: 7px 10px;
	font-size: 1.5em;
}

#detail .detail-right .go-basket button {
	width: 100%;
	height: 46px;
	border: 1px solid var(--main-color);
	background: var(--main-color);
	color: var(--white);
	transition: 300ms;
}

#detail .detail-right .go-basket button.active {
	background: var(--main-hover);
	border-color: var(--main-hover);
}

#detail .detail-right .go-basket button:hover {
	border-color: var(--main-color);
	color: var(--main-color);
	background: transparent;
}

#detail .detail-right .go-basket button:active {
	border-color: var(--main-active);
	color: var(--main-active);
	background: transparent;
}

#detail .detail-right .go-basket  svg.heart {
	transition: 300ms;
	font-size: 3em;
}

#detail .detail-right .go-basket svg.heart,
#detail .detail-right .go-basket svg.heart {
	cursor: pointer;
}

#detail .detail-right .go-basket .active svg {
	color: var(--main-active);
}


#detail .detail-right .malt {
	background: #fff;
	height: 66px;
	position: fixed;
	left: 0;
	bottom: 0;
	margin: 0;
	font-size: 0.95rem;
	z-index: 14;
	text-align: center;
	padding:10px;
}

button.add-to-basket {
	padding: 11px;
	padding-right: 20px;
	padding-left: 20px;
	width: 100%;
	height: 100%;
	border: 2px solid var(--main-color);
	background: var(--main-color);
	color: var(--white);
	transition: 300ms;
}

.malt button.add-to-basket {
	font-size: 1em;
	font-weight: 500;
}

.malt button.add-to-basket .price {
	font-size: 1em;
	font-weight: 500;
}

button.add-to-basket > * {
	display: inline-block !important;
	text-align:center;
	height: 50%;
}

button.add-to-basket:hover {
	border-color: var(--main-hover);
}

button.add-to-basket.active {
	border-color: var(--main-hover);
}
button.add-to-basket.active:hover {
	border-color: var(--main-color);
}

.add-to-basket svg.heart {
	transition: 300ms;
	font-size: 3em;
}

.add-to-basket svg.heart,
.add-to-basket svg.heart {
	cursor: pointer;
}

.add-to-basket.active svg {
	color: var(--main-active);
}





#detail .detail-right .in-basket {
	color: var(--main-color);
	padding: 15px 0 10px;
}

#detail .detail-right .services {
	margin-top: 20px;
	display: inline-block;
}

#detail .detail-right .services ul {
	margin: 0;
}

#detail .detail-right .services ul li {
	font-size: 1.2em;
	color: #333;
	text-transform: uppercase;
	white-space: nowrap;
	display: inline-block;
}

#detail .detail-right .services ul li:nth-child(1)  {
	width: 55%;
}

#detail .detail-right .services ul li:nth-child(2)  {
	width: 45%;
}

#detail .detail-right .services ul li svg {
	display: inline-block;
	font-size: 5.5em;
	float: left;
}

#detail .detail-right .services ul li span {
	display: inline-block;
	padding-left: 5px;
}

#detail .detail-right .services ul li span:nth-child(2) {
	margin: 22px 10px;
	line-height: 1.5;
}

.add-basket-product {
	margin: 10px 0;
}

.add-basket-product > .title {
	margin-bottom: 15px;
}

.add-basket-product .img {
	width: 20%;
	float: left;
	margin-right: 15px;
}

.add-basket-product b {
	font-weight: 500;
	margin-right: 10px;
}

.add-basket-product > span {
	display: block;
	text-align: left;
}

.product-feature {
	color: var(--quick-silver);
	padding-top: 15px;
}

.product-feature .feature-item > a,
.product-feature .feature-item > span {
	padding: 15px 0;
	border-bottom: 1px solid var(--form-border);
	margin-bottom: 10px;
	display: block;
	cursor: pointer;
}

.product-feature .feature-item > span svg,
.product-feature .feature-item > a svg {
	float: right;
}

.product-feature .feature-item > span svg {
	transform: rotate(270deg);

}
.product-feature .feature-item.active > span svg {
	/* content: "\e907"; */
	transform: rotate(0);
}

.product-feature .feature-item > span + .feature-content {
	display: none;
}

.product-feature .feature-item.active > span + .feature-content {
	display: block;
}

.product-feature .feature-content .col > strong {
	padding: 10px 0;
	display: block;
}

ul.disc {
	padding-left: 17px;
}

ul.disc li {
	list-style: disc;
}

.product-feature .feature-content ul li {
	margin: 10px 0 0;
}

.product-feature .feature-content ul.scl-yt li {
	margin: 10px 10px 10px 0;
}

.product-feature .feature-content ul.scl-yt li img {
	vertical-align: middle;
	opacity: .6;
	display: inline-block;
}

.product-feature .feature-content ul.scl-yt li.local {
	display: block;
	opacity: .8;
}

.product-feature .feature-content {
	position: relative;
}

.last-enter h2.hr::before {
	width: 84%;
	left: 8%;
}

.last-enter h2.hr span {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	letter-spacing: .2em;
	font-weight: 600;
	color: var(--main-grey);
	padding: 0;
}

.last-enter .swiper-slide {
	max-width: calc((100vw - 260px) * .1166);
	margin-right: 10px;
}

.last-enter .lse-product-list .lse-item {
	float: left;
}

.last-enter .lse-product-list .lse-item .img {
	overflow: hidden;
	width: 100%;
	height: calc((100vw - 260px) * .1166 * 1.25);
	position: relative;
}

.last-enter .lse-product-list .lse-item .img img {
	transition: 2s transform ease;
	width: 100%;
}

.last-enter .lse-product-list .lse-item:hover .img img {
	transform: scale(1.1);
}

.last-enter .lse-product-list .lse-item .title {
	padding: 10px 0;
	text-align: center;
	display: block;
}

.complete-dress h2.hr span {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	letter-spacing: .2em;
	font-weight: 600;
	color: var(--main-grey);
	padding: 0;
}

.complete-dress .product-list {
	position: relative;
	display: flex;
	line-height: 1.6;
}

.complete-dress .product-list .item {
	width: 16%;
	margin-right: 1%;
}

.complete-dress .product-list .item span {
	display: inline-block;
	margin-right: 10px;
	position: relative;
}

.complete-dress .product-list .old::after {
	content: '';
	width: 110%;
	height: 1px;
	background: var(--main-grey);
	position: absolute;
	top: 45%;
	left: -5%;
}

.complete-dress .product-list .item span.title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 90%;
}

.complete-dress .product-list .item span.price {
	font-size: 15px;
	font-weight: 500;
	color: #3e3e3e;
}

.complete-dress .product-list .item:last-child {
	margin-right: 0;
}

.complete-dress .product-list .item .img {
	width: 100%;
	height: calc((100vw - 260px) * .16 * 1.25);
	position: relative;
}

/** DETAİL **/
/* MALLS */
.malls {
	line-height: 1.6em;
	padding: 0!important;
	color: var(--main-color);
}

.malls h2 {
	font-weight: 400;
	margin-bottom: 1em;
}

.malls .col-4 {
	border-bottom: 1px solid var(--main-lightgrey);
}

#content.malls .scl-sidebar {
	overflow: unset;
	position: relative;
	top: 0;
	padding: 0;
}

._404 {
	width: 76%;
	margin: 0 auto 0px;
	text-align: center;
	display: block;
}

._404 .char_404 {
	font-size: 2em;
	padding-bottom: 10px;
	display: inline-block;
}

._404 h1 {
	font-size: 1.5em;
	margin-bottom: 15px
}

._404 h1.bg {
	width: 70%;
	height: 50px;
	line-height: 50px;
	padding-top: 20px;
	font-weight: 500;
	margin: 0 auto;
	text-transform: uppercase;
	position: relative;
}

._404 .searching {
	position: relative;
}

._404 input {
	box-shadow: none!important;
	border-radius: 0
}

._404 .searching span {
	width: 40px;
	height: 40px;
	line-height: 36px;
	display: inline-block;
	position: absolute;
	top: 0;
	right: 0px;
	cursor: pointer;
}

#content ._404 + .scl-product-list {
	padding: 0 15%;
	margin: 20px 0 50px;
	float: left;
	width: 100%;
}

#content ._404 + .scl-product-list .scl-product {
	width: 18.5%;
}

#content ._404 + .scl-product-list .scl-product .colors-num {
	font-size: .9em;
}

#content ._404 + .scl-product-list .scl-product .images {
	height: calc((100vw - 260px) * .7 * .185 * 1.25)
}

/** MALLS **/
/* BASKET */

header.basket  {
	box-shadow: 0px 0px 5px rgba(0,0,0,0.1)
}

header.basket .head .container  {
	width: calc(100% - 40px);
	max-width: calc(100% - 40px);
}

header.basket .scl-head-msg {
	color: var(--quick-silver);
	text-decoration: underline;
	line-height: 72px;
}

header .secure-pay span {
	display: inline-block;
	width: 118px;
	line-height: 1.5em;
	color: var(--main-silver);
}

header .secure-pay svg {
	display: inline-block;
	float: left
}

#basket  {
	min-height: 90vh;
}

#basket .basketMessage {
	font-size: 15px;
	line-height: 1.3em;
	width: 100%;
	text-align: center;
	display: block;
}

#basket .basketMessage .dangerCampaign {
	background: var(--main-red);
	color: white;
	display: block;
}

#basket .basketMessage .msg-btn {
	background: white;
	color: var(--main-red);
	padding: 4px 8px;
	margin: 6px 15px;
	font-size: .8em;
	display: inline-block;
}

#basket .basketMessage .successCampaign {
	background: #ea575736;
	padding: 10px 0;
	display: block;
}

#basket .basketMessage .swiper-container {
	padding-bottom: 0!important;
}

#basket iframe {
	height: auto;
	min-height: 500px;
	top: -40px;
	left: 15px;
	position: relative;
	max-width: 1000px;
}

#basket .container {
	min-height: 400px;
	padding-top: 30px;
	width: calc(100% - 100px);
}

#basket h1.tabs span {
	padding: 8px 15px;
	margin: 0 5px;
	position: relative;
	display: inline-block;
	cursor: pointer;
	background: rgba(55, 55, 55, .2);
	border-radius: 30px;
	position: relative;
}

#basket h1.tabs span.active {
	background: var(--main-green);
	color: #fff;
	padding: 10px 15px;
}


#basket .basket-product-list .row:first-child {
	border: 1px solid var(--main-lightgrey);
	border-width: 1px 0;
	padding: 15px 0;
}

#basket .basket-product-list .row:not(:first-child) {
	position: relative;
	align-items: center;
}

#basket .basket-product-list .row > div:nth-child(2) span {
	display: block;
	margin-top: 20px;
}

#basket .basket-product-list .row > div:nth-child(2) span.old {
	display: inline-block;
	margin-right: 10px;
	font-size: .9em;
	text-decoration: line-through;
	margin-top: 0;
	margin-top: 20px;
}

#basket .basket-product-list .row > div:nth-child(2) .old + span {
	display: block;
	margin-top: 5px;
}

#basket .basket-product-list .row:not(:first-child) > div:last-child {
	text-align: center;
}

#basket .basket-product-list .row .loading, #basketHover .content .loading {
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(200,200,200,.1);
	z-index: 4;
}

#basket .basket-product-list .row .loading .loader, #basketHover .content .loading .loader {
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	position: absolute;
}

#basket .basket-product-list .row .loading .loader span, , #basketHover .content .loading .loader span {
	margin: 0;
}

#basket .basket-product-list .row:not(:first-child) .form-check {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 5px;
}

#basket .basket-product-list + .col {
	position: sticky;
	top: 150px;
	height: 100%;
}

#basket .basket-info {
	padding: 20px 40px;
	border: 1px solid var(--main-lightgrey);
	top: -30px;
	position: relative;
	color: var(--main-color)
}

#basket .basket-info h2 {
	padding: 0;
	font-weight: 500;
}

#basket .basket-info .row:not(:first-child) {
	padding: 20px 0;
}

#basket .basket-info .row:not(:first-child):not(.dashedTB) span {
	width: 60%;
	float: right;
	padding: 0;
	font-weight: 500;
	font-size: 14px;
}

#basket #campaignData {
	margin-top: 20px;
	font-size: 12px;
	height: 32px;
	line-height: 32px;
	display: block;
	width: auto;
	border-color: var(--theme-color);
	color: var(--theme-color);
	clear: both;
	border-radius: 3px;
	display: inline-block;
	max-width: 100%;
}

#basket #campaignData .selected-item.only-text {
	height: 32px;
	line-height: 32px;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

#basket #campaignData .scl-select-options {
	width: auto;
	color: var(--main-grey);
	border-color: var(--main-lightgrey);
}

#basket #campaignData .scl-select-options.only-text .scl-select-item {
	height: 30px;
	line-height: 30px;
	margin: 2px 0;
}
#basket #campaignData .scl-select-options .scl-select-item.active {
	background: var(--main-grey)
}

#basket .dashedTB .price {
	font-size: 15px;
}

#basket .basket-info .dashedTB .price {
	font-size: 13px;
}

#basket .dashedTB .price span {
	display: block!important;
	width: auto!important;
}

#basket .dashedTB .price span.green {
	position: relative;
	height: 20px;
	line-height: 20px;
	margin: -2em  0 1em -5px;
	background: var(--main-green);
	padding: 0 20px 0 5px;
	font-size: .8rem;
	color: #fff;
	display: inline-block;
}
#basket .dashedTB .price span.green::before {
	content: '';
	width: 10px;
	height: 10px;
	border: 10px solid transparent;
	border-right-color: white;
	display: block;
	position: absolute;
	right: 0;
}

#basket .dashedTB .price .old {
	display: block;
	text-decoration: line-through;
	font-size: .8em;
	margin: 5px 0;
	color: #999;
}

#basket .basket-info .row:not(:first-child) span:nth-child(2) {
	text-align: right;
	width: 40%;
}

#basket .basket-info .row:not(:first-child) span {
	display: inline-block;
}

#basket .basket-info .row:not(:first-child) span small {
	display: block;
	margin-top: 5px;
	color: var(--main-grey);
}

#basket .steps {
	text-align: center;
	margin: -15px 0 50px;
	position: relative;
}

#basket .steps ul {
	display: flex;
	justify-content: center;
}

#basket .steps::before {
	content: '';
	width: 70%;
	height: 1px;
	background: var(--form-border);
	position: absolute;
	top: 50%;
	left: 15%;
	z-index: 1;
}

#basket .steps li {
	background: #f2f3f4;
	margin-right: 90px;
	padding: 0 20px;
	position: relative;
	display: flex;
	align-items: center;
	z-index: 2;
}

#basket .steps li:fisrt-child {
	margin-left: 0;
}

#basket .steps li:last-child {
	margin-right: 0;
}

#basket .steps .check {
	width: 45px;
	height: 45px;
	border: 3px solid var(--form-border);
	color: var(--form-border);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 15px 0 0;
}

#basket .steps .check svg {
	font-size: 2.5rem;
	position: relative;
	top: 20px;
	transform: translateY(-50%);
	display: none;
}

#basket .steps .active.check {
	border-color: var(--main-green);
	color: var(--main-green);
	font-size: 0;
}

#basket .steps .active.check svg {
	display: inline-block;
}

#basket .steps svg small {
	font-size: 12px;
}

#basket .steps .active::before {
	display: block;
}

#basket .credit-card {
	width: 250px;
	height: 160px;
	border: 1px solid #ddd;
	border-radius: 10px;
	padding-bottom: 15px;
	overflow: hidden;
	position: relative;
	background: linear-gradient(283deg, #dcdcdc, #fff);
}

#basket .credit-card .bank {
	width: 100%;
	height: 40px;
	padding: 10px
}

#basket .credit-card .bank img {
	max-height: 30px;
	float: left;
	max-width: 60%;
}

#basket .credit-card .card-brand {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

#basket .credit-card .card-brand img {
	height: 20px;
}

#basket .credit-card .cardno {
	height: 50px;
	line-height: 50px;
	font-size: 15px;
	margin-left: 15px;
	margin-top: 10px;
	font-family: 'Orbitron', sans-serif;
	letter-spacing: .5px;
}

#basket .credit-card .name {
	font-size: 14px;
	margin-left: 15px;
	margin-top: 5px;
}

#basket .credit-card .date {
	margin-left: 15px;
	margin-top: 10px;
}

#basket .address-select {
	display: flex;
	flex-wrap: wrap;
}

#basket .address-select .address {
	width: 32%;
	padding: 10px 20px 10px 10px;
	margin: 0 1% 10px 0;
	border: 1px solid var(--main-lightgrey);
	border-radius: 5px;
	position: relative;
	float: left;
	overflow: hidden;
}

#basket .address-select .address span {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin-bottom: 5px;
	display: block;
	min-height: 15px;
}

#basket .address-select .address.personal span {
	margin-bottom: 10px;
	overflow: visible;
}

#basket .address-select .address span.check {
	width: 16px;
	height: 16px;
	border: 2px solid var(--main-lightgrey);
	border-radius: 50%;
	position: absolute;
	display: block;
	right: 5px;
	top: 5px;
}

#basket .address-select .address span.editing {
	position: absolute;
	bottom: 0;
	right: 10px;
}

#basket .address-select .address.active span.check {
	border-color: var(--main-hover);
	border-width: 2px;
}

#basket .address-select .address.active span.check::before {
	content: '';
	width: 8px;
	height: 8px;
	background: var(--main-hover);
	border-radius: 50%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: relative;
	display: block;
}

#basket .address-select .address span.check + span {
	color: var(--main-color);
	margin-bottom: 10px;
	display: inline-block;
	font-weight: 600;
	letter-spacing: .5px;
}

#basket .address-select .address .edit {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
}

#basket .address-select .address .edit:hover {
	color: var(--main-hover);
}

#basket .address-select .new-address {
	height: 145px;
	color: #ccc;
	transition: 300ms;
	padding: 45px 0!important;
}
#basket .address-select .new-address:hover {
	color: var(--main-grey)
}


#basket .address-select .new-address svg {
	font-size: 3em;
}

#basket .cargo-select .cargo {
	padding-left: 45px;
	position: relative;
	height: 50px;
	line-height: 50px;
	cursor: pointer;
	margin: 5px 0;
}

#basket .cargo-select .cargo::before {
	content: '';
	width: 16px;
	height: 16px;
	border: 2px solid var(--main-lightgrey);
	border-radius: 50%;
	position: absolute;
	display: block;
	left: 12px;
	top: 17px;
}

#basket .cargo-select .cargo.active::before {
	border-color: var(--main-hover);
}

#basket .cargo-select .cargo.active::after {
	content: '';
	width: 8px;
	height: 8px;
	background: var(--main-hover);
	border-radius: 50%;
	top: 21px;
	left: 16px;
	position: absolute;
	display: block;
}

#basket .choices-select .item {
	padding-left: 45px;
	position: relative;
	padding: 20px 0 20px 40px;
	cursor: pointer;
	display: block;
	line-height: 2;
}

#basket .choices-select .item > span {
	display: block;
}

#basket .choices-select .item .check {
	content: '';
	width: 16px;
	height: 16px;
	border: 2px solid var(--main-grey);
	border-radius: 50%;
	position: absolute;
	display: block;
	left: 12px;
	top: calc(50% - 8px);
}

#basket .choices-select .item.active .check::after {
	content: '';
	width: 8px;
	height: 8px;
	background: var(--main-hover);
	border-radius: 50%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	display: block;
}

#basket .choices-select .item img {
	max-width: 130px;
	max-height: 30px;
	margin-right: 20px;
}

#basket .installment .check {
	border: 1px solid var(--main-green);
	border-radius: 50%;
	position: relative;
	float: left;
	width: 14px;
	height: 14px;
	margin-right: -30px;
}

#basket .installment .check.active::before {
	content: '';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: var(--main-green);
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

#basket .basket-success .success, #basket .basket-success .info  {
	text-align: center;
	font-size: 15px;
}

#basket .basket-success .row.success  {
	padding: 10% 0 5%;
	line-height: 1.6;
}

#basket .basket-success .bgw {
	position: relative;
	margin: 10em 0;
}

#basket .basket-success h1 {
	font-weight: 600;
	font-size: 20px;
}

#basket .basket-success svg {
	width: 124px;
	height: 124px;
	background: var(--main-green);
	padding: 10px;
	border-radius: 50%;
	color: #fff;
	position: absolute;
	top: -50px;
	font-size: 7em;
	left: 50%;
	transform: translateX(-50%);
}

#basket .basket-success .btn {
	background: #000;
	color: #fff;
	border: none;
	margin-top: 30px;
}

#basket .basket-success .btn:hover {
    padding: 10px 30px;
}

#basket .scroll-container
{
	padding: 20px 10px;
	border: 1px solid #ccc;
	line-height: 1.5;
	margin-bottom: 20px;
}

#basket .scroll-container tr, #basket .scroll-container td, #basket .scroll-container th
{
	border: 1px solid #ccc;
	text-align: center;
	padding: 5px;
}

#basket .scroll-container table {
	width: 100%;
}

.basket-empty {
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
	font-size: 20px;
	font-weight: 100;
	text-align: center;
}

.basket-empty svg {
	font-size: 5em;
}

.basket-empty span {
	margin: 30px 0;
	display: block;
}

.basket-empty .btn {
	width: auto!important;
	padding: 10px 25px!important;
}

.basket-empty .btn {
	padding: 15px 55px;
}
/** BASKET **/
/* USER PAGE */
.scl-sidebar .user-panel li a {
	display: block;
	color: var(--quick-silver);
	padding-bottom: 10%;
	margin-bottom: 9%;
	-webkit-transition: color .1s ease-in;
	transition: color .1s ease-in;
	border-bottom: 1px solid var(--main-lightgrey);
	text-transform: uppercase;
}

.scl-sidebar .user-panel li a:hover {
	color: var(--black);
}

.scl-sidebar .user-panel li.active a {
	font-weight: 500;
}

.scl-section-content {
	position: relative;
	min-height: 300px;
}

.user-page .order-empty svg {
	font-size: 3.5em;
	color: var(--main-grey);
}

.user-page .order-empty span {
	font-size: 18px;
	margin: 15px;
	font-weight: 300;
	line-height: 2;
	font-weight: 500;
	text-transform: uppercase;
	color: var(--main-grey);
}

.user-page .order-empty .btn {
	margin-top: 20px;
	padding: 10px 15px;
}

.user-page .scl-content-title {
	margin: 0;
	margin-bottom: 10px;
}

.user-page .scl-content .scl-content-title h1 {
	color: #2a2a2a!important;
	text-transform: uppercase;
}

.user-page .btn.user-title-btn {
	padding: 5px 10px;
	margin-top: 0px;
}

.user-page .user-title-btn.light {
	margin: 8px 0;
	padding: 2px 0;
	background: var(--white);
	color: var(--main-grey);
	border: none;
	border-bottom: 1px solid var(--main-grey);
}

.user-page .scl-content-title.tab {
	border-bottom: 1px solid var(--main-lightgrey);
	margin-bottom: 15px;
	padding: 0 0 10px;
}

.user-page .scl-content-title.tab h1 {
	float: left;
	margin-right: 20px;
	color: var(--main-lightgrey);
	cursor: pointer;
}

.user-page .scl-content-title.tab h1.active {
	color: var(--main-color);
}

.order-ticket, .order-ticket:hover {
	height: 70px;
	line-height: 70px;
	padding: 0 20px;
	color: var(--main-grey);
	margin-top: 30px;
	position: relative;
	background: #0001;
	display: block;
}

.order-ticket::before, .order-ticket::after {
	content: '';
	position: absolute;
	top: -10px;
	left: -10px;
	width: 30px;
	height: 30px;
	border: solid #aaa;
	border-width:  2px 0 0 2px;
	transition: 300ms;
}

.order-ticket::after {
	top: auto;
	left: auto;
	right: -10px;
	bottom: -10px;
	border-width:  0 2px 2px 0;
}

.order-ticket:hover::before, .order-ticket:hover::after {
	border-color: var(--main-grey);
	width: 60px;
	height: 60px;
}

.order-ticket span:nth-child(2) {
	float: right;
}

.order-ticket svg {
	position: relative;
	float: left;
	top: 15px;
	margin-right: 10px;
	line-height: inherit;
	font-size: 3em;
}

.user-page .list {
	vertical-align: middle;
	line-height: 1.6em;
	color: var(--quick-silver);
}

.user-page .list .row.head {
	border-width: 2px;
	font-weight: 500;
	margin-top: 2em;
}

.user-page .list .row {
	align-items: center;
	border-bottom: 1px solid var(--main-lightgrey);
	padding: 0 10px 10px;
	margin: 10px 0;
	position: relative;
}

.user-page .list .row .go-detail {
	width: 30px;
	height: 30px;
	line-height: 28px;
	margin-top: -10px;
	border-radius: 30px;
	font-weight: 600;
	background: #eee;
	color: #666;
	text-align: center;
	position: absolute;
	right: 5px;
	bottom: 15px;
}

.user-page .list .edit svg {
	font-size: 2em;
	margin-top: -2px
}

.user-page .list .edit span,
.user-page .list .msg span {
	display: inline-block;
	cursor: pointer;
}

.user-page .list .edit span:nth-child(2) {
	margin-left: 10px;
}

.user-page .list .edit span:nth-child(2):hover {
	color: var(--main-active);
}

.user-page .list .edit span:nth-child(1):hover,
.user-page .list .msg span:hover {
	color: var(--main-hover);
}

.user-page .list .edit .btn {
	padding: 4px 10px;
	font-size: 12px;
	margin-top: 4px;
}

.user-page .info {
	padding: 1% 13%;
	border: 1px solid var(--main-lightgrey);
}

.user-page .info .row {
	padding: 10px 0;
	line-height: 1.6em;
}

.user-page .info .row.bdr,
.user-page .list .row.bdr {
	padding: 20px 0;
	border-bottom: 1px solid var(--main-lightgrey);
}

.user-page .info a {
	color: var(--main-hover);
	text-decoration: underline;
}

.user-page .info h1 {
	text-align: center;
	padding: 10px 0 5px;
	border-bottom: 3px solid #333;
	color: #333!important;
	margin: 15px 0!important;
	font-size: 17px!important;
}

.user-page .info .row span {
	display: block;
}

.user-page .info .row.order-short .col:nth-child(3), .user-page .info .row.order-short .col:nth-child(4) {
	align-items: center;
	display: flex;
	justify-content: flex-end;
}

.user-page .info .row.return {
	padding: 30px 0;
}

.user-page .info .row.return .col:nth-child(4) {
	display: flex;
	justify-content: flex-end;
}

.user-page .info .row:last-child {
	border: none;
}
/** USER PAGE */
/* HEADER VERSİON 2 */

/* HELP */

#help {
	letter-spacing: .03em;
}

#help .card {
	border: none;
	border-bottom: 1px solid var(--main-lightgrey);
}

#help .card:last-child {
	border: none;
}

#help h2 {
	padding: 15px 0;
}

#help .help-content h2 {
	color: var(--main-grey);
	padding: 0;
	font-size: 18px!important;
}

#help p {
	font-size: 14px!important;
}

#help b, strong {
	font-weight: 600;
}

#help svg {
	margin-right: 10px;
	color: var(--quick-silver);
}

#help .help-content p,
#help .help-content tr {
	line-height: 1.6em;
}

#help .help-content a {
	color: var(--main-hover);
}

#help .help-content a:hover {
	color: var(--black);
}

#help .help-content tr {
	height: 70px;
	line-height: 2em;
	border-bottom: 1px solid var(--main-lightgrey);
}

#help .help-content ul {
	padding-left: 40px;
}

#help .help-content ul li {
	list-style: disc;
	margin-bottom: 5px;
	line-height: 1.6;
}

/** HELP **/

.pagination {
	background: #fafafa;
	justify-content: center;
	margin-top: 10px;
	margin-bottom: 30px;
}

.pagination a {
	padding: 20px 15px;
	margin: 0 5px;
	font-weight: 500;
	font-size: 14px;
	color: #3e3e3e;
	position: relative;
	transition: 300ms;
}

.pagination li.disabled a {
	opacity: .3;
	cursor: default;
}
.pagination li.active a:after, .pagination li:not(.disabled):hover a:after {
	content: '';
	height: 1.5px;
	width: 60%;
	background: #3e3e3e;
	position: absolute;
	bottom: 12px;
	left: 20%;
}

.scl-mobile-filter, header .mobile-menu-button {
	display: none;
}
.icons { font-size: 25px;}
.ril__outer {
	background: #fff!important;
}
.ril__toolbar button:hover {
	background-color: #0000004d;
}

.ril__toolbar {
	background: transparent!important;
	height: 100%!important;
	width: 60px!important;
	right: 0!important;
	left: auto!important;
	justify-content: flex-end;
	flex-direction: column-reverse;
}
.ril__toolbarSide {
	height: auto!important;
}
.ril__toolbarItem {
	background-color: #0000004d;
	height: 60px;
	margin-bottom: 5px;
	display: inline-block!important;
}
.ril__toolbarRightSide{
	flex-direction: column-reverse;
	display: flex;
	padding: 0!important;
}
.ril__builtinButton {
	width: 100%!important;
	height: 60px!important;
}

.ril__navButtonNext, .ril__navButtonPrev {
	background-color: #00000080!important;
	opacity: 1!important;
}
.ril__navButtonNext:hover, .ril__navButtonPrev:hover {
	background-color: #00000090!important;
}

.ril__navButtons{
	z-index: 1
}
