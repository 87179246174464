.col {
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.col:nth-child(1) {
	width: calc(50% - ( 100% / 77.95))
}

.col:nth-child(2) {
	width: 50%;
}

.col>*:nth-child(2) {
}
