.products {
	border-top: 2px solid #eee;
	padding: 10px 0;
	margin-top: 50px;
}

.products .swiper-container {
	padding: 10px 0!important;
}

.products :global .swiper-button-prev, .products :global .swiper-button-next {
	width: 34px!important;
	height: 34px!important;
}

.products :global .swiper-button-prev::after, .products :global .swiper-button-next::after {
	font-size: 12px;
}

.product {
	width: 90%;
	margin: 0 5% 0 0;
	padding: 5px;
	border: 1px solid #eee;
	box-shadow: 2px 3px 7px rgba(0, 0, 0, .1);
	border-radius: 3px;
	position: relative;
}

.title {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
}

.product img {
	aspect-ratio: 12 / 15;
	margin-bottom: 10px;
}

.price {
	margin-top: 10px;
	display: inline-block;
}
.price :global .old {
	text-decoration: line-through;
	margin-right: 10px;
}

.promotion {
	margin-top: 5px;
	padding: 3px;
	border-radius: 5px;
	font-size: .9em;
	color: white;
	background: orange;
	display: inline-block;
}

.add {
	display: block;
	text-align: center;
	cursor: pointer;
}

.add span:nth-child(1){
	background: black;
	color: white;
	height: 30px;
	line-height: 30px;
	border-radius: 5px;
	padding: 0 10px;
	display: inline-block;
	position: relative;
	bottom: -15px;
	transition: 300ms;
}

.add span:nth-child(1):hover{
	background: var(--main-hover);

}

.colors {
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
}

.color {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin: 0 8px 15px;
	cursor: pointer;
	position: relative;
}

.color::before {
	content: '';
	border: 1px solid #ccc;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.color.active::before {
	border: 1px solid #000;
}

.sizes {
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
	margin-bottom: 40px;
}

.colors + h2 {
	margin-top: 20px;
}

.size {
	width: 30px;
	height: 30px;
	line-height: 30px;
	margin: 5px 10px;
	background: #eee;
	border-radius: 50%;
	text-align: center;
	color: #000;
	cursor: pointer;
	position: relative;
}

.size::before {
	content: '';
	border: 1px solid #ccc;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.size.active::before {
	border: 1px solid #000;
}

.nonStock {
	position: relative;
	opacity: .5;
	background: #eee;
}

.nonStock::before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	height: 1px;
	width: 100%;
	background: black;
	transform: rotate(45deg);
}

.list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.listitem {
	margin: 20px 0;
	width: 30%;
}
@media screen and (max-width: 768px) {
	.listitem {
		margin: 20px 0;
		width: 50%;
	}
}
