@media only screen and (min-width: 1720px) {

	header .search-complete .scl-product .images, header .search-complete img {
		height: calc(1460px * .5 * .65 * .25 * 1.25)
	}

	#content .scl-product-list .scl-product .images {
		height: calc(1460px * .75 * .32 * 1.25);
	}

	#detail .detail-left .big-image {
		height: calc(1460px * .48 * .8 * 1.25)
	}

	#detail .detail-left .thump-slider .thump-image li {
		height: calc(1460px * .48 * .2 * .9 * 1.25)
	}

	#content ._404 + .scl-product-list .scl-product .images {
		height: calc(1460px * .7 * .18 * 1.25)
	}

	.last-enter .lse-product-list .lse-item .img {
		height: calc(1460px * .1166 * 1.25)
	}
}

@media only screen and (max-width:960px) {
	.modal-open {
		padding-right: 0!important;
	}

	.onne {
		display: none
	}

	iframe {
		height: calc(100vw * 0.56)
	}

	header {
		margin-bottom: 10px;
	}

	header:not(.basket) .head {
		border-bottom: 2px solid rgba(193,193,193,.5);
	}

	header .mobile-menu-button {
		display: inline-block;
	}

	header .mobile-menu-button svg:nth-child(2) {
		display: none;
	}

	header .mobile-menu-button .active svg:nth-child(1) {
		display: none;
	}

	header .mobile-menu-button .active svg:nth-child(2) {
		display: inline-block;
		padding-top: 2px;
	}

	.container {
		width: calc(100% - 30px)!important;
	}

	header .campaignSlider .campaignItem {
		left: 0;
		transform: unset;
	}

	header .user-menu {
		transform: unset;
		top: 10px;
	}

	header .user-menu > ul > li > a, header .user-menu > ul > li > .a {
		font-size: 0;
	}

	header .user-menu > ul > li > a svg, header .user-menu > ul > li > .a svg {
		font-size: 30px;
	}

	header .user-menu > ul > .login-user > .a svg:nth-child(2) {
		display: none;
	}

	header .user-menu > ul > li:not(:first-child):before {
		display: none
	}

	header .user-menu span.notif {
		right: -10px;
		left: auto;
		top: -5px;
		width: 20px;
		height: 20px;
		line-height: 20px;
		font-size: 13px;
	}

	header .user-menu .login-user ul {
		display: block;
		position: fixed;
		width: 70%!important;
		height: 100%!important;
		max-width: 100%!important;
		right: -100%;
		top: 125px!important;
		border: none;
		padding-left: 10px!important;
		padding-bottom: 125px!important;
		border: none!important;
		opacity: 1;
		z-index: 22;
	}

	header .user-menu .login-user:hover ul {
		padding: 0 0 0 10px!important;
	}

	header .user-menu .login-user ul li {
		font-size: 25px;
		margin: 5px 0;
	}

	header .user-menu .login-user ul span.hr {
		margin: 20px;
	}

	header .user-menu .login-user ul::before, header .user-menu .login-user ul::after {
		display: none;
	}

	header .search-complete {
		position: fixed;
		top: 130px;
		padding: 10px 20px;
		overflow: scroll;
		height: 100%;
		padding-bottom: 125px;
		z-index: 23;
	}

	header .head .search {
		left: 0;
		transform: unset;
	}

	header .search-complete .products {
		width: 100%;
	}

	header .search-complete .scl-product {
		width: 33%;
	}

	header .search-complete .scl-product .images, header .search-complete img {
		height: calc(((100vw - 40px) * .33 - 5px) * 1.25);
	}

	header .search-complete .scl-product span:not(.old) {
		width: 100%;
	}

	header .search-complete .sugges {
		width: 50%;
		margin-top: 50px;
		font-size: 18px;
	}
	header .search-complete .trends {
		width: 50%;
		margin-top: 30px;
		font-size: 18px;
	}

	header .search-complete .btn {
		padding: 15px;
	}

	#main-menu {
		position: fixed;
		width: 70%;
		height: 100%;
		top: 112px;
		left: -70%;
		box-shadow: 0 10px 10px rgba(0,0,0, .1);
		font-size: 16px;
		border-left: 1px solid rgba(0,0,0, .1);
		overflow: scroll;
		padding-bottom: 125px;
		z-index: 24;
	}

	#main-menu > .container > ul {
		padding-bottom: 100px;
		text-align: left;
	}

	#main-menu > .container > ul > li {
		display: block;
	}

	#main-menu > .container > ul > li > .main-title {
		display: block;
		font-size: 18px;
		border-bottom: 1px solid #ccc!important;
		height: 70px;
		line-height: 70px;
		margin: 0;
	}

	#main-menu > .container > ul > li:hover > .main-title {
		height: 70px;
		border-bottom: 1px solid #ccc!important;
		font-weight: unset;
	}

	#main-menu > .container > ul > li > .main-title > span:nth-child(1) {
		left: 0;
		transform: unset;
		width: 100%;
		text-align: left;
	}

	#main-menu > .container > ul > li > .main-title > span:nth-child(2) {
		display: none;
	}

	#main-menu > .container > ul > li > .main-title svg {
		float: right;
		position: relative;
		top: 15px;
		display: none;
	}

	#main-menu > .container > ul > li.active > .main-title  svg:nth-child(2) {
		display: inline-block;
	}

	#main-menu > .container > ul > li:not(.active) > .main-title svg:nth-child(1) {
		display: inline-block;
	}

	#main-menu > .container > ul > li.two .menu-children {
		width: 100%;
	}

	#main-menu > .container > ul > li.notTitle .menu-children {
		padding-top: 0;
	}

	#main-menu .menu-children::before {
		display: none;
	}

	#main-menu .menu-children h3, #main-menu .menu-children ul li a, #main-menu .menu-children ul li span {
		font-size: 20px;
		margin: 10px 5px;
		padding: 5px 0;
	}

	#main-menu .menu-children h3 a, #main-menu .menu-children h3, #main-menu .menu-children ul li a, #main-menu .menu-children ul li span {
		color: currentColor;
		display: block;
	}

	#main-menu .menu-children.big, #main-menu .menu-children {
		position: relative;
		top: 0;
		left: 0;
		opacity: 1;
		display: block!important;
		box-shadow: none;
		padding: 0;
		overflow: hidden;
		height: 0;
		border: none
	}

	#main-menu .menu-children .col-3, #main-menu .menu-children .col {
		width: 100%;
		flex: 0 0 auto;
	}

	#main-menu>.container > ul > li.two .menu-children {
		left: 0
	}

	#content {
		padding: 0;
	}

	#content .scl-sidebar {
		position: fixed;
		width: 70%!important;
		height: 100%!important;
		top: 0px;
		background: #fff;
		padding: 50px;
		left: -100%;
		z-index: 23;
		display: block;
		opacity: 1;
		float: unset;
		padding-bottom: 125px;
		transition: unset;
	}

	#content .scl-content {
		width: 100%;
		position: relative;
	}

	#content .scl-content.list-page {
		padding-top: 50px;
	}

	#content .scl-content.list-page h1 {
		position: absolute;
		top: 0;
		left: 0;
	}

	#content:not(.user-page) .scl-content .scl-content-title {
		padding: 0;
		margin-left: -15px;
		width: calc(100% + 30px);
	}

	#content .scl-content .scl-content-title .scl-content-sort {
		width: 50%;
		padding: 15px 10px;
		text-align: center;
		border-right: 0;
	}

	#content .scl-content .scl-content-title .scl-mobile-filter {
		float: left;
		width: 50%;
		padding: 15px 10px;
		background: white;
		border: 1px solid var(--main-lightgrey);
		border-left: 0;
		top: 0;
		text-align: center;
		display: block;
		cursor: pointer;
	}

	#content .scl-content .scl-content-title .scl-content-sort svg {
		position: relative;
		top: 0;
		right: -5px;
	}

	#content .scl-content .scl-content-title .scl-content-sort ul li span {
		padding: 18px 10px;
	}

	#content .scl-product-list .scl-product .title {
		margin-top: 60px;
	}

	#content .scl-product-list .scl-product .images {
		height: calc((100vw - 30px) * .3233 * 1.25)
	}

	#content .scl-product-list .scl-product .hover-menu.quick {
		opacity: .6;
		display: block;
		padding: 0;
		margin: 0;
		width: 100%;
	}

	#content .scl-product-list .scl-product .colors-num {
		bottom: 130px;
	}

	#content .scl-product-list .scl-product .hover-menu.quick button.quick {
		width: 100%;
	}

	#content .scl-product-list .scl-product .price .old {
		font-size: .9em;
	}

	#content ._404 {
		padding-top: 40px;
	}

	#content ._404 + .scl-product-list {
		padding: 0 5%;
	}

	#content ._404 + .scl-product-list .scl-product .title {
		margin-top: 0;
	}
	#content ._404 + .scl-product-list .scl-product .colors-num {
		bottom: 60px;
	}

	#content.malls .scl-sidebar {
		display: block;
		width: 100%!important;
		float: left;
		left: 0;
		padding: 0 0 20px;
		position: unset;
	}

	#content.malls .scl-sidebar .mb-3 {
		width: 50%;
	}

	#content.malls .row:first-child {
		margin-top: -20px
	}

	#content.malls .row>* {
		margin-top: 20px
	}

	.user-page .btn.user-title-btn {
		padding: 3px;
	}

	.user-page .btn.user-title-btn svg {
		font-size: 20px;
	}

	.user-page .list .row>*:nth-child(1) {
		flex: 1 0 auto;
	}

	.user-page .list .row>* {
		flex: 2 0 auto;
		padding: 0 10px 0 0
	}

	.user-page .list .row > .msg {
		display: none
	}

	.user-page .list .edit span:nth-child(1) {
		color: var(--main-green)
	}

	.user-page .scl-section-content .row > .col {
		width: 100%;
		flex: 0 0 auto;
		padding: 0 20px!important;
	}

	.user-page .scl-section-content .form-btn {
		margin: 10px!important
	}

	.user-page .scl-section-content.pr-5 {

	}

	header.basket {
		margin-bottom: 0;
	}

	#basket .container {
		width: calc(100% - 40px)!important;
		padding-top: 0;
	}

	#basket .container .basket-product-list {
		width: 100%;
		overflow: hidden;
	}

	#basket .basket-info {
		top: 0;
	}

	footer ul.help li {
		margin: 20px 2%;
		border: none;
	}

	footer .container .row:first-child .col-6 {
		width: 40%;
	}

	footer .container .row:first-child .col-5 {
		width: 60%;
	}

	footer .container .row:first-child .col-6 + .col {
		display: none;
	}

	.close-page-hover, .close-page-click {
		z-index: 10;
	}

	.full-banner h1, .full-banner span {
		max-width: 100%;
		width: 80%;
	}

	.full-banner h1 {
		top: 20px
	}

	.full-banner span {
		top: 70px
	}

	.scl-modal {
		 width: 100%!important;
		 max-width: 100%!important;
	 }

	#loginModal .scl-modal {
		width: 500px!important;
	}

 	.scl-modal .scl-modal-close {
 		right: 0;
 		top: 0;
 		width: 40px;
 		height: 40px;
 		line-height: 40px
 	}

	.react-slideshow-container+ul.indicators {
		background: #fff;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 0;
		padding: 5px 10px 3px;
		border-radius: 3px;
	}
	.react-slideshow-container+ul.indicators li {
		width: 10px;
		height: 10px;
		background: #ccc;
		overflow: hidden;
		margin: 0 5px!important;
		border-radius: 50%;
	}

	.react-slideshow-container+ul.indicators li.active {
		background: #5e5e5e;
	}

	#detail .detail-left .big-image{
		height: calc((100vw - 30px) * .48 * .8 * 1.25);
	}

	#detail .detail-left .thump-slider .thump-image li {
		height: calc((100vw - 30px) * .48 * .9 * .2 * 1.25)
	}

	.quickModal #detail .detail-left .thump-slider .thump-image li {
		height: calc(100% * .48 * .9 * .2 * 1.25);
	}

	.combimeModal .combine-products {
		width: 100%;
		height: auto;
		border-bottom: 1px solid rgba(0, 0, 0, 1);
		padding-bottom: 5px;
		display: flex;
		flex-wrap: nowrap;
	}

	.combimeModal .scl-modal .modal-content {
		margin-top: -35px!important;
		max-height: 100vh;
	}

	.combimeModal .combine-products::after {
		background: transparent;
		color: black;
		font-size: 14px;
		font-weight: 500;
		line-height: 48px;
	}
	.combimeModal .scl-modal .scl-modal-close {
		background: transparent;
		color: black;
		font-size: 28px;
		line-height: 44px;
		font-family: initial;
	}

	.combimeModal .combine-products {
		margin-top: 45px;
		padding-top: 8px;
		border: 1px solid #ddd;
	}

	.combimeModal .combine-products span {
		width: 17%;
		display: inline-block;
		margin-right: 2%;
	}

	.combimeModal #detail {
		width: 100%;
		padding-bottom: 100px;
	}

	.combimeModal .combine-products::after {
		width: 100%;
	}

	.combimeModal #detail .detail-right {
		overflow: visible;
		height: auto;
		max-height: none;
		padding-right: 0;
	}

	.combimeModal #detail .detail-left .big-image {
		height: calc((100vw - 30px) * 1.25);
	}

	.scl-product.combine:hover .combine-list {
		opacity: 0;
	}

	#content ._404 + .scl-product-list .scl-product .images {
		height: calc((100vw - 30px) * .9 * .185 * 1.25)
	}

	.ril__toolbar {
		width: 45px!important;
	}

	.ril__toolbarItem {
		line-height: 40px!important;
		height: 40px!important;
		width: 40px;
	}

	.ril__builtinButton {
		width: 40px!important;
		height: 40px!important;
	}

	.ril__navButtons {
		top: 135px!important;
		right: 5px!important;
		left: auto!important;
		margin: 0!important;
		padding: 0!important;
		height: 40px!important;
		width: 40px!important;
	background-size: 15px!important;
	}
	.ril__navButtonPrev {
		top: 180px!important;
	}
	.ril__toolbarLeftSide{
		opacity: 0
	}
}

@media only screen and (max-width:768px) {

}

@media only screen and (max-width:640px) {

}

@media only screen and (max-width:540px) {

	.category .col {
		width: 50%;
		flex: 0 0 auto;
	}

	header .campaignSlider  {
		display: none;
	}

	header {
		height: 126px;
		z-index: 19;
	}

	header .head {
		height: 46px;
	}

	header .head .logo {
		margin-top: -18px;
	}

	header .other-brand {
		height: 41px;
	}

	header .head .logo svg {font-size: 1.2em}

	header .head .search {
		width: calc(100% + 30px - 115px);
		left: calc(-15px + 115px);
		top: 40px;
		transform: none;
		height: 45px;
		display: block;
	}

	header .head .search input {
		width: calc(100% - 30px)!important;
		height: 40px;
		top: 7px;
		border-bottom: 3px solid #000!important;
		transform: unset;
		left: 15px;
		line-height: 1;
		padding: 0 0 0 40px;
		text-align: left;
	}

	header .head .search svg {
		width: 20px;
		height: 20px;
		line-height: 30px;
		left: 6%;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;
		background: unset;
		padding: 0;
	}

	header .head .search .close-search {
		left: 78%;
	}

	header:not(.basket) .head {
		border-bottom: none;
	}

	header .search-complete .products {
		width: 100%;
		float: unset
	}

	header .search-complete .scl-product {
		width: 50%;
	}

	header .search-complete .sugges {
		width: 100%;
		margin-top: 30px;
	}

	header .search-complete .trends {
		width: 100%;
		font-size: 16px;
		margin-bottom: 15px;
	}

	header .search-complete .trends a {
		padding: 10px 15px;
		margin: 8px 10px;
	}

	header .search-complete .scl-product .images, header .search-complete img {
		height: calc(((100vw - 40px) * .5 - 5px) * 1.25);
	}

	header .user-menu {
		top: 2px;
		position: unset;
	}

	header .user-menu .list-inline-item {
		margin-left: 15px;
	}

	header .user-menu .list-inline-item:first-child {
		display: none;
	}

	header .user-menu .login-user {
		width: 30px;
		height: auto!important;
	}

	header .user-menu .login-user:active .a.user-icon {
		border-color: var(--main-green);
	}

	header .user-menu .a.user-icon {
		background: #f0f0f0;
		width: 28px;
		height: 28px;
		line-height: 26px;
		display: block;
		font-size: 20px;
		color: #555;
		border-radius: 50%;
		text-align: center;
		position: absolute;
		border: 1px dashed #ddd;
		top: 6px;
		left: -2px;
		transition: 500ms;
	}

	header .user-menu .login-user ul li {
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 500;
		margin: 0;
	}

	header .user-menu .login-user ul li a {
		height: 50px!important;
		line-height: 30px;
	}

	header .user-menu > ul > li > a svg,
	header .user-menu > ul > li > .a svg {
		font-size: 20px;
	}

	header .user-menu span.notif {
		width: 16px;
		height: 16px;
		line-height: 16px;
		right: -8px;
		top: 0;
		font-size: 12px;
	}

	header .user-menu .login-user ul  {
		top: 0px!important;
		z-index: 900;
		transform: unset!important;
		width: 80%!important;
		overflow-y: scroll!important;

	}

	header .mobile-menu-button {
		position: absolute!important;
		top: 50px;
		left: -15px;
		z-index: 100;
		padding: 8px 15px!important;
		background: black;
		color: #fff;
		font-size: 20px!important;
		align-items: flex-start;
	}

	header .mobile-menu-button .a {
		font-size: 15px!important;
	}

	header .mobile-menu-button svg {
		margin-right: 10px;
		font-size: 19px!important;
	}

	#main-menu {
		width: 80%;
		height: 100%;
		top: 0px;
		left: -100%;
	}

	#main-menu > .container > ul > li > .main-title {
		font-size: 13px;
		height: 50px!important;
		line-height: 50px;
		margin-bottom: 0!important;
	}

	#main-menu > .container > ul > li > .main-title.menu-red {
		margin: 0 -15px;
		padding: 0 15px!important;
		overflow: hidden;
	}

	#main-menu > .container > ul > li > .main-title.menu-red span {
		left: 15px;
	}

	#content:not(.user-page) .scl-content .scl-content-title.title-red {
		background: white;
		padding: 0;
	}

	#content:not(.user-page) .scl-content .scl-content-title.title-red h1 {
		color: #E81932
	}

	#main-menu .menu-children h3,
	#main-menu .menu-children ul li a,
	#main-menu .menu-children ul li span {
		font-size: 14px;
	}

	nav[aria-label=breadcrumb] .container {
		overflow: hidden;
	}

	nav[aria-label=breadcrumb] .container ol {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: inline-block;
		padding: 12px 0;
		margin-bottom: 0
	}

	nav[aria-label=breadcrumb] .container ol li {
		display: inline-block;
	}

	nav .breadcrumb-item+.breadcrumb-item::before {
		top: -1px;
	}

	#content .scl-sidebar {
		padding: 20px;
	}

	#content .scl-product-list .scl-product .images {
		height: calc((100vw - 30px) * .49 * 1.25)
	}

	#content .scl-product-list .scl-product .hover-menu.quick {
		display: none;
	}

	#content .scl-product-list .scl-product .favorite {
		top: 5px;
		right: 5px;
		font-size: 18px;
	}

	#content .scl-product-list.two-column .scl-product {
		margin-bottom: 2rem;
		width: 48.5%;
	}

	#content .scl-product-list.two-column .scl-product._2, #content .scl-product-list.two-column .scl-product._3 {
		width: 100%;
	}

	#content .scl-product-list .scl-product .colors-num {
		bottom: 70px;
	}

	#content .scl-product-list .scl-product .title {
		margin-top: 0;
	}

	#content.malls .scl-sidebar .mb-3 {
		width: 100%;
	}

	.scl-product.combine .combine-product .image {
		width: 40px;
	}

	.scl-product.combine .combine-product span {
		font-size: 12px;
		padding-left: 45px;
	}

	#content .scl-product-list .scl-product.combine .look-modal {
		width: 100%;
	}

	.user-page .info {
		padding: 0 20px;
	}

	.user-page .basket-empty {
		position: unset;
		top: unset;
		left: unset;
		transform: unset;
		margin: 3em 0;
	}

	#detail {
		padding-bottom: 25px;
	}

	#detail + footer {
		margin: 0;
		width: 100%;
		overflow: hidden;
	}

	#detail .detail-right, #detail .detail-left {
		width: 100%!important;
		margin: 0;
	}

	#detail .detail-right {
		padding-top: 20px;
		padding-bottom: 0;
	}

	#detail .detail-right .services {
		margin-top: -5px;
		margin-left: -10px;
		width: 100%
	}

	#detail .detail-right .services ul li {
		font-size: .9em;
		width: 50%!important;
	}

	#detail .detail-right .services ul li svg {
		font-size: 5em;
	}

	#detail .detail-right .services ul li span:nth-child(2){
		margin: 12px 0 8px 0px;
		font-size: 12px;
	}






	#detail .detail-right .go-basket .col-2:last-child {
		left: 8px;
		width: 10%;
		bottom: 18px;
		font-size: .85em;
	}



	#detail .detail-right .ipricee {
		background: #fff;
		height: 68px;
		position: fixed;
		left: 0;
		bottom: 0;
		margin: 0;
		padding-left: 55px!important;
		font-size: 0.93rem;
		border-top: 2px solid #ccc;
		z-index: 15;
		text-align: center;
		justify-content: left !important;
		display: flex !important;
		padding-top:11px;
		padding-bottom:20px;

	}

	#detail .detail-right .pricee {
		background: #fff;
		height: 68px;
		position: fixed;
		left: 0;
		bottom: 0;
		margin: 0;
		padding-left: 10px!important;
		font-size: 1.4rem;
		border-top: 2px solid #ccc;
		display: flex;
		flex-direction: column;
		justify-content: center;
		z-index: 15;
		text-align: center;
	}

	#detail .detail-right .social {
		height: auto;
		padding: 0;
	}

	#detail .detail-right .social > ul {
		margin-bottom: 25px;
		float: left
	}

	#detail .detail-right .social > ul li {
		width: 48%!important;
		margin: 5px 1%!important;
	}

	#detail .detail-right .social ul li  {
		height: 56px;
		text-align: left;
		white-space: nowrap;
		word-wrap: normal;
	}

	#detail .detail-right .social ul li svg {
		width: auto;
		margin-bottom: 5px;
		margin-right: 7px;
	}

	#detail .detail-right .social ul li.friend svg,
	#detail .detail-right .social ul li.phone svg {
		margin-bottom: 8px;
	}

	#detail .detail-right .social ul li:last-child span {
		padding-top: 3px;
		display: inline-block;
	}

	#detail .detail-right .social .share-window {
		bottom: -160px;
		left: 20px;
	}

	#detail .detail-right .scl-select-option ul li:first-child {
		margin-left: 0;
	}

	#detail .detail-left, #detail .detail-left .big-image {
		width: 100%;
		height: calc((100vw - 30px) * 1.25);
		margin-bottom: 25px;
	}

	#detail .detail-left .big-image .swiper-container {
		height: calc((100vw - 30px) * 1.25 + 45px);
	}

	#detail .detail-left .thump-slider {
		display: none;
	}

	.combimeModal .scl-modal .modal-content {
		margin-top: 0!important;
	}

	.swiper-container {
		padding-bottom: 50px!important;
	}

	.complete-dress .product-list .item, .complete-dress .product-list .item a {
		width: 100%;
	}

	.complete-dress .product-list .swiper-slide{
		width: calc((100vw - 30px))!important;
	}

	.complete-dress .product-list .item {
		width: calc((100vw - 40px) * .5)!important;
		float: left;
	}

	.complete-dress .product-list .item .img {
		height: calc((100vw - 40px) * .5 * 1.25 );
		position: relative;
	}

	.add-basket-product .img {
		width: 30%;
	}

	.complete-dress .swiper-container-horizontal>.swiper-pagination-bullets {
			bottom: 0px;
			width: 100%!important;
		}

	.complete-dress .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
		border: 2px solid #000;
		width: 9px;
		height: 9px;
		background: unset;
	}

	#content ._404 {
		width: 100%;
	}

	#content ._404 h1 {
		width: 100%;
		margin-bottom: 10px;
	}

	#content ._404 h1 + .row {
		margin: 2rem 0!important;
	}

	#content ._404 h1 + .row .col-6 {
		width: 80%;
	}

	#content ._404 + .scl-product-list .scl-product {
		width: 49%;
		margin-right: 2%;
	}

	#content ._404 + .scl-product-list .scl-product .images {
		min-height: 190.2px
	}

	#content ._404 + .scl-product-list .scl-product:nth-child(2n) {
		margin-right: 0;
	}

	header.basket {
		height: 60px;
	}

	header.basket .scl-head-msg, header.basket .user-menu {
		display: none;
	}

	#basket h1.tabs {
		text-align: center;
	}

	#basket h1.tabs span, #basket h1.tabs span.active {
		font-size: 14px;
		padding: 5px 0;
		width: 30%;
	}

	#basket .choices-select .item img {
		display: inline-block;
	}

	#basket .choices-select .item {
		padding: 10px 0 10px 40px;
	}

	#basket .choices-select.installment {
		width: calc(50% - 20px);
	}

	#basket .basket-product-list .row:first-child {
		display: none;
	}

	#basket .basket-product-list .row.dashedTB div:nth-child(3) {
		position: absolute;
		top: 75px;
		right: -55px;
	}

	#basket .basket-product-list .row.dashedTB div:nth-child(4) {
		position: absolute;
		top: 20px;
		right: 20px;
	}

	#basket .basket-product-list .input-number {
		width: 60px;
	}

	#basket .basket-product-list .input-number input[type=number] {
		height: 26px;
	}

	#basket .basket-product-list .input-number .plus, #basket .basket-product-list .input-number .sour {
		line-height: 26px;
		padding: 0;
		width: 20px;
	}

	#basket .basket-product-list .input-number .plus > svg, #basket .basket-product-list .input-number .sour > svg {
		position: relative;
	}

	#basket .basket-product-list + .col {
		margin-top: 30px;
	}

	#basket .steps {
		margin: 0;
		padding: 15px 0;
	}

	#basket .steps .check {
		width: 30px;
		height: 30px;
		margin: 0 10px 0 0;
	}

	#basket .steps li {
		margin-right: 0;
		padding: 0 20px 0 0
	}

	#basket .steps li:last-child {
		padding: 0
	}

	#basket .address-select .address {
		width: 100%;
	}

	#basket .address-select .new-address {
		font-size: 18px;
	}

	#basket .basket-success h1 {
		padding-top: 40px;
	}

	#basket .credit-card {
		position: absolute;
		top: 5%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10;
		display: none;
	}

	#basket .credit-card.active {
		display: block;
	}

	#basket .credit-card .close {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 30px;
		height: 30px;
		line-height: 30px;
		border-radius: 30px;
		background: #999;
		text-align: center;
	}

	#basket .mobile-fixed {
		width: 100%;
		background: white;
		position: fixed;
		bottom: 0;
		left: 0;
		padding: 10px;
		margin: 0;
		box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.2);
		transition: 300ms all;
		align-items: center;
	}

	#basket .mobile-fixed .price span {
		display: block;
	}

	#basket .mobile-fixed .price span:nth-child(2) {
		margin-top: 8px;
		font-weight: 500;
		font-size: 18px;
		color: var(--main-color)
	}

	#basket .basketMessage {
		font-size: 12px;
		line-height: 1.5em;
	}

	#basket .basketMessage .dangerCampaign {
		padding: 6px 20px 3px;
	}

	#basket .basketMessage .successCampaign {
		padding: 6px 20px;
	}

	.quickModal #detail .detail-right {
		padding: 0;
	}

	.quickModal .scl-modal .modal-content {
		max-height: 100%;
	}

	.ReactModal__Overlay {
		z-index: 1051!important
	}

	.scl-modal {
		width: 100%!important;
		max-width: 100%!important;
		margin: 0;
	}

	.last-enter .swiper-slide {
		max-width: 50%;
	}

	.last-enter h2.hr span {
		padding: 0;
	}

	.last-enter .lse-product-list .lse-item .img {
		height: calc((100vw - 40px) * .5 * 1.25);
		position: relative;
	}

	.last-enter .swiper-container {
		padding-bottom: 30px;
	}

	.last-enter .swiper-container-horizontal>.swiper-pagination-bullets {
		width: 100%!important;
	}

	#loginModal .scl-modal .modal-content {
		padding: 50px 20px 20px;
	}

	#sizeChartModal .modal-content {
		padding: 40px 10px;
	}

	#sizeChartModal .scl-modal .col {
		overflow: scroll;
	}

	#sizeChartModal .product-chart {
		white-space: nowrap;
		overflow-x: scroll;
		margin-bottom: 0;
		padding-bottom: 20px;
	}

	footer .container {
		padding: 30px 10px 20px
	}

	footer .container .row:first-child .col-6, footer .container .row:first-child .col-5 {
		width: 100%;
	}

	footer ul.help {
		text-align: left;
		margin: 10px 0 0;
		font-weight: 500;
		letter-spacing: .5px;
	}

	footer ul.help li {
		text-align: left;
		display: block;
		margin: 10px 0;
	}

	footer .social {
		text-align: center!important;
		margin-bottom: 40px;
		padding-top: 0!important;
	}

	.full-banner {
		margin-top: 0;
	}

	.full-banner h1 {
		font-size: 16px;
		width: 100%;
		top: 0px;
		text-shadow: 8px 4px 4px black, -8px -4px 4px black, 8px -4px 4px black, -8px 4px 4px black
	}

	.full-banner span {
		top: 30px;
		width: 100%;
		font-size: 13px;
	}

	.go-head {
		width: 36px;
		height: 36px;
		line-height: 36px;
		right: 10px;
		bottom: 75px;
	}
	.swal2-actions {
		padding: 0!important;
	}

	.banner h2 {
		font-size: 12px;
	}

	.banner h2 span {
		padding: 0 10px;
	}

	.banner.brands {
		background: #f3f3f3;
		font-size: 16px;
		padding: 2em 0 2.5em;
	}

	.banner.brands h1 {
		padding-bottom: 1.5em;
		font-size: 18px;
		letter-spacing: .3em;
	}

	.banner.one-click a {
		position: relative;
		background: #fff;
		display: block;
		padding: 20px;
		font-size: 14px;
		font-weight: 600;
		border-bottom: 2px solid #eee;
	}

	.banner.one-click a:first-child {
	    border-top: 2px solid #eee;
	}

	.banner.one-click svg {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%) rotate(270deg);
		opacity: .8;
	}

	.occasion .occa {
		width: 45%;
		margin-right: 10%;
		border: none;
		margin-left: 3%;
	}

	.occasion .occa:nth-child(2n) {
		margin-left: 0;
		margin-right: 0;
		width: 42%
	}

	#ilmio-hashtag-slider .carousel-indicators li {
		width: 10px;
		height: 10px;
		border-top: 0;
		border-radius: 10px;
		border-bottom: 0;
	}

	.swiper-container {
		padding-bottom: 45px!important;
		min-height: 100%;
	}

	.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
		border: 2px solid #000;
		width: 9px;
		height: 9px;
		background: unset;
	}

	.swal2-modal .swal2-title {
		width: 100%;
	}

	.swal2-modal .swal2-styled {
		width: 100%;
	}

	.swal2-actions {
		margin-top: 0;
	}

	.swal2-content {
		padding: 0!important;
		font-size: 16px!important;
	}

	.swal2-validation-message {
		margin: 0 -1.2em!important;
	}

	.close-usermenu {
		color: #000;
		padding: 10px;
	}

	header .user-menu .close-usermenu > svg {
		margin-right: 0;
	}

	#ETBIS {
		position: absolute;
		bottom: 110px;
		right: 0;
	}

	@supports (-webkit-touch-callout: none) {
		#detail .detail-right .price {
			height: 80px;
		}
		#detail .detail-right .go-basket .col-2:last-child {
			bottom: 25px;
		}
		#detail .detail-right .go-basket .col-8 {
			bottom: 17px;
		}
	}
}

@media only screen and (max-width:385px) {

}

@media only screen and (max-width:330px) {

}
