.modal {
	background: #0005;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1050;
	text-align: center;
	vertical-align: middle;
	transition: .5s opacity;
	opacity: 0;
}

.container {
	width: 50%;
	max-width: 100%;
	position: absolute;
	top: 0;
	left: 25%;
	max-height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 13px 53px;
	transition: 1s top;
}

.container::-webkit-scrollbar{
	background: #eee;
	width: 10px;
	height: 5px;
}

.container::-webkit-scrollbar-thumb {
	cursor: pointer;
	background: #6e6e6e;
}

.container::-webkit-scrollbar-thumb:hover {
	background: #3e3e3e;
	cursor: pointer!important;
}

.container.mini {
	width: 610px;
	left: calc((100% - 610px) / 2)
}

.container.large {
	width: 64%;
	left: 18%
}

.container.full {
	width: 100%;
	left: 0
}

.content {
	background: white;
	padding: 40px 40px 20px;
}

.close {
	background: #000;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	position: absolute;
	top: 0;
	right: 40px;
	color: #fff;
	font-size: 26px;
	cursor: pointer;
}

@media screen and (max-width: 768px) {
	.container, .container.mini, .container.large, .container.full {
		width: 100%;
		left: 0;
		padding: 0;
	}

	.close {
		right: 0;
	}

	.content {
		padding: 40px 10px;
	}
}
