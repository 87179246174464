.orderInfo {
	display: flex;
	justify-content: space-between;
	margin: 50px 0;
	padding: 0 50px;
}

.orderCode {}

.orderInfo > div > span {
	font-weight: 500;
	text-align: left;
	display: block;
}

.orderInfo > div > span:nth-child(1) {
	color: var(--main-green);
	margin-bottom: 5px;
	text-transform: uppercase;
}

.orderInfo span:nth-child(2) > span {
	display: block;
}

.orderCode span:nth-child(2) {
	font-size: 24px;
	font-weight: 500;
}

@media screen and (max-width: 768px) {
	.orderInfo {
		flex-wrap: wrap;
		margin-top: 0px;
		padding: 0;
	}

	.orderInfo > div > span {
		text-align: center;
	}

	.col {
		justify-content: center;
		width: 100%;
		margin-top: 20px;
	}
}
