.button {
	position: relative;
	cursor: pointer;
	padding: 5px 10px 5px 30px;
	text-align: left;
}

.check {
	position: absolute;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	border: 2px solid #ccc;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}

.button:hover .check {
	border-color: var(--main-hover);
	opacity: .7;
}

.button.active .check {
	border-color: var(--main-hover);
}

.button.active .check::before {
	content: '';
	background: var(--main-hover);
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.button.active .check::before {
	border-radius: 50%;
	transform: translate(-50%, -50%);
}
