.forgotPassword {
	line-height: 1.6
}

.forgotPassword h2 {
	color: black;
	margin: 0 0 20px;
	font-size: 20px
}

.forgotPassword label {
	text-align: left;
	margin-top: 30px;
}

.tabs {
	margin-top: 30px;
	margin-bottom: 20px;
	justify-content: start;
	display: flex;
}

.tabs span {
	padding-left: 40px;
	margin-right: 30px;
	position: relative;
	cursor: pointer;
	transition: 500ms;
}

.tabs span:hover {
	color: #000;
}

.tabs span:before {
	content: '';
	border-radius: 50%;
	width: 20px;
	height: 20px;
	border: 2px solid #ccc;
	position: absolute;
	left: 10px;
	top: 50%;
	transform: translateY(-50%);
}

.tabs span.active {
	font-weight: 600;
}

.tabs span.active:after {
	content: '';
	border-radius: 50%;
	width: 10px;
	height: 10px;
	background: var(--main-green);
	position: absolute;
	left: 15px;
	top: 50%;
	transform: translateY(-50%);
}

.tabs span.active::before {
	border-color: var(--main-green);
}
