.iban {
	border: 2px solid #ccc;
	flex-basis: 30%;
	padding: 10px;
	margin: 10px 15px 10px 0;
	border-radius: 5px;
	text-align: left;
}

.iban .ibanTitle {
	font-size: 1.1em;
	font-weight: 500;
	margin-top: 0;
}

.iban span {
	display: block;
	margin: 15px 0 0;
}

.delete {
	float: right;
	color: var(--main-red);
	cursor: pointer;
}

.delete:hover {
	text-decoration: underline;
}

.iban.checking {
	position: relative;
	cursor: pointer;
}

.iban .check {
	width: 20px;
	height: 20px;
	border: 2px solid var(--main-grey);
	border-radius: 50%;
	position: absolute;
	top: -8px;
	right: 8px;
}

.iban.active .check {
	border-color: var(--main-hover);
}

.iban .check::before {
	content: '';
	background: var(--main-hover);
	width: 10px;
	height: 10px;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
}
.iban:hover .check::before {
	opacity: .5;
}

.iban.active .check::before {
	opacity: 1;
}
