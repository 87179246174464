.social {
	display: flex;
	justify-content: space-around;
	padding: 10px 0 5px;
}

.social :global .btn {
	background: transparent;
	color: black;
	margin: 0 12px;
	border-radius: 6px;
	border: 2px solid #ccc;
	color: #999;
	min-width: 45%;
	font-weight: 500;
	font-size: 14px;
	transition: 500ms all;
	padding: 8px;
}

.social :global .btn:hover {
	border: 2px solid #666;
	color: #333;
}

.social :global svg {
	margin-right: 10px;
}

.hr {
	height: 30px;
	margin: 10px 10px 20px;
	position: relative;
	text-align: center;
	display: block;
}

.hr::before {
	content: '';
	background: #ccc;
	width: 100%;
	height: 1px;
	position: absolute;
	top: 50%;
	left: 0;
}

.hr span {
	background: white;
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}
