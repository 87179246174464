@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;600;800&display=swap');

.poppins,
body {
	font-family: 'Poppins', sans-serif;
}

body {
	line-height: 1;
	font-size: 13px;
	overflow-x: hidden;
}

body.fixed-header {
	padding-top: 155px;
}

.container {
	padding: 0;
	width: calc(100% - 260px);
	max-width: 1460px;
}

header .user-menu span.notif {
	font-size: 10px;
}

header .user-menu svg {
	margin-right: 5px;
}

#detail .detail-right .info,
.user-page .btn.user-title-btn,
footer,
footer ul li,
header .user-menu .list-inline-item,
nav .breadcrumb {
	font-size: 12px;
}

#content .scl-sidebar h3.filter-title,
#detail .detail-content,
.user-panel,
footer h2,
header .top {
	font-size: 14px;
}

#content .scl-product-list .scl-product .price,
#detail .detail-content > ul > li,
#detail .detail-content p,
#detail .detail-right .scl-select-option ul li::before,
#detail .detail-right .share li,
h1,
h2,
h3,
h4,
header .head .search i {
	font-size: 16px;
}

#detail .detail-right .price,
{
	font-size: 18px;
}

#content .scl-product-list .scl-product .favorite,
#detail .detail-left .thump-slider .down,
#detail .detail-left .thump-slider .up,
.scl-modal .scl-modal-close {
	font-size: 24px;
}

svg {
	font-size: 1.6em;
}

a:hover {
	color: initial;
}

a:not([href]){
	cursor: default;
}

#basketModal .basket-modal-close,
.order-ticket i,
header.basket .secure-pay svg {
	font-size: 2.2rem;
}

[disabled] {
	opacity: .7;
}

.bgw {
	background: white;
}

#detail .detail-left .thump-image::-webkit-scrollbar,
.detail-left::-webkit-scrollbar,
.detail-right::-webkit-scrollbar,
.modal-body::-webkit-scrollbar,
.hide-scroll::-webkit-scrollbar {
	display: none;
}

#detail .detail-left .thump-image,
.detail-left,
.detail-right,
.hide-scroll,
.modal-body {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.scl-scroll {
	height: 200px;
	overflow-x: hidden;
	overflow-y: scroll;
	padding-right: 15px;
	line-height: 2
}

.scl-scroll br {
	display: none
}

#sizeChartModal .product-chart::-webkit-scrollbar,
.quickModal #detail .detail-right::-webkit-scrollbar,
.scl-scroll::-webkit-scrollbar
{
	background: #eee;
	width: 5px;
	height: 5px;
	padding: 10px;
}

#sizeChartModal .product-chart::-webkit-scrollbar-thumb,
.quickModal #detail .detail-right::-webkit-scrollbar-thumb,
.scl-scroll::-webkit-scrollbar-thumb
{
	cursor: pointer;
	background: #6e6e6e;
}

.scl-scroll::-webkit-scrollbar-thumb:hover
{
	background: #3e3e3e;
	cursor: pointer!important;
}

#basketModal .content,
.scl-modal .modal-content {
	border: none;
}

h2.hr span {
	background: transparent;
	letter-spacing: 8px;
}

h2.hr::before {
	width: 20%;
	top: auto;
	bottom: 0;
	left: 50%;
	border-width: 1px;
	transform: translateX(-50%);
	display: none;
}

span.hr {
	background: #ddd;
}

form .form-label {
	margin-left: 20px;
}

input[type=checkbox] + span {
	width: 20px;
	height: 20px;
}

input[type=checkbox] + span:after {
	font-size: 14px;
}

.scl-modal .scl-modal-close {
	width: 30px;
	height: 30px;
	line-height: 30px;
	background: #000;
	color: white;
	top: -15px;
	right: -15px;
	border: none;
	border-radius: 0;
}

#basketModal .basket-modal-close {
	border: none;
	border-radius: 0;
}

header {
	top: 0;
	z-index: 23;
}

header .top {
	width: 100%;
	height: 40px;
	line-height: 40px;
	background: #000;
	text-align: center;
	color: white;
}

header .top-left {
	position: absolute;
}

header .campaignSlider .campaignItem {
	height: 30px;
	color: #fff;
	position: absolute;
	top: -50px;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
	text-transform: uppercase;
}

header .campaignSlider .campaignItem.active {
	opacity: 1;
	top: 0;
}

header .campaignSlider .campaignItem.next {
	top: -50px;
}

header .brands {
	float: left;
}

header .other-brand {
	padding: 0 10px;
	position: relative;
	transition: 300ms;
	color: #fff;
	font-size: 3em;
}

header .other-brand.active {
	background: #fff;
	color: #000;
}

header .other-brand svg {
	height: 20px;
	position: relative;
	top: -2.5px;
}

header .top-right {
	position: absolute;
	top: 0;
	right: 0;
	text-align: right;
	padding-left: 20px;
	z-index: 10;
}

header .top-select {
	float: right;
	margin-left: 20px;
	cursor: pointer;
	position: relative;
}

header .top-select ul {
	height: 0;
	background: #fff;
	color: #000;
	position: absolute;
	top: 30px;
	right: -5px;
	padding: 0 20px 0 10px;
	border-radius: 3px;
	text-align: left;
	border: 1px solid #e6e6e6;
	-webkit-box-shadow: 0 1px 6px rgba(0,0,0,0.1);
	-moz-box-shadow: 0 1px 6px rgba(0,0,0,0.1);
	box-shadow: 0 1px 6px rgba(0,0,0,0.1);
	transition: 300ms opacity;
	opacity: 0;
	display: inline-block;
	overflow: hidden;
	z-index: 21;
}

header .top-select:hover ul {
	height: auto;
	opacity: 1;
}

header .top-select:hover ul li:hover {
	text-decoration: underline;
}

header .top-select > span {
	height: 36px;
	display: inline-block;
}

header .top-select > span > svg {
	margin-left: 5px;
	position: relative;
}

header .head {
	height: 72px;
}

header .head .container {
	height: auto;
}

header .head > .container {
	height: 100%;
	position: relative;
	z-index: 20;
	padding: 0;
}

header .head .logo {
	position: relative;
	left: unset;
	top: unset;
	margin-top: -5px;
	transform: translateX(0);
	float: left;
	z-index: 21;
	width: auto;
	font-size: 5.5em;
}

header .head .logo img {
	height: 44px;
}

header.basket .logo {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	font-size: 5.5em;
}

header.basket .secure-pay svg {
	margin: 0 10px;
	color: #aaa;
}

header .head .search {
	background: #fff;
	width: 100%;
	height: 72px;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	z-index: 22;
}

header .head .search.active {
	display: block;
}

header .head .search input {
	background: transparent;
	width: 50%;
	height: 40px;
	position: absolute;
	top: 16px;
	left: 50%;
	transform: translateX(-50%);
	padding: 0 55px 0 15px;
	color: #5e5e5e;
	border-bottom: 2px solid #d4d4d4!important;
	font-weight: 500;
	letter-spacing: 1px;
	text-align: center;
	transition: 300ms;
}

header .head .search svg {
	position: absolute;
	left: 75%;
	top: 12px;
	background: #d4d4d4;
	color: #4e4e4e;
	width: 44px;
	height: 44px;
	line-height: 44px;
	padding: 0 10px;
	z-index: 22;
	cursor: pointer;
}

header .head .search .close-search {
	width: 55px;
	background: transparent;
	left: 70%;
	line-height: 30px;
}

header .head .search input:focus + svg {
	color: #333;
}

header .search-complete {
	width: 100%;
	left: 0;
	top: 54px;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
	overflow: hidden;
	border: none;
	padding: 20px 25% 0;
}

header .search-complete .sugges {
	width: 35%;
	float: left;
	border: none;
}

header .search-complete .trends {
	width: 35%;
	float: left;
	border: none;
	padding-top: 20px;
}

header .search-complete .trends a {
	padding: 5px 10px;
	margin: 5px;
	background: #ededed;
	border-radius: 3px;
}

header .search-complete .products {
	width: 65%;
	float: right;
	display: flex;
	justify-content: start;
	align-items: start;
	flex-wrap: wrap;
}

header .search-complete .scl-product {
	width: 25%;
	padding: 0 2.5px;
	margin: 10px 0;
}

header .search-complete .scl-product span.title + span {
	padding-left: 0;
}

header .search-complete .scl-product:hover {
	color: initial;
}

header .search-complete .btn {
	background: transparent;
	color: var(--main-color);
	margin: 15px 0 0;
	border-top: 1px solid var(--main-lightgrey);
}

header .user-menu {
	position: relative;
	right: 0;
	top: 16.5px;
	z-index: 22;
}

header .user-menu .list-inline-item {
	margin-left: 20px;
	padding: 10px 0;
	position: relative;
}

header .user-menu .list-inline-item:first-child .a::before,
header .user-menu .list-inline-item:first-child a::before {
	display: none;
}

header .user-menu span.notif {
	min-width: 15px;
	height: 15px;
	line-height: 16px;
	background: var(--main-hover);
	border-radius: 50%;
	text-align: center;
	position: absolute;
	left: 15px;
	top: -6px;
	color: #fff;
}

header .user-menu .login-user:hover {
	height: 50px;
}

header .user-menu .login-user ul {
	transition: 300ms opacity ease-in;
	border-radius: 2px;
	height: 0;
	padding: 0;
	overflow: hidden;
	opacity: 0;
}

header .user-menu .login-user:hover ul
{
	top: 45px;
	opacity: 1;
	height: auto;
	padding-bottom: 20px;
	overflow: visible;
	border: 1px solid var(--main-grey);
	padding: 5px 15px 20px;
	transform: translateY(2px);
}

header .user-menu .login-user ul::after,
header .user-menu .login-user ul::before {
	content: '';
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-bottom-color: var(--main-grey);
	display: block;
	position: absolute;
	top: -20px;
	right: 40px;
	z-index: 30;
}

header .user-menu .login-user ul::after {
	content: '';
	border-bottom-color: white;
	top: -19.5px;
}

header .user-menu .login-user ul a {
	text-align: left;
	display: block;
}

nav .breadcrumb {
	padding: 12px 0;
	background: #fff;
}

nav .breadcrumb .breadcrumb-item a, nav .breadcrumb .breadcrumb-item span {
	color: #959595;
	text-transform: uppercase;
}

.breadcrumb-item+.breadcrumb-item::before {
	color: #959595;
}

#main-menu {
	font-weight: 500;
	width: 100%;
	border-bottom: 2px solid #ddd;
	margin-bottom: 10px;
}

header.fixed + #main-menu {
	position: fixed;
	top: 70px;
}

#main-menu .container,
.banner .container {
	padding: 0;
}

.banner span {
	display: inline-block;
}

.banner a {
	color: var(--main-grey);
}

.banner a:not(.underline) {
	width: 100%;
}

.banner img {
	width: 100%;
	text-align: center;
}

.banner:not(.not) a {
	display: inline-block;
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
	position: relative;
}

.banner .underline::before {
	content: '';
	height: 1.5px;
	width: 110%;
	display: block;
	position: absolute;
	bottom: -5px;
	left: -5%;
	background: var(--main-grey);
}

.banner .underline {
	position: relative;
}

.banner .bold {
	font-weight: 600;
}

.banner h2 {
	font-family: 'Montserrat', sans-serif;
	display: block;
	text-align: center;
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: .1em;
	font-weight: 600;
	font-size: 15px;
	position: relative;
	margin-bottom: 20px;
}

.banner h2 span {
	padding: 0 70px;
	background: white;
	position: relative;
	z-index: 10;
}

.banner h2::before {
	content: '';
	height: 1px;
	width: 100%;
	background: #eee;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 0;
}

#main-menu > .container > ul {
	height: auto;
	border: 0;
	margin: 0;
}

#main-menu > .container > ul > li > .main-title {
	height: 36px;
	line-height: 36px;
	padding: 0;
	color: var(--main-color);
	font-size: 15px;
	margin: -4px 15px -2px;
	text-transform: uppercase;
	letter-spacing: 1px;
	position: relative;
	transition: unset;
	text-align: center;
	white-space: nowrap;
	opacity: .9;
	display: inline-block;
	cursor: pointer;
}

#main-menu > .container > ul > li > .main-title.menu-red {
	background: #E81932;
	color: white;
	padding: 0 15px;
}

#main-menu > .container > ul > li > .main-title > span:nth-child(2) {
	opacity: 0
}

#main-menu > .container > ul > li > .main-title > span:nth-child(1) {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}


#main-menu > .container > ul > li > .main-title > span:nth-child(2),
#main-menu > .container > ul > li:hover > .main-title {
	font-weight: 600;
	border-bottom: 2px solid #000;
}

#main-menu > .container > ul > li > a::after {
	display: none;
}

#main-menu > .container > ul > li > .main-title svg {
	display: none;
}

#main-menu .menu-children {
	padding: 15px 0;
	min-width: 200px;
	text-align: left;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
	top: 32px;
	left: calc(-100px + 50%);
	padding: 8px 15px;
	border: 1px solid #ccc;
	border-width: 0 2px 2px;
}

#main-menu .menu-children.big {
	width: 100%;
	position: absolute;
	left: 0;
	padding: 7.5px 0;
	border: none;
}

header.index:not(.fixed) #main-menu .menu-children {
	top: 70px;
}

#main-menu .menu-children:not(.big) .container,
#main-menu .menu-children:not(.big) .container .col-3 {
	width: 100%;
	border: none;
	margin: 0;
}

#main-menu .menu-children h3 {
	text-transform: uppercase;
	font-weight: 600;
	margin-bottom: 20px;
	font-size: 14px;
	color: #000;
	position: relative;
}

#main-menu .menu-children::before {
	content: '';
	background: #ddd;
	width: 100%;
	height: 1.5px;
	position: absolute;
	top: 37px;
	left: 0;
}

#main-menu .menu-children ul li a,
#main-menu .menu-children ul li span {
	display: block;
	margin-bottom: 10px;
	font-weight: 400;
	color: #000;
}

#main-menu .menu-children ul li a:hover,
#main-menu .menu-children ul li span:hover {
	text-decoration: underline;
	font-weight: 500;
}

#main-menu .menu-children ul li a::before {
	display: none;
}

header.fixed .head {
	background: #fff;
	position: fixed;
	top: 0;
	z-index: 20;
}

header.fixed .top {
	display: none;
}

.modal-open .fixed .container {
	padding-right: 17px!important;
}

.modal-open .fixed + #main-menu .container {
	padding-right: 17px!important;
}

.close-page-click,
.close-page-hover {
	background: transparent;
	top: 0;
}

.message-slider {
	border-width: 1px 0;
	padding: 20px;
	margin-bottom: 10px;
	text-align: center;
	border-bottom: 1px solid #ddd;
}

.carousel {
	top: 0;
	margin-bottom: 40px;
}

#content:not(.user-page) .scl-content .scl-content-title {
	background: #fafafa;
	padding: 10px;
	margin-bottom: 20px;
	top: 0;
}

#content:not(.user-page) .scl-content .scl-content-title.title-red {
	padding: 10px 20px;
	background: #E81932
}

#content:not(.user-page) .scl-content .scl-content-title.title-red h1, #content:not(.user-page) .scl-content .scl-content-title.title-red small {
	color: white;
}

#content .scl-content .scl-content-title .scl-content-sort {
	width: 180px;
	padding: 10px;
	background: white;
	border: 1px solid var(--main-lightgrey);
	top: 0;
}

#content .scl-content .scl-content-title .scl-content-sort ul {
	width: 100%;
	top: 40px;
	border-color: var(--main-lightgrey);
}

#content .scl-content .scl-content-title .scl-content-sort ul li span {
	padding: 8px 10px;
	color: var(--main-grey);
	margin: 2px 0;
	transition: 200ms;
}

#content .scl-content .scl-content-title .scl-content-sort ul li span:hover,
#content .scl-content .scl-content-title .scl-content-sort ul li.active span {
	background: var(--main-lightgrey);
	color: var(--main-grey);
}

#content .scl-product-list .scl-product .image {
	border: 1px solid #e6e6e6;
}

#content .scl-product-list .scl-product .title {
	text-transform: uppercase;
}

#content .scl-product-list .scl-product .hover-menu.quick {
	text-align: center;
}

#content .scl-product-list .scl-product .images {
	position: relative;
	height: calc((100vw - 260px) * .75 * .32 * 1.25);
}

#detail nav .breadcrumb {
	margin: 2% 0;
}

#detail .icon-scl-heart,
#detail .icon-scl-heart-full {
	font-size: 2.2rem;
	position: relative;
	top: -8px;
	cursor: pointer;
}

#detail .detail-left .thump-image {
	width: 90%;
	margin: 0;
}

#detail .detail-left .thump-image img {
	width: 100%;
}

#detail .detail-left .thump-image li.active::before {
	background: transparent;
	border: 1px solid var(--main-grey);
}

#detail .detail-left .thump-slider .down,
#detail .detail-left .thump-slider .up {
	position: absolute;
	top: -32px;
	left: 0;
	z-index: 1;
	background: white;
	width: 90%;
	height: 30px;
	line-height: 30px;
	text-align: center;
	cursor: pointer;
	opacity: 0;
}

#detail .detail-left .thump-slider .down {
	top: auto;
	bottom: -33px;
}

#detail .detail-left .thump-slider:hover .down,
#detail .detail-left .thump-slider:hover .up {
	opacity: 1;
}

#detail .detail-right .info {
	margin: 7px 0;
}

#detail .detail-right .info strong {
	display: inline-block;
	font-weight: 500;
	margin-right: 5px;
}


#detail .detail-right .sizes {
	margin: 0 0 15px;
}

#detail .detail-right .description {
	text-align: justify;
}

#detail .detail-right .scl-select-option {
	margin: 15px 0 0;
}

#detail .detail-right .go-basket button.active {
	background: var(--main-color);
	border-color: var(--main-color);
	color: #fff;
}

#detail .detail-content {
	margin: 50px 0;
	width: 100%;
	color: #4e4e4e;
	float: left;
}

#detail .detail-content > ul {
	text-align: center;
	border-bottom: 1px solid #ddd;
}

#detail .detail-content > ul > li {
	padding: 20px 0;
	margin: 0 40px;
	cursor: pointer;
	font-weight: 500;
}

#detail .detail-content .prod-tabs {
	width: 80%;
	margin: 0 auto;
}

#detail .detail-content > ul > li.active {
	color: #000;
	border-bottom: 3px solid #000;
	margin-bottom: -1px;
}

#detail .detail-content .tab-content {
	display: none;
	opacity: 0;
	transition: 300ms;
	line-height: 2;
	margin-top: 50px;
}

#detail .detail-content .tab-content.active {
	display: block;
	opacity: 1;
}

#detail .detail-content .tab-content strong {
	display: inline-block;
	margin-bottom: 5px;
}

#detail .detail-content .tab-content ul li {
	margin-left: 10px;
}

#detail .detail-content .tab-content .col {
	margin-bottom: 40px;
}

#content .scl-sidebar h3.filter-title {
	padding-bottom: 8px;
	margin-top: 30px;
	font-weight: 600;
	cursor: pointer;
}

#content .scl-sidebar h3.filter-title svg {
	display: none;
}

#content .scl-sidebar h3.filter-title:not(.active) svg:nth-child(2) {
	display: inline-block;
}

#content .scl-sidebar h3.filter-title.active svg:nth-child(1) {
	display: inline-block;
}

#content .scl-content h1 {
	font-weight: 600;
	color: var(--main-grey);
	text-align: left;
	margin: 0;
	font-size: 20px;
	vertical-align: middle;
}

#content .scl-content h1 small {
	font-weight: 300;
	font-size: .7rem;
	color: #000;
	margin-left: 5px;
	display: inline-block;
	position: relative;
	top: -1px;
}

#content .scl-sidebar h2 {
	font-size: 18px;
	font-weight: 500;
	text-transform: uppercase;
	padding-top: 10px;
}

#content .scl-content .description {
	font-size: 1em;
	border: 1px solid #ddd;
	padding: 20px 20px 0;
	margin: 10px;
}

#content .scl-content h3 + p {
	padding-bottom: 25px;
}

#content .scl-sidebar h2 + h3.filter-title {
	margin-top: 20px;
}

#content .scl-sidebar ul.filter-list {
	padding-bottom: 20px;
	display: none;
}

input[type=checkbox] + span::after {
	color: var(--main-color);
}

#content .scl-sidebar ul.filter-list li:hover {
	border-color: #aaa;
}

#content .scl-sidebar ul.filter-list li.active {
	border-color: #666;
}

.scl-sidebar .user-panel li a {
	margin: 0;
	padding: 10px 0;
	border: none;
}

.scl-sidebar .user-panel li.active a {
	font-size: 15px;
}

.scl-sidebar .user-panel li:last-child a {
	border: none;
}

#content.user-page .container {
	width: 80%;
}


.scl-modal .modal-content h2 {
	padding: 20px 0;
	margin: 0;
	font-size: 1.5em;
}

.scl-modal .modal-content h2:after {
	display: none;
}

#help .card {
	border-color: #ddd;
}

footer {
	background: #f9f9f9;
	border-top: 1px solid #ddd;
	padding-bottom: 0;
}

footer .container {
	padding: 30px 50px 20px;
}

footer h2 {
	min-height: 25px;
	font-weight: 600;
	color: var(--main-color);
	text-align: left;
	margin: 0;
	padding: 10px 0;
	border-bottom: 1px solid var(--main-lightgrey);
}

footer ul {
	border: none;
	text-align: left;
}

footer,
footer a {
	color: #707070;
}

footer ul li {
	border: none;
	padding: 0 0 15px;
}

footer ul.help {
	margin-top: 50px;
	text-align: center;
}

footer ul.help li {
	font-size: 1.1em;
	text-align: center;
	padding: 5px 15px;
}

footer ul.help li {
	border-right: 1px solid #ddd;
}

footer ul.help li:last-child {
	border-right: none;
}

footer .social a {
	color: var(--main-grey);
	margin: 5px 25px 5px 0;
	font-size: 1.4rem;
}

footer .subscribe form {
	position: relative;
	border: 1px solid var(--main-lightgrey);
	margin-bottom: 1.2em!important
}

footer .subscribe .mb-3 {
	margin-bottom: 0!important
}

footer .subscribe input {
	max-width: 70%;
	border: none;
}

footer .subscribe label {
	position: relative;
	padding-left: 35px;
	margin: 0 5px;
	width: 100%;
	text-align: left;
	display: block;
	float: left;
}

footer .subscribe button {
	position: absolute;
	right: 0;
	top: 0;
	width: 30%;
	padding: 20px 0;
	font-weight: 600;
	background: transparent;
	color: var(--main-grey);
	border: none;
	border-left: 1px solid var(--main-lightgrey);
	cursor: pointer;
}

.go-head {
	width: 45px;
	height: 45px;
	line-height: 44px;
	padding-left: .5px;
	background: var(--main-color);
	color: var(--white);
	font-size: 2.6em;
	border-radius: 2px;
}

.go-head svg {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.swal2-modal {
	border: 1px solid #000!important;
}

.swal2-modal .swal2-title {
	font-size: 1.1em!important;
	color: #000!important;
	font-weight: 400;
}

.swal2-modal .swal2-styled.swal2-confirm {
	background: transparent!important;
	color: #000!important;
	border: 1px solid #000!important;
	padding: 1em 2em;
	font-size: 1em!important;
}

.swal2-modal .swal2-styled.swal2-deny {
	background: #000!important;
	padding: 1em 2em;
	font-size: 1em!important;
}

.swal2-modal .swal2-styled.swal2-confirm:hover,
.swal2-modal .swal2-styled.swal2-deny:hover {
	opacity: .9;
}

.swal2-modal .swal2-styled.swal2-cancel {
	background: #000!important;
	padding: 1em 2em;
	font-size: 1em!important;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
	color: #000!important;
	background-color: #000!important;
}

.swal2-icon.swal2-success .swal2-success-ring {
	border-color: rgba(0,0,0, .2)!important;
}

.react-slideshow-wrapper .images-wrap > div {
	position: relative;
	z-index: 1;
}

.swiper-container {
	padding-bottom: 50px!important;
}

.swiper-container-horizontal>.swiper-pagination-bullets {
	background: #ffffff;
	padding: 5px 15px;
	width: 100%!important;
	left: 50%!important;
	transform: translateX(-50%);
	border-radius: 10px;
}

.swiper-button-next, .swiper-button-prev {
	background: white!important;
	color: #3e3e3e!important;
	left: 0!important;
	width: 54px!important;
	height: 54px!important;
	opacity: 0!important;
	transition: 600ms;
}

.swiper-button-next {
	left: auto!important;
	right: 0!important;
}

.swiper-container:hover .swiper-button-next, .swiper-container:hover .swiper-button-prev {
	opacity: 1!important;
}

.swiper-container:hover .swiper-button-next.swiper-button-disabled, .swiper-container:hover .swiper-button-prev.swiper-button-disabled {
	opacity: 0!important;
}

.swiper-button-next:after, .swiper-button-prev:after {
	font-size: 25px!important;
}

.swiper-lazy-preloader {
	border-color: #aaa!important;
	border-top-color: #ddd!important;
	z-index: -1!important;
}

.homeFullSlider {
	height: calc(100vw / 2.83);
}

.loading {
	width: 100%;
	max-height: 150px;
	text-align: center;
}
.loading img {
	display: inline-block;
	max-height: 150px;
}

.loading.fix {
	width: 100%;
	height: 100%;
	max-height: 100%;
	text-align: center;
	position: absolute;
	background: #eee;
	top: 0;
	left: 0;
}

.loading.fix .loader {
	position: absolute;
	left: 50%;
	top: 50%;
	margin: 0;
	transform: translate(-50%, -50%)
}

.loader span,
.loader span:after {
	border-radius: 50%;
	width: 6em;
	height: 6em;
}

.loader span {
	margin: 60px auto;
	font-size: 5px;
	position: relative;
	border-top: .7em solid rgba(0, 0, 0, 0.2);
	border-right: .7em solid rgba(0, 0, 0, 0.2);
	border-bottom: .7em solid rgba(0, 0, 0, 0.2);
	border-left: .7em solid rgba(0, 0, 0, 0.5);
	-webkit-animation: load 1.1s infinite linear;
	animation: load 1.1s infinite linear;
	display: block;
}

@-webkit-keyframes load {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes load {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.pdf-schema {
	line-height: 1.5;
	text-align: left;
}

.pdf-schema h1 {
	text-align: center;
	background: rgba(23,70,200, .2);
	padding: 10px 0;
	margin: 0 0 -20px;
	color: #000;
	position: relative;
	top: -30px
}

.pdf-schema h2 {
	padding: 10px 0;
	font-size: 1.3em;
}

.pdf-schema h3 {
	color: #3a3a3a;
}

.pdf-schema p {
	line-height: 1.5rem;
	padding-left: 2em;
	margin: 2em 0;
}

.pdf-schema h2 + p, .pdf-schema h3 + p, .pdf-schema h1 + p {
	margin: 0 0 2em;
	padding-left: 0;
}

.pdf-schema li p {
	margin: .5em 0 2em;
	padding-left: 0;
}

.pdf-schema ul {
	padding-left: 3em;
	margin-top: 1em;
	margin-bottom: 2em;
}

.pdf-schema li {
	list-style: initial;
	line-height: 1.5;
	margin-bottom: .5em;
}

.pdf-schema table {
	margin-bottom: 2em;
}
.pdf-schema table tr, .pdf-schema table th, .pdf-schema table td {
	border: 1px solid #ccc;
	padding: 10px;
}
