.main {
	text-align: center;
	font-size: 18px;
	font-weight: 600;
}

@media screen and (max-width: 768px) {
	.main {
		font-size: 12px;
	}
}
