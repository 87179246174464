.groupSlider :global .swiper-container {
	padding-bottom: 50px;
	margin-bottom: 30px;
}

.title {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	letter-spacing: .2em;
	font-weight: 600;
	color: var(--main-grey);
	text-align: center;
}

.sliderProduct {
	width: 10%;
	padding: 5px;
}

.sliderProduct:hover img {
	transform: scale(1.02);
	transition: 700ms;
}

.sliderProduct span {
	display: block;
	margin-top: 10px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.sliderProduct span:nth-child(3) {
	font-weight: 600;
	font-size: 15px;
}

._1 {
	width: calc(50% - 5px);
}
