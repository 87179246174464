@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400;600;800&display=swap);
:root {
	--black: #000;
	--white: #fff;
	--theme-color: #ef6454;
	--main-color: #000;
	--main-grey: #5a5a5a;
	--main-lightgrey: #ddd;
	--main-blue: #7584d0;
	--main-green: #1cab81;
	--main-red: #ff4545;
	--main-active: #f63a6b;
	--main-hover: #1cab81;
	--form-border: #d0d0d0;
	--quick-silver: #000;
	--button-color: #000;
	--swiper-pagination-color: #7a7a7a
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;

}

html  {
	height: 100%;
	width: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
}

body {
	color: #5a5a5a;
	color: var(--main-grey);
	overflow: auto;
	scroll-behavior: smooth;
	position: relative;
}

a {
	display: inline-block;
	color: #000;
	color: var(--main-color);
	transition: color .2s ease-in;
	text-decoration: none;
	cursor: pointer;
}

ul {
	padding-left: 0;
	list-style-type: none;
}

img {
	max-width: 100%;
	height: auto;
	display: block;
}

iframe {
	max-width: 100%;
	width: 100%;
	height: calc(((100vw * 0.8) * 0.75) * 0.56);
}

pre {
	font-family: 'Poppins', sans-serif;
	white-space: pre-wrap;
}

.tabs,
.choices-select,
.input-number,
#detail .detail-right .sizes,
.header .head,
.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

input:-ms-input-placeholder {
	color: #5a5a5a;
	color: var(--main-grey);
}

input:-ms-input-placeholder,
input::-ms-input-placeholder,
input::-webkit-input-placeholder,
input::placeholder {
	color: #5a5a5a;
	color: var(--main-grey);
}

input {
	outline: none;
}

h1,
h2,
h3,
h4,
h5,
b,
p {
	font-weight: 400;
}

h1,
h2,
h3,
h4,
p {
	line-height: 1.6em;
}

strong {
	font-weight: 700;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number],
input[type=password],
input[type=tel],
input[type=text],
textarea {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: textfield;
}

::-moz-focus-inner {
	padding: 0;
	border: 0;
}

::-webkit-input-placeholder {
	color: #a6a6a8;
}

:-moz-placeholder {
	color: #a6a6a8;
	opacity: 1;
}

::-moz-placeholder {
	color: #a6a6a8;
	opacity: 1;
}

::-ms-input-placeholder {
	color: #a6a6a8;
}

.file-upload {
	border: 2px dashed #ccc;
	background: white;
	font-size: 1.4em;
	margin: 0 12px;
	width: calc(100% - 24px);
}
.file-upload span {
	padding: 2em;
	text-align: center;
	cursor: pointer;
	display: block;
}

.file-upload input[type=file]{
	opacity: 1;
	z-index: -1;
	position: absolute;
}

button,
button:focus {
	outline: none;
	border-radius: unset;
}

.base-light {
	font-weight: 400;
}

.base-regular {
	font-weight: 500;
}

.base-bold {
	font-weight: 700;
}

.modal-open {
	padding-right: 17px!important;
	overflow-x: hidden;
	overflow-y: hidden;
	max-height: 100vh;
}

.head {
	color: #000;
	color: var(--main-color);
}

.container {
	position: relative;
}

.pointer{
	cursor: pointer
}

.absolute {
	position: absolute;
}
h2.hr {
	color: #5a5a5a;
	color: var(--main-grey);
	position: relative;
	margin-bottom: 1em;
	text-align: center;
}

h2.hr::before {
	content: '';
	width: 108%;
	height: 2px;
	background: #ddd;
	background: var(--main-lightgrey);
	position: absolute;
	top: 50%;
	left: -4%;
	z-index: 1;
}

.grey {
	color: #5a5a5a;
	color: var(--main-grey);
}

.green {
	color: #1cab81;
	color: var(--main-green);
}

.blue {
	color: #7584d0;
	color: var(--main-blue);
}

.red {
	color: #ff4545;
	color: var(--main-red);
}

.orange {
	color: #ff6400!important;
}

.danger {
	color: #ff4545;
	color: var(--main-red);
}

h2.hr span {
	position: relative;
	background: white;
	padding: 0 95px;
	letter-spacing: 1em;
	z-index: 2;
}

h3.hr {
	color: #5a5a5a;
	color: var(--main-grey);
	position: relative;
	text-align: center;
}

h3.hr::before {
	content: '';
	width: 100%;
	height: 1px;
	background: #ddd;
	background: var(--main-lightgrey);
	position: absolute;
	top: 55%;
	left: 0;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	z-index: 1;
}

h3.hr span {
	position: relative;
	background: white;
	padding: 0 30px;
	z-index: 2;
	letter-spacing: .03em;
}

span.hr {
	clear: both;
	height: 1px;
	background: #ddd;
	background: var(--main-lightgrey);
	display: block;
	margin: 3% auto;
}

.r45 {
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}

.r90 {
	-webkit-transform: rotate(90deg);
	        transform: rotate(90deg);
}

.r180 {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
}

.r270 {
	-webkit-transform: rotate(270deg);
	        transform: rotate(270deg);
}

.r180,
.r270,
.r45,
.r90 {
	display: inline-block;
}
/* FORM */
form {
	letter-spacing: .03em;
}

form .form-label {
	color: #5a5a5a;
	color: var(--main-grey);
	line-height: 1.6em;
	margin: 7px 0;
	position: relative;
	display: block;
	text-align: left;
	text-transform: uppercase;
}

form .form-control {
	padding: 0 20px;
	width: 100%;
	box-sizing: border-box;
	height: 50px;
	line-height: 1.4em;
	border: 1px solid #ddd;
	border: 1px solid var(--main-lightgrey);
	border-radius: 0;
	color: #5a5a5a;
	color: var(--main-grey);
}

form textarea.form-control {
	padding: 15px 20px;
	height: auto;
}

form .form-control:focus {
	box-shadow: unset;
	border-color: #ddd;
	border-color: var(--main-lightgrey);
}

form .form-control.red {
	color: #ff4545;
	color: var(--main-red);
	border-color: currentColor;
}

form .form-control.red::-webkit-input-placeholder {
	color: #ff4545;
	color: var(--main-red);
	opacity: .9
}

form .form-control.red:-ms-input-placeholder {
	color: #ff4545;
	color: var(--main-red);
	opacity: .9
}

form .form-control.red::placeholder {
	color: #ff4545;
	color: var(--main-red);
	opacity: .9
}

form .error {
	width: 100%!important;
	text-align: left;
	margin-bottom: 10px;
	display: block;
}

.form-btn {
	text-align: center;
	margin: 40px 0!important;
}

.btn {
	background: #000;
	background: var(--button-color);
	border-radius: 0;
	border: 1px solid #000;
	border: 1px solid var(--button-color);
	color: #fff;
	color: var(--white);
	outline: 0;
	box-shadow: none!important;
	transition: 300ms;
	padding: 10px 20px;
}

.btn:hover,
button:hover {
	background-color: transparent;
	color: #000;
}

.btn:active {
	background: #fff;
	background: var(--white);
	border: 1px solid #f63a6b;
	border: 1px solid var(--main-active);
	color: #f63a6b;
	color: var(--main-active);
}

.btn-reverse {
	background: transparent;
	color: #000;
	color: var(--main-color);
}

.btn-small {
	padding: 5px 8px;
	font-size: .9em;
}

.form-check {
	position: relative;
	font-size: 14px;
	padding-left: 30px;
}
.form-check-label {
	cursor: pointer;
	line-height: 1.5;
}
input[type=checkbox] {
	display: none!important;
}

input[type=checkbox] + span {
	width: 16px;
	height: 16px;
	display: block;
	background-color: #fff;
	background-color: var(--white);
	cursor: pointer;
	border: 1px solid #5a5a5a;
	border: 1px solid var(--main-grey);
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-60%);
	        transform: translateY(-60%);
}

input[type=checkbox] + span svg {
	opacity: 0;
	line-height: 1;
	color: currentColor;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	transition: opacity .3s cubic-bezier(0.4, 0, 1, 1);
}

input[type=checkbox]:checked + span {
	border: 1px solid #5a5a5a;
	border: 1px solid var(--main-grey);
}

input[type=checkbox]:checked + span svg {
	opacity: 1;
}

.modal-backdrop.show {
	opacity: .3!important;
}

.scl-select {
	width: 100%;
	height: 50px;
	line-height: 50px;
	border: 1px solid #d0d0d0;
	border: 1px solid var(--form-border);
	position: relative;
	font-size: 16px;
}

.scl-select span:nth-child(1) {
	white-space: nowrap;
}

.scl-select span:nth-child(2) {
	float: right;
	padding-right: 25px;
	color: #1cab81;
	color: var(--main-hover);
}

.scl-select .selected-item {
	padding: 0 10px;
	cursor: pointer;
}

.scl-select .selected-item.only-text {
	height: 48px;
	line-height: 48px;
}

.scl-select .selected-item.active + .scl-select-options {
	display: block;
}

.scl-select .selected-item span:nth-child(2):after {
	content: '';
	border: 5px solid transparent;
	border-top-color: black;
	position: absolute;
	top: 50%;
	right: 10px;
}

.scl-select-options {
	background: white;
	width: calc(100% + 2px);
	max-height: 300px;
	border: 1px solid #ddd;
	border: 1px solid var(--main-lightgrey);
	box-shadow: 0 1px 0 0 rgba(0,0,0,.5);
	position: absolute;
	top: 48px;
	left: -1px;
	z-index: 10;
	overflow: auto;
	display: none;
}

.scl-select-options .scl-select-item {
	padding: 0 10px;
	cursor: pointer;
}

.scl-select-options.only-text .scl-select-item {
	height: 50px;
	line-height: 50px;
}

.scl-select-options .scl-select-item.active span:nth-child(2) {
	color: white;
}

.scl-select-options .scl-select-item.active {
	background: #5a5a5a;
	background: var(--main-grey);
	color: white;
}

.scl-select-options .scl-select-item.not span {
	color: #ddd;
	color: var(--main-lightgrey);
}

.scl-select .danger span:nth-child(2) {
	color: red;
}

.input-number {
	width: 70px;
	margin-right: 2%;
	position: relative;
}

.input-number svg {
	margin-right: 0!important;
}

input[type=number] {
	width: 100%;
	height: 46px;
	padding: 0 20px;
	text-align: center;
	border: 1px solid #d0d0d0;
	border: 1px solid var(--form-border);
}

input[type=number] + span,
input[type=number] + span + span {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	line-height: 46px;
	border: 1px solid #d0d0d0;
	border: 1px solid var(--form-border);
	color: #000;
	color: var(--quick-silver);
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	transition: 300ms;
}

input[type=number] + span + span {
	right: auto;
	left: 0
}

input[type=number] + span + span:active,
input[type=number] + span:active {
	color: #f63a6b;
	color: var(--main-active);
}

.input {
	position: relative;
}

.show-password {
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	cursor: pointer;
	padding: 15px;
	z-index: 25;
}

.input-button {
    position: absolute;
    left: 82%;
    top: 0;
    bottom: 0;
    height: 40px;
    margin: auto;
    font-size: 30px;
    cursor: pointer;
    color: #ccc;
		z-index: 5;
}

/** FORM **/
/* MODAL */
.successMessage, .errorMessage {
	padding: 20px 0 20px 70px;
	background: #1cab81;
	background: var(--main-green);
	color: #fff;
	position: relative;
	line-height: 1.5;
	text-align: left;
}

.errorMessage {
	background: #ff4545;
	background: var(--main-red);
	text-align: left;
}

.paymentError {
	font-size: 1.6em;
	border-radius: 3px;
}

.successMessage svg, .errorMessage svg {
	position: absolute;
	top: 50%;
	left: 17px;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	font-size: 2.2rem;
	color: white;
}

.modal-backdrop.show {
	opacity: .8;
}

.scl-modal {
	width: 50%;
	max-height: 100vh;
	overflow-x: unset;
}

.scl-modal .modal-content {
	padding: 40px;
	padding-bottom: 20px;
	background-color: #fff;
	background-color: var(--white);
	border: 3px solid #1cab81;
	border: 3px solid var(--main-hover);
	box-sizing: border-box;
	border-radius: 0;
	position: absolute;
	overflow: unset;
	top: 0;
	left: 0;
	transition: margin 500ms;

}

.scl-modal .scl-modal-close {
	width: 44px;
	height: 44px;
	line-height: 1.1em;
	position: absolute;
	right: -20px;
	top: -20px;
	font-size: 38px;
	text-align: center;
	color: #000;
	color: var(--main-color);
	background-color: #fff;
	background-color: var(--white);
	border: 3px solid #1cab81;
	border: 3px solid var(--main-hover);
	border-radius: 50%;
	box-sizing: border-box;
	font-family: Arial, Baskerville, monospace;
	transition: 0s;
	cursor: pointer;
	z-index: 10;
}

.scl-modal .modal-content .modal-body {
	padding: 0;
	overflow: visible;
}

.scl-modal a {
	transition: 500ms color;
}

.scl-modal .modal-content .scl-modal-close:hover {
	color: #fff;
	color: var(--white);
	background-color: #f63a6b;
	background-color: var(--main-active);
}

.nav-tabs .nav-item {
	width: 50%;
}

.nav-tabs .nav-link {
	border: none;
	text-align: center;
	padding-bottom: 15px;
	color: #000;
	color: var(--quick-silver);
}

#basket .nav-tabs .nav-link {
	font-size: 1.15em;
}

.nav-tabs .nav-link.active {
	border-bottom: 2px solid #000;
	border-bottom: 2px solid var(--main-color);
	color: #000;
	color: var(--main-color);
}

.nav-tabs .nav-link:hover {
	color: #000;
	color: var(--main-color);
}

.scl-modal .modal-content h2 {
	text-align: center;
	width: 100%;
	box-sizing: border-box;
	color: #000;
	color: var(--quick-silver);
	padding: 3.7% 0;
	position: relative;
	margin-bottom: 5%;
	line-height: 1.6em;
}

.scl-modal .fb-btn {
	text-align: center;
}

.scl-modal .fb-btn button {
	display: inline-block;
	box-sizing: border-box;
	width: 313px;
	max-width: 100%;
	height: 57px;
	color: #fff;
	color: var(--white);
	background: url("/img/facebook-login.png") no-repeat;
	outline: none;
	border: none;
	box-shadow: none;
}

.scl-modal .fb-btn button:hover {
	background-position: left -57px;
}
/* LOGIN MODAL */
#loginModal  {
	z-index: 1051;
}

#loginModal .scl-modal {
	width: 500px;
}
/** LOGIN MODAL **/
/* QUİCK MODAL */

.quickModal {
	overflow: hidden;
}

.quickModal .scl-modal {
	width: 980px;
	max-width: 980px;
}

.quickModal .scl-modal .modal-content {
	padding: 1%;
	height: 100%;
	max-height: 525px;
}

.quickModal .scl-modal .modal-content .modal-body {
	overflow-x: hidden;
	overflow-y: auto;
}

.quickModal .container {
	width: 100%;
}

.quickModal #detail {
	margin-top: 15px;
}

.quickModal #detail .detail-left .thump-slider .thump-image li {
	height: calc(980px * .48 * .9 * .2 * 1.25);
}

.quickModal #detail .detail-left .big-image {
	height: calc((980px * 0.50 * 0.80) * 1.25);
}

#detail .go-detail {
	margin-top: 10px;
	float: right;
	text-transform: uppercase;
	letter-spacing: .5px;
	font-weight: 500;
	position: relative;
}

#detail .go-detail span {
	transition: 500ms;
	margin-right: 10px;
}

#detail .go-detail:hover span {
	margin-left: 10px;
	margin-right: 0;
}

.quickModal #detail .detail-right {
	padding-right: 20px;
	width: 50%;
	padding-bottom: 0;
	height: 470px;
	overflow-y: scroll;
}

.quickModal #detail .detail-right .social {
	display: none;
}

.quickModal #detail .detail-content .prod-tabs {
	width: 100%;
}

.quickModal #detail .detail-content .tab-content{
	margin-top: 0;
}

.quickModal .detail-right .thump-slider .up, .quickModal .detail-right .thump-slider .down {
	display: none;
}
.quickModal .detail-right .services ul li:first-child {
	width: 50%!important;
}
/** QUİCK MODAL **/

.word-show  {
	z-index: 1052;
}

/* COMBİNE MODAL */

.combimeModal .scl-modal .modal-content {
	overflow: hidden;
}

.combimeModal .scl-modal .modal-content {
	padding: 0;
	height: 100%;
	max-height: 675px;
}

.combimeModal .scl-modal {
	width: 1080px;
	max-width: 1080px;
}

.combimeModal #detail {
	width: 950px;
	float: right;
	margin-top: 15px;
}

.combimeModal .scl-modal .scl-modal-close {
	top: 0;
	right: 0;
}

.combimeModal .combine-products {
	width: 125px;
	float: left;
	padding: 45px 5px 0 5px;
	overflow: auto;
	height: 650px;
	background: #f9f9f9;
}

.combimeModal .combine-products span.active {
	border: 1px solid #000;
}

.combimeModal .combine-products::after {
	content: 'ÜRÜNLERİ KEŞFET';
	background: #000;
	color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	width: 125px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	letter-spacing: 2px;
	font-size: 10px;
	white-space: nowrap;
}

.combimeModal .combine-products span {
	margin-bottom: 5px;
	cursor: pointer;
	display: block;
}

.combimeModal .scl-modal .modal-content .modal-body {
	overflow-x: hidden;
	overflow-y: scroll;
}

.combimeModal .container {
	width: 100%;
}

.combimeModal .detail-right {
	padding-right: 20px;
	max-height: 650px;
	overflow: scroll;
	padding-bottom: 150px
}

.combimeModal #detail .detail-left .big-image {
	width: 100%;
	height: calc((1080px - 130px) * .48 * 1.25);
}

#detail .detail-left .big-image img {
	width: 100%!important;
}

.combimeModal #detail .detail-left .thump-slider {
	text-align: center;
	width: 100%;
	padding-top: 10px;
}

.combimeModal #detail .detail-left .thump-image li img {
	display: none;
}

.combimeModal #detail .detail-left .thump-slider .thump-image li {
	width: 12px;
	height: 12px;
	border: 1px solid #000;
	border-radius: 50%;
	overflow: hidden;
	display: inline-block;
}
.combimeModal #detail .detail-left .thump-slider .thump-image img { display: none}

.combimeModal #detail .detail-left .thump-image li.active {
	background: #000;
}

.combimeModal #detail .detail-left .thump-image li.active::before {
	display: none;
}

.combimeModal #detail .detail-left .thump-slider span.up, .combimeModal #detail .detail-left .thump-slider span.down {
	position: relative!important;
	display: inline-block;
	width: auto!important;
}
/** COMBİNE MODAL **/
/* QUESTİON MODAL */
#questionModal .scl-modal .modal-content {
	padding-top: 2%;
}
/** QUİCK MODAL **/
/* PAYMENT MODAL */
#paymentOptionsModal .scl-modal {
	width: 65%;
	max-width: 65%;
}

#paymentOptionsModal .bank {
	border: 2px solid #ddd;
	padding: 10px 20px;
	text-align: center;
	cursor: pointer;
}

#paymentOptionsModal .bank.active {
	border-color: #5e5e5e;
}

#paymentOptionsModal .bank img {
	display: inline-block;
}

#paymentOptionsModal .scl-modal .modal-content {
	padding-top: 2%;
}

#sizeChartModal .scl-modal {
	width: 60%;
	max-width: 65%;
}

#sizeChartModal h3 span.green {
	font-weight: 600;
}

#sizeChartModal .product-chart {
	font-size: 1.2rem;
	text-transform: uppercase;
	color: #000;
}

#sizeChartModal .product-chart li {
	margin: 0 1rem;
	opacity: .8;
	cursor: pointer;
}

#sizeChartModal .product-chart li.active {
	opacity: 1;
	font-weight: 500;
}

#paymentOptionsModal .table tr.active td {
	padding: 5px 15px;
}

.table {
	margin-top: 20px;
}

.table, .table td {
	border:1px solid #ddd;
	text-align: center;
	vertical-align: middle;
}

.table tr:nth-child(2n+1) {
	background: #fafafa;
}

.table tr.active,.table td.active {
	background: #eee;
}

.table tr.hover, .table td.hover {
	background: #ccc;
}

.table .active .hover, .table .hover .active {
	background: #2e2e2e;
	color: #fff;
}

.table tr td {
	padding: 15px 5px;
}

.table.small tr td {
	padding: 10px 5px;
}

/** PAYMENT MODAL **/
/* BASKET MODAL */

#basketModal {
	display: none;
}

#basketModal .content {
	position: fixed;
	width: 40%;
	height: 100%;
	top: 0;
	right: -100%;
	background: #fff;
	background: var(--white);
	border-left: 4px solid #1cab81;
	border-left: 4px solid var(--main-hover);
	z-index: 27;
	overflow: scroll;
}

#basketModal .basket-bg {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	background: rgba(0,0,0,.2);
	z-index: 26;
	opacity: 0;
}

#basketModal .basket-modal-close {
	width: 44px;
	height: 44px;
	line-height: 1.1em;
	position: absolute;
	top: 10px;
	right: 15px;
	font-size: 38px;
	text-align: center;
	color: #000;
	color: var(--main-color);
	background-color: #fff;
	background-color: var(--white);
	border: 3px solid #1cab81;
	border: 3px solid var(--main-hover);
	border-radius: 50%;
	box-sizing: border-box;
	font-family: Arial,Baskerville,monospace;
	transition: 0s;
	cursor: pointer;
	z-index: 10;
}

#basketModal .basket-empty {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	font-weight: 100;
	text-align: center;
	padding-top: 30px;
}

#basketModal .basket-empty svg {
	font-size: 5em;
}

#basketModal .basket-empty span {
	margin: 30px 0 0;
	display: block;
}

#basketModal .basket-empty .btn {
	padding: 15px 55px;
}

#basketModal .basket-empty .btn {
	padding: 15px 55px;
}

#basketModal .basket-info,
#basketModal .basket-product-list {
	width: 90%;
	padding: 60px 0 0 60px;
}

#basketModal .basket-info {
	width: 80%;
	padding: 20px 60px;
	margin: 10% 10% 0;
	/*border: 1px solid var(--main-lightgrey);*/
	top: -30px;
	position: relative;
}

#basketModal .basket-info h2 {
	border-bottom: 2px solid #000;
	border-bottom: 2px solid var(--main-color);
	padding: 0 0 15px;
	text-align: center;
}

#basketModal .basket-info .row:not(:first-child) {
	padding: 10px 0;
	border-bottom: 1px solid #ddd;
	border-bottom: 1px solid var(--main-lightgrey);
}

#basketModal .basket-info .row:not(:first-child) span {
	width: 60%;
	float: left;
	padding: 0;
}

#basketModal .basket-info .row:not(:first-child) span:nth-child(2) {
	text-align: right;
	width: 40%;
}

#basketModal .basket-info .row:not(:first-child) span small {
	display: block;
	margin-top: 5px;
	color: #5a5a5a;
	color: var(--main-grey);
}

#basketModal .row .col-5 a,
#basketModal .row .col-5 span {
	display: block;
	margin-bottom: 8px;
}

#basketModal .row .col-2.p-4 {
	padding: 5px 15px!important;
}

#basketModal .row.dashedTB .col-1 {
	text-align: right;
	padding-top: 25px;
}

#basketModal .row.dashedTB .col-6 span {
	padding-top: 7px;
	display: block;
}
/** BASKET MODAL **/
/** BASKET HOVER **/

#basketHover {
	background: #fff;
	background: var(--white);
	position: absolute;
	top: 47px;
	right: -60px;
	border-radius: 2px;
	transition: 300ms opacity ease-in;
	opacity: 0;
	z-index: 27;
	height: 0;
	overflow: hidden;
	opacity: 0;
}

header .user-menu .open-basket:hover {
	height: 50px;
}

header .user-menu .open-basket:hover #basketHover {
	width: 450px;
	height: auto;
	border: 1px solid #5a5a5a;
	border: 1px solid var(--main-grey);
	overflow: visible;
	opacity: 1;
	min-height: 70px;
}

#basketHover::before, #basketHover::after {
	content: '';
	width: 8px;
	height: 8px;
	border: 8px solid #5a5a5a;
	border: 8px solid var(--main-grey);
	display: block;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	position: absolute;
	top: -8px;
	right: 80px;
	z-index: 30;
}

#basketHover::after {
	content: '';
	width: 8px;
	height: 8px;
	border: 8px solid white;
	top: -7px;
}

#basketHover .content > .row {
	margin: 0;
}

.basket-hover-bg {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	z-index: 20;
}

#basketHover .basket-modal-close {
	width: 44px;
	height: 44px;
	line-height: 1.1em;
	position: absolute;
	top: 10px;
	right: 15px;
	font-size: 38px;
	text-align: center;
	color: #000;
	color: var(--main-color);
	background-color: #fff;
	background-color: var(--white);
	border: 3px solid #1cab81;
	border: 3px solid var(--main-hover);
	border-radius: 50%;
	box-sizing: border-box;
	font-family: Arial,Baskerville,monospace;
	transition: 0s;
	cursor: pointer;
	z-index: 10;
}

#basketHover .basket-empty {
	position: relative;
	top: 0;
	left: 0;
	-webkit-transform: none;
	        transform: none;
	padding: 20px 0;
	text-align: center;
}

#basketHover .basket-empty svg {
	font-size: 5em;
}

#basketHover .basket-empty span {
	margin: 30px 0;
	display: block;
}

#basketHover .basket-empty .btn {
	padding: 15px 55px;
}

#basketHover .basket-empty .btn {
	padding: 15px 55px;
}

#basketHover .basket-info,
#basketHover .basket-product-list {
	width: 100%;
	padding: 10px 20px 0 10px;
}

#basketHover .basket-product-list .dashedTB .col-3 {
	padding: 0!important;
}

#basketHover  .basket-info {
	width: 90%;
	padding: 0 40px;
	margin: 2em 5% 0;
	top: -20px;
	position: relative;
}

#basketHover .row:nth-child(2) .basket-info {
	margin: 0px 5%;
	top: -10px;
}

#basketHover .basket-info h2 {
	border-bottom: 2px solid #000;
	border-bottom: 2px solid var(--main-color);
	padding: 0 0 15px;
	text-align: center;
}

#basketHover .basket-info .row:not(:first-child) {
	padding: 10px 0;
	border-bottom: 1px solid #ddd;
	border-bottom: 1px solid var(--main-lightgrey);
}

#basketHover .basket-info .row:not(:first-child) span {
	width: 60%;
	float: left;
	padding: 0;
}

#basketHover .basket-info .row:not(:first-child) span:nth-child(2) {
	text-align: right;
	width: 40%;
}

#basketHover .basket-info .row:not(:first-child) span small {
	display: block;
	margin-top: 5px;
	color: #5a5a5a;
	color: var(--main-grey);
}

#basketHover .row .col-2.p-4 {
	padding: 5px 15px!important;
}

#basketHover .row.dashedTB .col-6 span {
	padding-top: 7px;
	display: block;
}

#basketHover .row.dashedTB .col-6 span.price span.old {
	display: none;
}
/** BASKET HOVER **/
/* WELCOME MODAL */
#welcomeModal .modal-dialog {
	width: 500px;
}

#welcomeModal .modal-content {
	margin-top: 50%;
}

#welcomeModal #country img,
#welcomeModal #language img {
	float: left;
	margin-right: 10px;
	max-width: 40px;
}

#welcomeModal #country .selected-item span,
#welcomeModal #language .selected-item span {
	align-items: center;
	display: flex;
}
/** WELCOME MODAL **/
/** MODAL **/
/* HEADER */

header {
	position: relative;
	top: 5px;
}

header .head {
	width: 100%;
}

header .head .container {
	height: 75px;
}

header .user-menu {
	float: right;
}

header .user-menu li > a,
header .user-menu li > .a {
	padding: 0;
	cursor: pointer;
}

header .user-menu .open-basket:hover {
	z-index: 25;
}

header .user-menu > ul > li:not(:first-child):before {
	content: '';
	height: 12px;
	width: 1px;
	background: #b9b9b9;
	position: absolute;
	top: 13px;
	left: -10px;
}

header .user-menu > a:after {
	content: '';
	vertical-align: middle;
	top: 50%;
	right: 0;
	width: 1px;
	height: 18px;
	background-color: #ddd;
	background-color: var(--main-lightgrey);
	position: absolute;
	display: inline-block;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}

header .user-menu .list-inline-item {
	margin-right: 0;
}

header .user-menu .diff {
	padding: 0;
}

header .user-menu .diff::after {
	display: none;
}

header .user-menu .diff + span {
	margin: 0 2px;
}

header .user-menu .basket-button {
	position: relative;
}

header .user-menu .basket-button .notif {
	width: 15px;
	height: 15px;
	line-height: 15px;
	display: inline-block;
	text-align: center;
	margin-left: 5px;
	background: #1cab81;
	background: var(--main-green);
	border-radius: 50%;
	color: #fff;
	position: relative;
	top: 1px;
}

header .user-menu .diff:last-child {
	margin-right: 20px;
}

header .user-menu .login-user {
	position: relative;
}

header .user-menu .login-user a {
	position: relative;
}

header .user-menu .login-user a:active,
header .user-menu .login-user a:hover {
	color: #000;
	color: var(--main-color);
}

header .user-menu .login-user a span::after {
	font-family: icomoon!important;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	position: absolute;
	top: 55%;
	left: calc(100% + 10px);
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	content: "\e903";
	color: #000;
	color: var(--black);
	width: auto;
	height: auto;
	background-color: transparent;
}

header .user-menu .login-user ul {
	background-color: #fff;
	background-color: var(--white);
	min-width: 170px;
	position: absolute;
	z-index: 21;
}

header .user-menu .login-user ul span.hr {
	margin: 10px auto;
}

header .user-menu .login-user ul > li {
	cursor: pointer;
}

header .user-menu .login-user ul a {
	transition: color .2s ease-in;
	color: #000;
	color: var(--main-color);
	padding: 10px 0;
}

header .user-menu .login-user ul li:hover {
	font-weight: 500;
}

header .modal-search {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 140px;
	background: white;
	z-index: 10;
	opacity: 0;
	transition: .6s opacity ease;
	display: none;
	z-index: 25;
}

header .modal-search input:-ms-input-placeholder {
	color: #5a5a5a;
	color: var(--main-grey);
}

header .modal-search input:-ms-input-placeholder,
header .modal-search input::-ms-input-placeholder,
header .modal-search input::-webkit-input-placeholder,
header .modal-search input::placeholder {
	color: #5a5a5a;
	color: var(--main-grey);
}

header .modal-search input {
	position: absolute;
	top: 35px;
	left: calc(50% - 225px);
	width: 450px;
	padding: 10px 30px 10px 0;
	font-weight: 100;
	border-bottom: 2px solid #000!important;
	border-bottom: 2px solid var(--quick-silver)!important;
	color: #5a5a5a;
	color: var(--main-grey);
	outline: none;
}

header .modal-search i.icon-search {
	position: absolute;
	top: 48px;
	left: calc(50% + 200px);
	font-weight: 100;
	color: #5a5a5a;
	color: var(--main-grey);
}

header .modal-search .close-search {
	position: absolute;
	top: 15px;
	right: 30px;
	display: inline-block;
	vertical-align: middle;
}

header .modal-search .close-search svg {
	display: inline-block;
	vertical-align: middle;
	padding-left: 10px;
}

header .search-complete {
	width: 330px;
	background: white;
	position: absolute;
	top: 50px;
	left: -30px;
	padding-top: 10px;
	border-style: solid;
	border-width: 0 1px 5px 1px;
	border-color: #ddd;
}

header .search-complete .result {
	padding: 0 0 10px 10px;
	border-bottom: 1px solid #ddd;
	display: block;
}

header .search-complete .no-result {
	text-align: center;
	display: block;
	padding: 10px 10px 20px 10px;
}

header .search-complete .btn {
	width: calc(100% + 1px);
	padding: 7px;
	margin: 0 0 -1px -1px;
	border: none;
}

header .search-complete .sugges {
	margin-top: 10px;
	border-top: 1px solid #ddd;
}
header .search-complete .sugges span {
	display: block;
	margin: 10px
}

header .search-complete .sugges a {
	text-decoration: underline;
}

header .search-complete .scl-product:hover {
	background: rgba(0,0,0,.02);
}

header .search-complete .scl-product span:not(.images),
header .search-complete .scl-product .title {
	display: block;
}

header .search-complete .scl-product span.title {
	font-size: .9em;
	line-height: 1.3em;
	overflow: hidden;
}
header .search-complete .scl-product span, header .search-complete .scl-product div {
	margin-top: 5px;
	float: left;
}

header .search-complete .scl-product .old {
	position: relative;
	font-size: .9em;
	padding: 0 2px!important;
	display: block;
}

header .search-complete .scl-product .old:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 0px;
	width: 100%;
	height: 1px;
	background: #00000080;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	display: inline-block;
}

header .search-complete .scl-product .images, header .search-complete img {
	width: 100%;
	float: left;
	position: relative;
	height: calc((100vw - 260px) * .5 * .65 * .25 * 1.25)
}

header .search-complete .loading span {
	margin: 0;
}

header .search-complete .scl-product .hover-menu, header .search-complete .scl-product .color-palette {
	display: none;
}
/** HEADER **/
/* MENU */

#main-menu {
	background: #fff;
	background: var(--white);
	position: relative;
	z-index: 20;
	min-height: 32px;
}

#main-menu > .container {
	position: static;
	position: initial;
}

#main-menu > .container > ul {
	border: 1px solid #e4e4e4;
	border-width: 1px 0;
	text-align: center;
	height: 50px;
}

#main-menu > .container > ul > li {
	margin-right: 0;
}

#main-menu > .container > ul > li.two .menu-children {
	width: 460px;
	left: calc(-230px + 50%);
}

#main-menu > .container > ul > li.little {
	position: relative;
}

#main-menu > .container > ul > li.notTitle .menu-children {
	padding-top: 15px;
}

#main-menu > .container > ul > li.notTitle .menu-children::before {
	display: none;
}

#main-menu > .container > ul > li > a {
	padding: 0 33px;
	height: 50px;
	line-height: 50px;
	position: relative;
	transition: .3s;
}

#main-menu > .container > ul > li > a:hover {
	color: #000;
	color: var(--main-color);
}

#main-menu > .container > ul > li > a::after {
	content: '';
	width: 0;
	height: 3px;
	background: #000;
	background: var(--black);
	position: absolute;
	left: 50%;
	bottom: -1px;
	display: inline-block;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	transition: width .3s ease,opacity .3s ease;
	overflow: hidden;
}

#main-menu > .container > ul > li > a:hover::after {
	width: 30%;
}

.menu-children {
	width: 100%;
	background: white;
	position: absolute;
	top: 50px;
	left: 0;
	width: 100%;
	padding: 45px 7px 35px;
	box-shadow: 0 1px 0 0 rgba(0,0,0,.07);
	text-align: center;
	z-index: 10;
	opacity: 0;
	display: none;
}

.close-page-hover, .close-page-click {
	background: #00000030;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 65px;
	left: 0;
	z-index: 1;
	display: none;
}
.close-page-click {
	z-index: 20;
}
.menu-children.marka .col {
	padding: 0 6px;
}

.menu-children.marka img {
	width: 100%;
	margin-bottom: 8px;
}

.menu-children.marka a {
	color: #5a5a5a;
	color: var(--main-grey);
}

.menu-children.marka a:hover {
	color: #1cab81;
	color: var(--main-hover);
}

.menu-children.reyon {
	text-align: left;
	padding: 45px 0 35px;
}

.menu-children.reyon img {
	width: 100%;
}

.menu-children.reyon > .container > .row > .col-3 {
	width: 19.2%;
	padding-right: 15px;
	margin: 15px 0;
	border-right: 1px solid #ddd;
	border-right: 1px solid var(--main-lightgrey);
	letter-spacing: .2px;
}

.menu-children.reyon > .container > .row > .col-3 .high-children {
	width: 77.8%;
	left: 21.2%;
	position: absolute;
	top: 0;
	display: none;
}

.menu-children.reyon > .container > .row > .col-3 .high-children.active {
	display: block;
}

.menu-children.reyon > .container > .row > .col-3 .high-children > .col-4 {
	padding-left: 7.4%;
	padding-right: 0;
	width: 31.4%;
}

.menu-children.reyon > .container > .row > .col-3 .high-children > .col-4:last-child {
	padding: 0;
	margin-left: 2.7%;
	width: 33%;
}

.menu-children.reyon ul {}

.menu-children.reyon li a {
	color: #5a5a5a;
	color: var(--main-grey);
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 2px;
}

.menu-children.reyon li:last-child a {
	margin-bottom: 0;
}

.menu-children.reyon ul li a:hover {
	color: #000;
	color: var(--black);
}

.menu-children.reyon ul li a:hover::before {
	width: 100%;
	opacity: 1;
}

.menu-children.reyon ul li a::before {
	content: '';
	background: #000;
	background: var(--black);
	width: 0;
	height: .5px;
	position: absolute;
	bottom: -1px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	transition: width .3s ease,opacity .3s ease;
	opacity: 0;
}

.menu-children.reyon ul li.images a {
	height: 40px;
	line-height: 40px;
	background-size: 100%;
	text-indent: 8px;
	color: #fff;
	color: var(--white);
	display: block;
}

.menu-children.reyon ul li.images a {
	color: #fff;
	color: var(--white);
}

.menu-children > .container > .row > .col-3 > ul > li > svg {
	display: none;
}
/** MENU **/
/* SLIDER */
.carousel {
	top: -7px;
}

.carousel-control-next,
.carousel-control-prev {
	height: 60px;
	width: 60px;
	background: white;
	position: absolute;
	bottom: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	--webkit-ransform: translateY(-50%);
	transition: opacity .15s ease;
	color: #000!important;
	color: var(--main-color)!important;
	font-size: 2em;
	text-align: center;
	z-index: 1;
	opacity: 0;
	transition: visibility 0s linear .3s,opacity .3s linear;
}

.carousel-control-next:hover,
.carousel-control-prev:hover {
	color: #000;
	color: var(--main-color);
}

.carousel:hover .carousel-control-next,
.carousel:hover .carousel-control-prev {
	opacity: 1;
}
.carousel:hover .carousel-control-prev{
	text-align: left;
}
/** SLIDER **/
/* OCCASİON */
.occasion {
	margin: 0 auto;
	text-align: center;
}

.occasion .occa {
	width: 15%;
	height: 5em;
	margin-right: 1%;
	position: relative;
	padding: 16px 0 10px 5em;
	border-right: solid 1px rgba(0,0,0,.1);
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	line-height: 1.5em;
	font-size: 13px;
}

.occasion .occa svg {
	position: absolute;
	left: 5px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.occasion .occa strong {
	letter-spacing: .1em;
}

.occasion .occa:last-child {
	border-right: none;
}
/** OCCASİON **/
/* INDEX OPACİTY BANNER */
.main-opacity-banner {
	margin-top: 5%;
}

.main-opacity-banner a {
	opacity: 1;
	transition: 500ms opacity;
}

.main-opacity-banner [disabled] {
	opacity: .5;
}

.main-opacity-banner img {
	width: 100%;
}
/** INDEX OPACİTY BANNER **/
/* FULL BANNER */
.full-banner {
	margin-top: 35px;
	position: relative;
}

.full-banner h1,
.full-banner span {
	max-width: 40%;
	color: #fff;
	color: var(--white);
	text-align: center;
	line-height: 1.8em;
	position: absolute;
	top: 55px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	font-size: 2.2em;
	text-shadow: 2px 2px 5px rgba(0,0,0,1)
}

.full-banner span {
	top: 100px;
	font-size: 1.5em;
}

/** FULL BANNER **/
/* HİT PRODUCTS */
.hit-product .hp-slider {
	overflow: hidden;
	padding-top: 15px;
}

.hit-product img {
	width: 100%;
}

.hit-product .hp-slider .hp-row {
	width: 100%;
	-webkit-transform: translateX(0px);
	        transform: translateX(0px);
	transition: all 1.5s ease 0s;
	width: 3054px;
}

.hit-product .hp-slider .hp-row a {
	width: 23.5%;
	width: 234.5px;
	margin-right: 20px;
}

.hit-product .hp-slider .hp-row a:last-child {
	margin-right: 0;
}

.hit-product .hp-slider .hp-dots {
	text-align: center;
	padding: 0;
}

.hit-product .hp-slider .hp-dots li {
	padding: 3px;
	opacity: .3;
	cursor: pointer;
	display: inline-block;
	margin: 5px;
}

.hit-product .hp-slider .hp-dots li.active,
.hit-product .hp-slider .hp-dots li:hover {
	opacity: 1;
}

.hit-product .hp-slider .hp-dots li span {
	padding: 3px;
	border: 2px solid #000;
	border: 2px solid var(--black);
	border-radius: 50%;
	display: inline-block;
}
/** HİT PRODUCTS **/
/* SCL BRANDS */
.scl-brands img {
	width: 100%;
}
/** SCL BRANDS **/
/* FOOTER */
footer {
	padding-bottom: 60px;
	text-align: center;
	clear: both;
}

footer a {
	color: #5a5a5a;
	color: var(--main-grey);
}

footer a:hover {
	color: #000;
	color: var(--main-color);
}

footer ul {
	border-top: 1px solid #ddd;
	border-top: 1px solid var(--main-lightgrey);
	padding-top: 15px;
	text-align: center;
}

footer ul li {
	padding: 5px 15px;
	border-right: solid 1px #ddd;
	border-right: solid 1px var(--main-lightgrey);
}

footer ul li:first-child {
	padding-left: 0;
}

footer ul li:last-child {
	padding-right: 0;
	border-right: none;
}

footer .social a {
	color: #888;
	margin-left: 15px;
}

footer .social a:hover {
	color: #000;
	color: var(--main-color);
}

.go-head {
	background: #fff;
	background: var(--white);
	width: 40px;
	height: 40px;
	line-height: 43px;
	color: #000;
	color: var(--quick-silver);
	border: 2px solid #000;
	border: 2px solid var(--quick-silver);
	text-align: center;
	position: fixed;
	right: 25px;
	bottom: 70px;
	z-index: 10;
	transition: 300ms ease-out;
	cursor: pointer;
	display: none;
}

.go-head svg {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
	display: inline-block;
}

.go-head:hover {
	color: #fff;
	color: var(--white);
	background: #000;
	background: var(--quick-silver);
}

#ETBIS {
	position: absolute;
	bottom: 0;
	right: 220px;
}

#ETBIS img {
	width: 70px;
}

.popup {
	width: auto;
	position: fixed;
	bottom: 15px;
	left: 20px;
	background: white;
	border-radius: 30px;
	padding: 10px 40px 10px 20px;
	border: 1px solid #7e7e7e;
	z-index: 20;
	line-height: 2em;
	color: #000;
	max-width: calc(100% - 40px);
}

.popup .container {
	width: 100%;
}

.popup a {
	text-decoration: underline;
	font-weight: 600;
}

.popup a:hover {
	color: #1cab81;
	color: var(--main-hover);
}

.popup span {
	position: absolute;
	right: 5px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	font-size: 20px;
	border-radius: 50%;
	cursor: pointer;
}

.popup span:hover {
	font-weight: 600;
}
/** FOOTER **/
/* CONTENTS */

/* SIDERBAR */

.combimeModal .combine-products::-webkit-scrollbar,
.scl-product.combine .combine-list::-webkit-scrollbar,
#content .scl-sidebar::-webkit-scrollbar
{
	width: 3px;
	height: 3px;
	background: #eee;
}

.combimeModal .combine-products::-webkit-scrollbar-thumb,
.scl-product.combine .combine-list::-webkit-scrollbar-thumb,
#content .scl-sidebar::-webkit-scrollbar-thumb
{
	background: #ccc;
}

.combimeModal .combine-products::-webkit-scrollbar-thumb:hover,
.scl-product.combine .combine-list::-webkit-scrollbar-thumb:hover,
#content .scl-sidebar::-webkit-scrollbar-thumb:hover
{
	background: #999;
}

#content .scl-sidebar {
	width: 22%;
	padding-right: 1%;
	float: left;
	transition: 300ms all;
	overflow-y: auto;
	overflow-x: hidden;
	position: -webkit-sticky; /* Safari */
	position: sticky;
	top: 115px;
	padding-bottom: 0px;
	margin-bottom: 0;
	scrollbar-width: thin;
}

#content .scl-sidebar h3.section-title {
	padding-bottom: 5%;
	color: #5a5a5a;
	color: var(--main-grey);
	width: 100%;
	text-align: left;
}

#content .scl-sidebar .sections {
	float: left;
	margin-bottom: 25px;
}

#content .scl-sidebar .sections .kutu {
	float: left;
	color: #5a5a5a;
	color: var(--main-grey);
	padding: 10px 30px 10px 10px;
	border: 1px solid #000;
	border: 1px solid var(--quick-silver);
	margin: 0 5px 5px 0;
	position: relative;
	cursor: pointer;
}

#content .scl-sidebar .sections .arasi {
	float: left;
	color: #5a5a5a;
	color: var(--main-grey);
	padding: 10px 10px 10px 10px;
	position: relative;
	cursor: pointer;
}
#content .scl-sidebar .clear-all {
	float: right;
	font-size: 11px;
	font-weight: 500;
}

#content .scl-sidebar .sections svg {
	position: absolute;
	top: 50%;
	right: 10px;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

#content .scl-sidebar .sections span:active::after {
	color: #f63a6b;
	color: var(--main-active);
}

#content .scl-sidebar h3.filter-title {
	padding-bottom: 5%;
	margin-bottom: 5.8%;
	color: #5a5a5a;
	color: var(--main-grey);
	width: 100%;
	border-bottom: solid 1px #ddd;
	border-bottom: solid 1px var(--main-lightgrey);
	text-align: left;
	clear: both;
}

#content .scl-sidebar ul.filter-list label {
	display: inline-block;
	cursor: pointer;
}

#content .scl-sidebar ul.filter-list h3  {
	font-size: .9rem;
	padding: 8px 5px 8px;
	border-bottom: 1px solid #eee;
	min-height: 30px;
	cursor: pointer;
	margin-bottom: 20px;
}

#content .scl-sidebar ul.filter-list h3:first-child {
	margin-top: 0;
}

#content .scl-sidebar ul.filter-list h3 svg:nth-child(1) {
	display: none;
}

#content .scl-sidebar ul.filter-list h3.active svg:nth-child(2) {
	display: none;
}

#content .scl-sidebar ul.filter-list h3.active svg:nth-child(1) {
	display: inline-block;
}

#content .scl-sidebar ul.filter-list h3 + ul {
	height: 0;
	overflow: hidden;
	transition: 500ms height;
}

#content .scl-sidebar ul.filter-list h3.active + ul {
	height: unset;
}

#content .scl-sidebar ul.filter-list ul  {
	padding-left: 5px;
}
/** SIDERBAR **/
/** CONTENT **/

#content .scl-content {
	width: 75%;
	min-height: 500px;
	float: right;
	position: relative;
}

#content .scl-content .scl-content-title h2 {
	display: inline-block;
}

#content .scl-content .scl-content-title .scl-content-sort {
	float: right;
	padding-right: 20px;
	position: relative;
	top: 10px;
	z-index: 2;
	cursor: pointer;
}

#content .scl-content .scl-content-title .scl-content-sort.active {
	z-index: 21;
}

#content .scl-content .scl-content-title .scl-content-sort svg {
	position: absolute;
	right: 2px;
	top: 6px;
	border-color: black transparent transparent transparent;
}

#content .scl-content .scl-content-title .scl-content-sort ul {
	width: 255px;
	background: white;
	border: 1px solid #1cab81;
	border: 1px solid var(--main-hover);
	position: absolute;
	top: 20px;
	right: 0;
	display: none;
}

#content .scl-content .scl-content-title .scl-content-sort ul li span {
	padding: 20px 15px;
	display: block;
	transition: none;
	cursor: pointer;
}

#content .scl-content .scl-content-title .scl-content-sort ul li span:hover,
#content .scl-content .scl-content-title .scl-content-sort ul li.active span {
	background: #1cab81;
	background: var(--main-hover);
	color: white;
}

#content .scl-content .scl-content-title .scl-content-sort.active ul {
	display: block;
}

#content .pages ul {
	border: 1px solid #ddd;
	margin: 20px 0 40px 0;
	padding: 10px;
}

#content .pages ul li {
	padding: 10px 20px;
	margin: 0;
	cursor: pointer;
	position: relative;
}
#content .pages ul li::after {
	content:'';
	position: absolute;
	top: -11px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	border: 8px solid transparent;
	transition: .5s;
}

#content .pages ul li:hover::after, #content .pages ul li.active::after {
	border-top-color: #ccc;
}

#content .scl-product-list {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
}

#content .scl-product-list .scl-product {
	width: 32.5%;
	margin-bottom: 3em;
	transition: 300ms;
	position: relative;
	text-align: center;
}

#content .scl-product-list .scl-product._2 {
	width: 66%;
}

#content .scl-product-list .scl-product._3 {
	width: 100%;
}

#content .scl-product-list .scl-product .big-size {
	background: white;
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #1cab81;
	color: var(--main-hover);
	text-align: center;
	font-size: .9em;
	border-radius: 3px;
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 10;
}

#content .scl-product-list .scl-product .only-online {
	background: #1cab81;
	background: var(--main-hover);
	padding: 8px 10px;
	color: white;
	text-align: center;
	font-size: .9em;
	font-weight: 600;
	border-radius: 3px;
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 10;
}

#content .scl-product-list .scl-product .big-size + .only-online  {
	top: 55px;
}

#content .scl-product-list .scl-product img {
	width: 100%;
}

#content .scl-product-list .scl-product .title {
	padding: 10px 10px 5px 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: block;
}

.iprice {
	display: flex;
	font-size: 1.2em;
}

.iprice .amount {
  letter-spacing: 0.8px;
	color: #ff4545;
	color: var(--main-red);
	font-weight: 400;
	white-space: nowrap;
  text-align: right;
	padding: 5px 7px 0px;
	font-size: 0.9em;
	display: block;
}

.iprice .prices {
  padding-left: 7px;
	display: block;
	border-left: solid 0.5px #e8101d !important;
}

.iprice .prices .priced {
	font-weight: 400;
	white-space: nowrap;
	color: #000;
	color: var(--black);
	display: block;
	font-size: 1em;
}

.iprice .prices .priced.default {
    line-height: normal;
    letter-spacing: 0.2px;
		font-size: 0.9em;

		color: #5a5a5a !important;

		color: var(--main-grey) !important;
		font-weight: 400;
		display: block;
}

.iprice .default {
    text-decoration: line-through;
}

.iprice .old {
	color: #5a5a5a;
	color: var(--main-grey);
	font-weight: 400;
  display: block;
	margin-right: 10px;
}

.price {
	font-weight: 400;
	white-space: nowrap;
	color: #000;
	color: var(--black);
	white-space: nowrap;
	font-size: 1.2em
}

.price .old {
	color: #5a5a5a;
	color: var(--main-grey);
	font-weight: 400;
	position: relative;
	margin-right: 10px;
	text-decoration: line-through;
}

.add-to-basket .price {
	font-weight: 400;
	white-space: nowrap;
	color: var(--main-white);
	white-space: nowrap;
}

.add-to-basket .price .old {
	color: #ddd;
	color: var(--main-lightgrey);
	font-weight: 400;
	position: relative;
	margin-right: 10px;
	text-decoration: line-through;
}

#content .scl-product-list .scl-product .hover-menu {
	width: 92%;
	margin: 0 4%;
	position: absolute;
	bottom: 75px;
	background: rgba(255,255,255,.5);
	padding: 30px 3px 10px;
	opacity: 0;
	transition: 600ms opacity;
	z-index: 5;
}

#content .scl-product-list .scl-product .hover-menu.quick {
	background: transparent;
	padding: 15px 3px 10px;
	bottom: 60px;
}

#content .scl-product-list .scl-product .hover-menu.quick svg {
	font-size: 0;
}

#content .scl-product-list .scl-product .hover-menu.quick button.quick {
	background: #ffffff;
	letter-spacing: 3.3px;
	width: 65%;
	padding: 12px 10px;
	font-weight: 600;
	color: #000;
	color: var(--main-color);
	border: 1px solid #000;
	border: 1px solid var(--main-color);
}

#content .scl-product-list .scl-product .hover-menu.quick button.quick:hover {
	background: #000000;
	color: #fff;
	color: var(--white);
}

#content .scl-product-list .scl-product:hover .hover-menu {
	opacity: 1;
}

#content .scl-product-list .scl-product .hover-menu .arrows span {
	background: white;
	width: 40px;
	height: 40px;
	line-height: 40px;
	position: absolute;
	top: 0;
	left: 0;
	text-align: center;
	cursor: pointer;
	transition: 300ms;
}

#content .scl-product-list .scl-product .hover-menu .arrows span:hover {
	background: #ddd;
	background: var(--main-lightgrey);
}

#content .scl-product-list .scl-product .hover-menu .arrows span:last-child {
	left: auto;
	right: 0;
}

#content .scl-product-list .scl-product .hover-menu .buttons button {
	padding: 5.5% 2%;
	width: 49%;
	border: 1px solid #000;
	border: 1px solid var(--main-color);
	background: #000;
	background: var(--main-color);
	color: #fff;
	color: var(--white);
	transition: 300ms;
}

#content .scl-product-list .scl-product .hover-menu .buttons button.details {
	background: transparent;
	outline: 1px solid #5a5a5a;
	outline: 1px solid var(--main-grey);
	border-color: #5a5a5a;
	border-color: var(--main-grey);
	outline-offset: -6px;
	margin-right: 2%;
	color: #000;
	color: var(--main-color);
}

#content .scl-product-list .scl-product .hover-menu .buttons button:hover {
	background: transparent;
	color: #000;
	color: var(--main-color);
}

#content .scl-product-list .scl-product .hover-menu .buttons button:active {
	background: transparent;
	color: #f63a6b;
	color: var(--main-active);
	border-color: #f63a6b;
	border-color: var(--main-active);
}

#content .scl-product-list .scl-product .hover-menu .buttons button.details:hover {
	border-color: white;
	outline-color: white;
	color: white;
	background: #5a5a5a;
	background: var(--main-grey);
}

#content .scl-product-list .scl-product .hover-menu .body-list {
	margin-top: 25px;
}

#content .scl-product-list .scl-product .hover-menu .body-list ul li a {
	margin: 0 10px;
	color: #5a5a5a;
	color: var(--main-grey);
}

#content .scl-product-list .scl-product .hover-menu .body-list ul li.active a {
	color: #000;
	color: var(--main-color);
}

#content .scl-product-list .scl-product .colors-num {
	position: absolute;
	bottom: 70px;
	left: -1px;
	font-weight: 500;
	text-align: left;
	overflow: hidden;
	background: #ffffff6b;
	padding: 5px 10px 5px 5px;
	border-radius: 0 0 10px 0;
	color: #000;
}

#content .scl-product-list .scl-product .favorite {
	position: absolute;
	top: 10px;
	right: 15px;
	font-weight: 500;
	color: #000;
	cursor: pointer;
	opacity: .6;
	transition: 300ms;
}

#content .scl-product-list .scl-product .favorite svg {
	font-size: 1em;
}

#content .scl-product-list .scl-product:hover .favorite {
	opacity: 1
}

#content .scl-product-list .scl-product .favorite .active {
	color: #f63a6b;
	color: var(--main-active)
}

#content .scl-product-list .scl-product .not-in-stock {
	width: 92%;
	margin: 0 4%;
	position: absolute;
	bottom: 60px;
	background: rgba(255,255,255,.5);
	padding: 30px 3px 10px;
	z-index: 4;
}

#content .scl-product-list .scl-product .not-in-stock.quick {
	background: transparent;
	padding: 15px 3px 10px;
}

#content .scl-product-list .scl-product .not-in-stock.quick button.quick {
	background: #ffffffcc;
	letter-spacing: 3.3px;
	width: 65%;
	padding: 12px 10px;
	font-weight: 600;
	color: #000;
	color: var(--main-color);
	border: 1px solid #000;
	border: 1px solid var(--main-color);
}

#content .scl-product-list .scl-product .not-in-stock.quick button.quick:hover {
	background: #000000;
	color: #fff;
	color: var(--white);
}

#content .scl-product-list .scl-product .not-in-stock .buttons button {
	padding: 5.5% 2%;
	width: 49%;
	border: 1px solid #000;
	border: 1px solid var(--main-color);
	background: #000;
	background: var(--main-color);
	color: #fff;
	color: var(--white);
	transition: 300ms;
}

#content .scl-product-list .scl-product .not-in-stock .buttons button.details {
	background: transparent;
	outline: 1px solid #5a5a5a;
	outline: 1px solid var(--main-grey);
	border-color: #5a5a5a;
	border-color: var(--main-grey);
	outline-offset: -6px;
	margin-right: 2%;
	color: #000;
	color: var(--main-color);
}

#content .scl-product-list .scl-product .not-in-stock .buttons button:hover {
	background: transparent;
	color: #000;
	color: var(--main-color);
}

#content .scl-product-list .scl-product .not-in-stock .buttons button:active {
	background: transparent;
	color: "#000000aa";
	border-color: #f63a6b;
	border-color: var(--main-active);
}

#content .scl-product-list .scl-product .not-in-stock .buttons button.details:hover {
	border-color: white;
	outline-color: white;
	color: white;
	background: #5a5a5a;
	background: var(--main-grey);
}


.scl-product.combine .combine-list {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% - 10px);
	background: #ffffffe9;
	cursor: pointer;
	transition: .5s;
	overflow-y: auto;
	overflow-x: hidden;
	opacity: 0;
}

.scl-product.combine .look-modal {
	position: absolute;
	bottom: -20px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	color: #000;
	font-size: 13px;
	white-space: nowrap;
}

.scl-product.combine:hover .combine-list {
	opacity: 1;
}

.scl-product.combine .combine-product {
	width: 100%;
	float: left;
	padding: 10px 5px;
	border-bottom: 1px solid #e0e0e0;
}

.scl-product.combine .combine-product .image {
	width: 70px;
	float: left;
}

.scl-product.combine .combine-product span {
	padding-top: 5px;
	padding-left: 85px;
	text-align: left;
	display: block;
}

#content .scl-product-list .scl-product .color-palette .varyant-color {
	background: blue;
	border-color: blue;
	width: 30px;
	height: 30px;
	margin: 5px;
	border-radius: 50%;
	box-shadow: 1px 1px 5px rgba(0,0,0,.3);
	position: relative;
	cursor: pointer;
	display: inline-block;
}

#content .scl-product-list .scl-product .color-palette .varyant-color.active::after {
	content: '';
	width: 38px;
	height: 38px;
	border-radius: 50%;
	border: 2px solid;
	border-color: inherit;
	position: absolute;
	top: -4px;
	left: -4px;
}

#content .scl-product-list .scl-product .color-palette .varyant-color.active::before {
	content: '';
	background: white;
	width: 6px;
	height: 6px;
	position: absolute;
	top: 12px;
	left: 12px;
	box-shadow: 0 0 2px rgba(0, 0, 0, .5);
	border-radius: 6px;
}

#content .scl-not-item {
	padding: 20px 0;
	text-align: center;
	margin: 100px auto;
	color: #5a5a5a;
	color: var(--main-grey);
}

#content .scl-not-item svg {
	font-size: 5em;
	margin: 20px 0;
	display: inline-block;
}

#content .scl-not-item h2 {
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto 20px;
	text-transform: uppercase;
	position: relative;
}

/* DETAİL */
#detail {}

#detail nav .breadcrumb {
	padding: 0;
	background: transparent;
	margin: 3.4% 0;
}

#detail nav .breadcrumb-item a {
	color: #000;
	color: var(--quick-silver);
}

#detail nav .breadcrumb-item a:hover {
	color: #1cab81;
	color: var(--main-hover);
}

#detail nav .breadcrumb-item a:active {
	color: #f63a6b;
	color: var(--main-active);
}

nav .breadcrumb-item+.breadcrumb-item::before {
	content: ">";
	font-weight: 600;
	right: 0;
	top: 0px;
	position: relative;
	font-size: 13px;
}

#detail .detail-left {
	width: 48%;
	float: left;
}

#detail .detail-left .big-image {
	height: calc((100vw - 260px) * .48 * .8 * 1.25);
	position: relative;
	float: right;
	width: 80%;
}

#detail .detail-left .sag-ust {
	width: 55px;
	height: 50px;
	line-height: 47px;
	text-align: center;
	position: absolute;
	top: 6px;
	right: 6px;
	border-radius: 50%;
	font-size: 1.2em;
	z-index: 10;
	cursor: pointer;
}

#detail .detail-left .sag-ust.favorite {
	font-weight: 500;
	opacity: .6;
	transition: 300ms;
}

#detail .detail-left:hover .favorite {
	opacity: 1
}

#detail .detail-left .sag-ust.favorite.active {
	color: #f63a6b;
	color: var(--main-active)
}

#detail .detail-left .sol-ust {
	width: 60px;
	height: 30px;
	text-align: center;
	position: absolute;
	top: 18px;
	left: 0px;
	font-size: 1.1em;
	z-index: 10;
	display: block;
	color: #fff;
	color: var(--white);
	background: #e8101d;
	border: solid 1px #e8101d;
	font-weight: 600;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;

	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

#detail .detail-left .sol-alt {
	width: 60px;
	height: 30px;
	text-align: center;
	position: absolute;
	bottom: 18px;
	left: 0px;
	font-size: 1.1em;
	z-index: 10;
	display: block;
	color: #fff;
	color: var(--white);
	background: #e8101d;
	border: solid 1px #e8101d;
	font-weight: 600;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;

	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}

#detail .detail-left .zoom {
	background: #fff;
	width: 50px;
	height: 50px;
	line-height: 47px;
	text-align: center;
	position: absolute;
	bottom: 10px;
	right: 10px;
	border-radius: 50%;
	font-size: 1.2em;
	z-index: 10;
	cursor: pointer;
}

#detail .detail-left .thump-image {
	width: 94%;
	margin: 0 3%;
	transition: .7s;
	transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
	overflow-x: hidden;
	overflow-y: scroll;
}

#detail .detail-left .thump-image li {
	width: 100%;
	position: relative;
	cursor: pointer;
	transition: 300ms;
}

#detail .detail-left .thump-image li.active::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.3);
}

#detail .detail-left .thump-slider {
	position: relative;
	float: left;
	width: 20%;
}

#detail .detail-left .thump-slider.active {
	overflow: hidden;
}

#detail .detail-left .thump-slider.active .thump-image {
	margin: 0;
}

#detail .detail-left .thump-slider .thump-image li {
	height: calc((100vw - 260px) * .48 * .9 * .2 * 1.25);
}

#detail .detail-left .thump-slider.active .thump-image li {
	margin: 7px 5px;
	cursor: pointer;
}

#detail .detail-left .thump-slider.active .thump-image li:first-child {
	margin-left: 0;
}

#detail .detail-left .thump-slider .arrow:last-child {
	left: auto;
	right: 0;
}

#detail .detail-left .thump-slider .arrow:last-child svg  {
	background-position: -130px -50px;
	left: auto;
	right: 4px;
}

#detail .detail-right {
	width: 45%;
	float: left;
	padding-bottom: 80px;
	margin-left: 2%;
	position: relative;
}

#detail .detail-right h1 {
	margin-bottom: 10px;
	color: #000;
	color: var(--main-color);
}

#detail .detail-right .size-convert {
	font-size: 1rem;
	margin: 0 0 1rem;
}

#detail .detail-right .size-convert span {
	cursor: pointer;
	margin-right: 1rem;
	position: relative;
}

#detail .detail-right .size-convert span.active {
	color: #1cab81;
	color: var(--main-hover);
    font-weight: 600;
}

#detail .detail-right .size-convert span:not(:last-child)::before {
	content: '';
	width: 1px;
	height: .8rem;
	background: #5a5a5a;
	background: var(--main-grey);
	position: absolute;;
	top: .3rem;
	right: -.6rem;
}


#detail .detail-right .scl-info {
	color: #000;
	color: var(--quick-silver);
	height: 50px;
	line-height: 50px;
	cursor: pointer;
	float: right;
	height: auto;
	line-height: initial;
	display: inline-block;
	transition: 300ms all;
}

#detail .detail-right .scl-info:hover {
	color: #000;
	color: var(--main-color);
}

#detail .detail-right .scl-select-option > span {
	color: #000;
	color: var(--quick-silver);
	margin-bottom: 10px;
	display: inline-block;
	text-transform: capitalize;
}

#detail .detail-right .size-chart {
	color: #1cab81;
	color: var(--main-green);
	font-weight: 500;
	text-decoration: underline;
	float: right;
	cursor: pointer;
}

#detail .detail-right .scl-select-option ul li {
	width: 28px;
	height: 28px;
	border-radius: 50%;
	position: relative;
	cursor: pointer;
	border: 1px solid #eee;
	margin-right: .8rem;
	margin-bottom: 10px;
}

#detail .detail-right .scl-select-option ul li:before {
	content: '';
	width: 34px;
	height: 34px;
	border: 1px solid #ddd;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

#detail .detail-right .scl-select-option ul li.active:before {
	border-color: #000;
}
#detail .detail-right .scl-select-option ul li svg {display: none;}
/*
#detail .detail-right .scl-select-option ul li svg {
	line-height: 1;
	color: #fff;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	transition: opacity .3s cubic-bezier(0.4, 0, 1, 1);
	font-size: 2em;
	filter: drop-shadow(0 0 2px rgba(0,0,0,0.4));
}

#detail .detail-right .scl-select-option ul li.whiteColor svg {
	color: #000;
	filter: unset;
}
*/
#detail .detail-right .scl-select-option ul li:first-child {
	margin-left: .3rem;
}

#detail .detail-right .sizes {
	margin: 10px 0;
}

#detail .detail-right .sizes span {
	width: 34px;
	height: 34px;
	line-height: 34px;
	border: 1px solid #ddd;
	color: #5a5a5a;
	margin-right: 10px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	border-radius: 50%;
	position: relative;
	z-index: 1
}

#detail .detail-right .sizes span::before {
	content: "";
	width: 26px;
	height: 26px;
	background: #ededed;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	border-radius: 30px;
	z-index: -1
}

#detail .detail-right .sizes span.active {
	border-color: black;
}

#detail .detail-right .sizes span.not {
	opacity: .5;
	position: relative;
}

#detail .detail-right .sizes span.not.active {
	opacity: .7;
	position: relative;
}

#detail .detail-right .sizes span.not::before {
	content: '';
	width: 24px;
	height: 1px;
	background: #000;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%) rotate(45deg);
	        transform: translate(-50%, -50%) rotate(45deg);
	display: block;
}

#detail .detail-right .social {
	height: 80px;
	position: relative;
	margin-top: 30px;
	display: block;
}

#detail .detail-right .social > ul li {
	width: 26%;
	margin: 0 1%;
	text-align: center;
	border: 1px solid #8a8a8a;
	color: #8a8a8a;
	border-radius: 3px;
	padding: 5px;
	height: 80px;
	float: left;
	font-size: 12px;
	cursor: pointer;
}

#detail .detail-right .social > ul li a {
	color: #8a8a8a;
}

#detail .detail-right .social > ul li:last-child {
	width: 14%;
	margin: 0;
}

#detail .detail-right .social  >ul li svg {
	width: 100%;
	margin-bottom: 10px;
	font-size: 40px;
	height: 40px;
	display: inline-block;
	color: #5e5e5e;
}

#detail .detail-right .social > ul li.scl-wp svg {
	color: #25D366;
}

#detail .detail-right .social > ul li.scl-shareopen svg,
#detail .detail-right .social > ul li.scl-friend svg,
#detail .detail-right .social > ul li.scl-phone svg {
	height: 30px;
	margin-bottom: 13px;
	margin-top: 7px;
}

#detail .detail-right .social > ul li.scl-shareopen svg
{
	color: #999;
}

#detail .detail-right .social > ul li:hover , #detail .detail-right .social ul li:hover a, #detail .detail-right .social ul li:not(.scl-wp):hover svg {
	border-color: #333;
	color: #333;
}

#detail .detail-right .social > ul li:hover {
	margin-top: .2em;
	-webkit-transform: scale(1.05);
	        transform: scale(1.05);
}

#detail .detail-right .social .share-window {
	background: #fff;
	position: absolute;
	bottom: 15px;
	right: 35px;
	padding: 30px 10px 20px;
	border: 1px solid #999;
	border-radius: 3px;

}

#detail .detail-right .social .share-window .close {
	position: absolute;
	right: 0;
	top: 0;
	font-size: 18px;
	cursor: pointer;
}

#detail .detail-right .social .share-window ul li {
	width: 40px;
	height: 40px;
	line-height: 35px;
	text-align: center;
	border: 1px solid #ccc;
	border-radius: 3px;
	float: left;
	margin: 0 5px;
	cursor: pointer;
}

#detail .detail-right .social .share-window input {
	width: 220px;
	font-size: 10px;
	padding: 10px;
	box-shadow: none;
}


#detail .detail-right .non-stock {
	color: #aeaeae;
	font-weight: 900;
	padding: 7px 10px;
	font-size: 1.5em;
}

#detail .detail-right .go-basket button {
	width: 100%;
	height: 46px;
	border: 1px solid #000;
	border: 1px solid var(--main-color);
	background: #000;
	background: var(--main-color);
	color: #fff;
	color: var(--white);
	transition: 300ms;
}

#detail .detail-right .go-basket button.active {
	background: #1cab81;
	background: var(--main-hover);
	border-color: #1cab81;
	border-color: var(--main-hover);
}

#detail .detail-right .go-basket button:hover {
	border-color: #000;
	border-color: var(--main-color);
	color: #000;
	color: var(--main-color);
	background: transparent;
}

#detail .detail-right .go-basket button:active {
	border-color: #f63a6b;
	border-color: var(--main-active);
	color: #f63a6b;
	color: var(--main-active);
	background: transparent;
}

#detail .detail-right .go-basket  svg.heart {
	transition: 300ms;
	font-size: 3em;
}

#detail .detail-right .go-basket svg.heart,
#detail .detail-right .go-basket svg.heart {
	cursor: pointer;
}

#detail .detail-right .go-basket .active svg {
	color: #f63a6b;
	color: var(--main-active);
}


#detail .detail-right .malt {
	background: #fff;
	height: 66px;
	position: fixed;
	left: 0;
	bottom: 0;
	margin: 0;
	font-size: 0.95rem;
	z-index: 14;
	text-align: center;
	padding:10px;
}

button.add-to-basket {
	padding: 11px;
	padding-right: 20px;
	padding-left: 20px;
	width: 100%;
	height: 100%;
	border: 2px solid #000;
	border: 2px solid var(--main-color);
	background: #000;
	background: var(--main-color);
	color: #fff;
	color: var(--white);
	transition: 300ms;
}

.malt button.add-to-basket {
	font-size: 1em;
	font-weight: 500;
}

.malt button.add-to-basket .price {
	font-size: 1em;
	font-weight: 500;
}

button.add-to-basket > * {
	display: inline-block !important;
	text-align:center;
	height: 50%;
}

button.add-to-basket:hover {
	border-color: #1cab81;
	border-color: var(--main-hover);
}

button.add-to-basket.active {
	border-color: #1cab81;
	border-color: var(--main-hover);
}
button.add-to-basket.active:hover {
	border-color: #000;
	border-color: var(--main-color);
}

.add-to-basket svg.heart {
	transition: 300ms;
	font-size: 3em;
}

.add-to-basket svg.heart,
.add-to-basket svg.heart {
	cursor: pointer;
}

.add-to-basket.active svg {
	color: #f63a6b;
	color: var(--main-active);
}





#detail .detail-right .in-basket {
	color: #000;
	color: var(--main-color);
	padding: 15px 0 10px;
}

#detail .detail-right .services {
	margin-top: 20px;
	display: inline-block;
}

#detail .detail-right .services ul {
	margin: 0;
}

#detail .detail-right .services ul li {
	font-size: 1.2em;
	color: #333;
	text-transform: uppercase;
	white-space: nowrap;
	display: inline-block;
}

#detail .detail-right .services ul li:nth-child(1)  {
	width: 55%;
}

#detail .detail-right .services ul li:nth-child(2)  {
	width: 45%;
}

#detail .detail-right .services ul li svg {
	display: inline-block;
	font-size: 5.5em;
	float: left;
}

#detail .detail-right .services ul li span {
	display: inline-block;
	padding-left: 5px;
}

#detail .detail-right .services ul li span:nth-child(2) {
	margin: 22px 10px;
	line-height: 1.5;
}

.add-basket-product {
	margin: 10px 0;
}

.add-basket-product > .title {
	margin-bottom: 15px;
}

.add-basket-product .img {
	width: 20%;
	float: left;
	margin-right: 15px;
}

.add-basket-product b {
	font-weight: 500;
	margin-right: 10px;
}

.add-basket-product > span {
	display: block;
	text-align: left;
}

.product-feature {
	color: #000;
	color: var(--quick-silver);
	padding-top: 15px;
}

.product-feature .feature-item > a,
.product-feature .feature-item > span {
	padding: 15px 0;
	border-bottom: 1px solid #d0d0d0;
	border-bottom: 1px solid var(--form-border);
	margin-bottom: 10px;
	display: block;
	cursor: pointer;
}

.product-feature .feature-item > span svg,
.product-feature .feature-item > a svg {
	float: right;
}

.product-feature .feature-item > span svg {
	-webkit-transform: rotate(270deg);
	        transform: rotate(270deg);

}
.product-feature .feature-item.active > span svg {
	/* content: "\e907"; */
	-webkit-transform: rotate(0);
	        transform: rotate(0);
}

.product-feature .feature-item > span + .feature-content {
	display: none;
}

.product-feature .feature-item.active > span + .feature-content {
	display: block;
}

.product-feature .feature-content .col > strong {
	padding: 10px 0;
	display: block;
}

ul.disc {
	padding-left: 17px;
}

ul.disc li {
	list-style: disc;
}

.product-feature .feature-content ul li {
	margin: 10px 0 0;
}

.product-feature .feature-content ul.scl-yt li {
	margin: 10px 10px 10px 0;
}

.product-feature .feature-content ul.scl-yt li img {
	vertical-align: middle;
	opacity: .6;
	display: inline-block;
}

.product-feature .feature-content ul.scl-yt li.local {
	display: block;
	opacity: .8;
}

.product-feature .feature-content {
	position: relative;
}

.last-enter h2.hr::before {
	width: 84%;
	left: 8%;
}

.last-enter h2.hr span {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	letter-spacing: .2em;
	font-weight: 600;
	color: #5a5a5a;
	color: var(--main-grey);
	padding: 0;
}

.last-enter .swiper-slide {
	max-width: calc((100vw - 260px) * .1166);
	margin-right: 10px;
}

.last-enter .lse-product-list .lse-item {
	float: left;
}

.last-enter .lse-product-list .lse-item .img {
	overflow: hidden;
	width: 100%;
	height: calc((100vw - 260px) * .1166 * 1.25);
	position: relative;
}

.last-enter .lse-product-list .lse-item .img img {
	transition: 2s -webkit-transform ease;
	transition: 2s transform ease;
	transition: 2s transform ease, 2s -webkit-transform ease;
	width: 100%;
}

.last-enter .lse-product-list .lse-item:hover .img img {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}

.last-enter .lse-product-list .lse-item .title {
	padding: 10px 0;
	text-align: center;
	display: block;
}

.complete-dress h2.hr span {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	letter-spacing: .2em;
	font-weight: 600;
	color: #5a5a5a;
	color: var(--main-grey);
	padding: 0;
}

.complete-dress .product-list {
	position: relative;
	display: flex;
	line-height: 1.6;
}

.complete-dress .product-list .item {
	width: 16%;
	margin-right: 1%;
}

.complete-dress .product-list .item span {
	display: inline-block;
	margin-right: 10px;
	position: relative;
}

.complete-dress .product-list .old::after {
	content: '';
	width: 110%;
	height: 1px;
	background: #5a5a5a;
	background: var(--main-grey);
	position: absolute;
	top: 45%;
	left: -5%;
}

.complete-dress .product-list .item span.title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 90%;
}

.complete-dress .product-list .item span.price {
	font-size: 15px;
	font-weight: 500;
	color: #3e3e3e;
}

.complete-dress .product-list .item:last-child {
	margin-right: 0;
}

.complete-dress .product-list .item .img {
	width: 100%;
	height: calc((100vw - 260px) * .16 * 1.25);
	position: relative;
}

/** DETAİL **/
/* MALLS */
.malls {
	line-height: 1.6em;
	padding: 0!important;
	color: #000;
	color: var(--main-color);
}

.malls h2 {
	font-weight: 400;
	margin-bottom: 1em;
}

.malls .col-4 {
	border-bottom: 1px solid #ddd;
	border-bottom: 1px solid var(--main-lightgrey);
}

#content.malls .scl-sidebar {
	overflow: unset;
	position: relative;
	top: 0;
	padding: 0;
}

._404 {
	width: 76%;
	margin: 0 auto 0px;
	text-align: center;
	display: block;
}

._404 .char_404 {
	font-size: 2em;
	padding-bottom: 10px;
	display: inline-block;
}

._404 h1 {
	font-size: 1.5em;
	margin-bottom: 15px
}

._404 h1.bg {
	width: 70%;
	height: 50px;
	line-height: 50px;
	padding-top: 20px;
	font-weight: 500;
	margin: 0 auto;
	text-transform: uppercase;
	position: relative;
}

._404 .searching {
	position: relative;
}

._404 input {
	box-shadow: none!important;
	border-radius: 0
}

._404 .searching span {
	width: 40px;
	height: 40px;
	line-height: 36px;
	display: inline-block;
	position: absolute;
	top: 0;
	right: 0px;
	cursor: pointer;
}

#content ._404 + .scl-product-list {
	padding: 0 15%;
	margin: 20px 0 50px;
	float: left;
	width: 100%;
}

#content ._404 + .scl-product-list .scl-product {
	width: 18.5%;
}

#content ._404 + .scl-product-list .scl-product .colors-num {
	font-size: .9em;
}

#content ._404 + .scl-product-list .scl-product .images {
	height: calc((100vw - 260px) * .7 * .185 * 1.25)
}

/** MALLS **/
/* BASKET */

header.basket  {
	box-shadow: 0px 0px 5px rgba(0,0,0,0.1)
}

header.basket .head .container  {
	width: calc(100% - 40px);
	max-width: calc(100% - 40px);
}

header.basket .scl-head-msg {
	color: #000;
	color: var(--quick-silver);
	text-decoration: underline;
	line-height: 72px;
}

header .secure-pay span {
	display: inline-block;
	width: 118px;
	line-height: 1.5em;
	color: var(--main-silver);
}

header .secure-pay svg {
	display: inline-block;
	float: left
}

#basket  {
	min-height: 90vh;
}

#basket .basketMessage {
	font-size: 15px;
	line-height: 1.3em;
	width: 100%;
	text-align: center;
	display: block;
}

#basket .basketMessage .dangerCampaign {
	background: #ff4545;
	background: var(--main-red);
	color: white;
	display: block;
}

#basket .basketMessage .msg-btn {
	background: white;
	color: #ff4545;
	color: var(--main-red);
	padding: 4px 8px;
	margin: 6px 15px;
	font-size: .8em;
	display: inline-block;
}

#basket .basketMessage .successCampaign {
	background: #ea575736;
	padding: 10px 0;
	display: block;
}

#basket .basketMessage .swiper-container {
	padding-bottom: 0!important;
}

#basket iframe {
	height: auto;
	min-height: 500px;
	top: -40px;
	left: 15px;
	position: relative;
	max-width: 1000px;
}

#basket .container {
	min-height: 400px;
	padding-top: 30px;
	width: calc(100% - 100px);
}

#basket h1.tabs span {
	padding: 8px 15px;
	margin: 0 5px;
	position: relative;
	display: inline-block;
	cursor: pointer;
	background: rgba(55, 55, 55, .2);
	border-radius: 30px;
	position: relative;
}

#basket h1.tabs span.active {
	background: #1cab81;
	background: var(--main-green);
	color: #fff;
	padding: 10px 15px;
}


#basket .basket-product-list .row:first-child {
	border: 1px solid #ddd;
	border: 1px solid var(--main-lightgrey);
	border-width: 1px 0;
	padding: 15px 0;
}

#basket .basket-product-list .row:not(:first-child) {
	position: relative;
	align-items: center;
}

#basket .basket-product-list .row > div:nth-child(2) span {
	display: block;
	margin-top: 20px;
}

#basket .basket-product-list .row > div:nth-child(2) span.old {
	display: inline-block;
	margin-right: 10px;
	font-size: .9em;
	text-decoration: line-through;
	margin-top: 0;
	margin-top: 20px;
}

#basket .basket-product-list .row > div:nth-child(2) .old + span {
	display: block;
	margin-top: 5px;
}

#basket .basket-product-list .row:not(:first-child) > div:last-child {
	text-align: center;
}

#basket .basket-product-list .row .loading, #basketHover .content .loading {
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(200,200,200,.1);
	z-index: 4;
}

#basket .basket-product-list .row .loading .loader, #basketHover .content .loading .loader {
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
	position: absolute;
}

#basket .basket-product-list .row .loading .loader span, , #basketHover .content .loading .loader span {
	margin: 0;
}

#basket .basket-product-list .row:not(:first-child) .form-check {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	left: 5px;
}

#basket .basket-product-list + .col {
	position: -webkit-sticky;
	position: sticky;
	top: 150px;
	height: 100%;
}

#basket .basket-info {
	padding: 20px 40px;
	border: 1px solid #ddd;
	border: 1px solid var(--main-lightgrey);
	top: -30px;
	position: relative;
	color: #000;
	color: var(--main-color)
}

#basket .basket-info h2 {
	padding: 0;
	font-weight: 500;
}

#basket .basket-info .row:not(:first-child) {
	padding: 20px 0;
}

#basket .basket-info .row:not(:first-child):not(.dashedTB) span {
	width: 60%;
	float: right;
	padding: 0;
	font-weight: 500;
	font-size: 14px;
}

#basket #campaignData {
	margin-top: 20px;
	font-size: 12px;
	height: 32px;
	line-height: 32px;
	display: block;
	width: auto;
	border-color: #ef6454;
	border-color: var(--theme-color);
	color: #ef6454;
	color: var(--theme-color);
	clear: both;
	border-radius: 3px;
	display: inline-block;
	max-width: 100%;
}

#basket #campaignData .selected-item.only-text {
	height: 32px;
	line-height: 32px;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

#basket #campaignData .scl-select-options {
	width: auto;
	color: #5a5a5a;
	color: var(--main-grey);
	border-color: #ddd;
	border-color: var(--main-lightgrey);
}

#basket #campaignData .scl-select-options.only-text .scl-select-item {
	height: 30px;
	line-height: 30px;
	margin: 2px 0;
}
#basket #campaignData .scl-select-options .scl-select-item.active {
	background: #5a5a5a;
	background: var(--main-grey)
}

#basket .dashedTB .price {
	font-size: 15px;
}

#basket .basket-info .dashedTB .price {
	font-size: 13px;
}

#basket .dashedTB .price span {
	display: block!important;
	width: auto!important;
}

#basket .dashedTB .price span.green {
	position: relative;
	height: 20px;
	line-height: 20px;
	margin: -2em  0 1em -5px;
	background: #1cab81;
	background: var(--main-green);
	padding: 0 20px 0 5px;
	font-size: .8rem;
	color: #fff;
	display: inline-block;
}
#basket .dashedTB .price span.green::before {
	content: '';
	width: 10px;
	height: 10px;
	border: 10px solid transparent;
	border-right-color: white;
	display: block;
	position: absolute;
	right: 0;
}

#basket .dashedTB .price .old {
	display: block;
	text-decoration: line-through;
	font-size: .8em;
	margin: 5px 0;
	color: #999;
}

#basket .basket-info .row:not(:first-child) span:nth-child(2) {
	text-align: right;
	width: 40%;
}

#basket .basket-info .row:not(:first-child) span {
	display: inline-block;
}

#basket .basket-info .row:not(:first-child) span small {
	display: block;
	margin-top: 5px;
	color: #5a5a5a;
	color: var(--main-grey);
}

#basket .steps {
	text-align: center;
	margin: -15px 0 50px;
	position: relative;
}

#basket .steps ul {
	display: flex;
	justify-content: center;
}

#basket .steps::before {
	content: '';
	width: 70%;
	height: 1px;
	background: #d0d0d0;
	background: var(--form-border);
	position: absolute;
	top: 50%;
	left: 15%;
	z-index: 1;
}

#basket .steps li {
	background: #f2f3f4;
	margin-right: 90px;
	padding: 0 20px;
	position: relative;
	display: flex;
	align-items: center;
	z-index: 2;
}

#basket .steps li:fisrt-child {
	margin-left: 0;
}

#basket .steps li:last-child {
	margin-right: 0;
}

#basket .steps .check {
	width: 45px;
	height: 45px;
	border: 3px solid #d0d0d0;
	border: 3px solid var(--form-border);
	color: #d0d0d0;
	color: var(--form-border);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 15px 0 0;
}

#basket .steps .check svg {
	font-size: 2.5rem;
	position: relative;
	top: 20px;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	display: none;
}

#basket .steps .active.check {
	border-color: #1cab81;
	border-color: var(--main-green);
	color: #1cab81;
	color: var(--main-green);
	font-size: 0;
}

#basket .steps .active.check svg {
	display: inline-block;
}

#basket .steps svg small {
	font-size: 12px;
}

#basket .steps .active::before {
	display: block;
}

#basket .credit-card {
	width: 250px;
	height: 160px;
	border: 1px solid #ddd;
	border-radius: 10px;
	padding-bottom: 15px;
	overflow: hidden;
	position: relative;
	background: linear-gradient(283deg, #dcdcdc, #fff);
}

#basket .credit-card .bank {
	width: 100%;
	height: 40px;
	padding: 10px
}

#basket .credit-card .bank img {
	max-height: 30px;
	float: left;
	max-width: 60%;
}

#basket .credit-card .card-brand {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

#basket .credit-card .card-brand img {
	height: 20px;
}

#basket .credit-card .cardno {
	height: 50px;
	line-height: 50px;
	font-size: 15px;
	margin-left: 15px;
	margin-top: 10px;
	font-family: 'Orbitron', sans-serif;
	letter-spacing: .5px;
}

#basket .credit-card .name {
	font-size: 14px;
	margin-left: 15px;
	margin-top: 5px;
}

#basket .credit-card .date {
	margin-left: 15px;
	margin-top: 10px;
}

#basket .address-select {
	display: flex;
	flex-wrap: wrap;
}

#basket .address-select .address {
	width: 32%;
	padding: 10px 20px 10px 10px;
	margin: 0 1% 10px 0;
	border: 1px solid #ddd;
	border: 1px solid var(--main-lightgrey);
	border-radius: 5px;
	position: relative;
	float: left;
	overflow: hidden;
}

#basket .address-select .address span {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin-bottom: 5px;
	display: block;
	min-height: 15px;
}

#basket .address-select .address.personal span {
	margin-bottom: 10px;
	overflow: visible;
}

#basket .address-select .address span.check {
	width: 16px;
	height: 16px;
	border: 2px solid #ddd;
	border: 2px solid var(--main-lightgrey);
	border-radius: 50%;
	position: absolute;
	display: block;
	right: 5px;
	top: 5px;
}

#basket .address-select .address span.editing {
	position: absolute;
	bottom: 0;
	right: 10px;
}

#basket .address-select .address.active span.check {
	border-color: #1cab81;
	border-color: var(--main-hover);
	border-width: 2px;
}

#basket .address-select .address.active span.check::before {
	content: '';
	width: 8px;
	height: 8px;
	background: #1cab81;
	background: var(--main-hover);
	border-radius: 50%;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	position: relative;
	display: block;
}

#basket .address-select .address span.check + span {
	color: #000;
	color: var(--main-color);
	margin-bottom: 10px;
	display: inline-block;
	font-weight: 600;
	letter-spacing: .5px;
}

#basket .address-select .address .edit {
	position: absolute;
	top: 10px;
	right: 10px;
	cursor: pointer;
}

#basket .address-select .address .edit:hover {
	color: #1cab81;
	color: var(--main-hover);
}

#basket .address-select .new-address {
	height: 145px;
	color: #ccc;
	transition: 300ms;
	padding: 45px 0!important;
}
#basket .address-select .new-address:hover {
	color: #5a5a5a;
	color: var(--main-grey)
}


#basket .address-select .new-address svg {
	font-size: 3em;
}

#basket .cargo-select .cargo {
	padding-left: 45px;
	position: relative;
	height: 50px;
	line-height: 50px;
	cursor: pointer;
	margin: 5px 0;
}

#basket .cargo-select .cargo::before {
	content: '';
	width: 16px;
	height: 16px;
	border: 2px solid #ddd;
	border: 2px solid var(--main-lightgrey);
	border-radius: 50%;
	position: absolute;
	display: block;
	left: 12px;
	top: 17px;
}

#basket .cargo-select .cargo.active::before {
	border-color: #1cab81;
	border-color: var(--main-hover);
}

#basket .cargo-select .cargo.active::after {
	content: '';
	width: 8px;
	height: 8px;
	background: #1cab81;
	background: var(--main-hover);
	border-radius: 50%;
	top: 21px;
	left: 16px;
	position: absolute;
	display: block;
}

#basket .choices-select .item {
	padding-left: 45px;
	position: relative;
	padding: 20px 0 20px 40px;
	cursor: pointer;
	display: block;
	line-height: 2;
}

#basket .choices-select .item > span {
	display: block;
}

#basket .choices-select .item .check {
	content: '';
	width: 16px;
	height: 16px;
	border: 2px solid #5a5a5a;
	border: 2px solid var(--main-grey);
	border-radius: 50%;
	position: absolute;
	display: block;
	left: 12px;
	top: calc(50% - 8px);
}

#basket .choices-select .item.active .check::after {
	content: '';
	width: 8px;
	height: 8px;
	background: #1cab81;
	background: var(--main-hover);
	border-radius: 50%;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	position: absolute;
	display: block;
}

#basket .choices-select .item img {
	max-width: 130px;
	max-height: 30px;
	margin-right: 20px;
}

#basket .installment .check {
	border: 1px solid #1cab81;
	border: 1px solid var(--main-green);
	border-radius: 50%;
	position: relative;
	float: left;
	width: 14px;
	height: 14px;
	margin-right: -30px;
}

#basket .installment .check.active::before {
	content: '';
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #1cab81;
	background: var(--main-green);
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

#basket .basket-success .success, #basket .basket-success .info  {
	text-align: center;
	font-size: 15px;
}

#basket .basket-success .row.success  {
	padding: 10% 0 5%;
	line-height: 1.6;
}

#basket .basket-success .bgw {
	position: relative;
	margin: 10em 0;
}

#basket .basket-success h1 {
	font-weight: 600;
	font-size: 20px;
}

#basket .basket-success svg {
	width: 124px;
	height: 124px;
	background: #1cab81;
	background: var(--main-green);
	padding: 10px;
	border-radius: 50%;
	color: #fff;
	position: absolute;
	top: -50px;
	font-size: 7em;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

#basket .basket-success .btn {
	background: #000;
	color: #fff;
	border: none;
	margin-top: 30px;
}

#basket .basket-success .btn:hover {
    padding: 10px 30px;
}

#basket .scroll-container
{
	padding: 20px 10px;
	border: 1px solid #ccc;
	line-height: 1.5;
	margin-bottom: 20px;
}

#basket .scroll-container tr, #basket .scroll-container td, #basket .scroll-container th
{
	border: 1px solid #ccc;
	text-align: center;
	padding: 5px;
}

#basket .scroll-container table {
	width: 100%;
}

.basket-empty {
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translateY(-50%) translateX(-50%);
	        transform: translateY(-50%) translateX(-50%);
	font-size: 20px;
	font-weight: 100;
	text-align: center;
}

.basket-empty svg {
	font-size: 5em;
}

.basket-empty span {
	margin: 30px 0;
	display: block;
}

.basket-empty .btn {
	width: auto!important;
	padding: 10px 25px!important;
}

.basket-empty .btn {
	padding: 15px 55px;
}
/** BASKET **/
/* USER PAGE */
.scl-sidebar .user-panel li a {
	display: block;
	color: #000;
	color: var(--quick-silver);
	padding-bottom: 10%;
	margin-bottom: 9%;
	transition: color .1s ease-in;
	border-bottom: 1px solid #ddd;
	border-bottom: 1px solid var(--main-lightgrey);
	text-transform: uppercase;
}

.scl-sidebar .user-panel li a:hover {
	color: #000;
	color: var(--black);
}

.scl-sidebar .user-panel li.active a {
	font-weight: 500;
}

.scl-section-content {
	position: relative;
	min-height: 300px;
}

.user-page .order-empty svg {
	font-size: 3.5em;
	color: #5a5a5a;
	color: var(--main-grey);
}

.user-page .order-empty span {
	font-size: 18px;
	margin: 15px;
	font-weight: 300;
	line-height: 2;
	font-weight: 500;
	text-transform: uppercase;
	color: #5a5a5a;
	color: var(--main-grey);
}

.user-page .order-empty .btn {
	margin-top: 20px;
	padding: 10px 15px;
}

.user-page .scl-content-title {
	margin: 0;
	margin-bottom: 10px;
}

.user-page .scl-content .scl-content-title h1 {
	color: #2a2a2a!important;
	text-transform: uppercase;
}

.user-page .btn.user-title-btn {
	padding: 5px 10px;
	margin-top: 0px;
}

.user-page .user-title-btn.light {
	margin: 8px 0;
	padding: 2px 0;
	background: #fff;
	background: var(--white);
	color: #5a5a5a;
	color: var(--main-grey);
	border: none;
	border-bottom: 1px solid #5a5a5a;
	border-bottom: 1px solid var(--main-grey);
}

.user-page .scl-content-title.tab {
	border-bottom: 1px solid #ddd;
	border-bottom: 1px solid var(--main-lightgrey);
	margin-bottom: 15px;
	padding: 0 0 10px;
}

.user-page .scl-content-title.tab h1 {
	float: left;
	margin-right: 20px;
	color: #ddd;
	color: var(--main-lightgrey);
	cursor: pointer;
}

.user-page .scl-content-title.tab h1.active {
	color: #000;
	color: var(--main-color);
}

.order-ticket, .order-ticket:hover {
	height: 70px;
	line-height: 70px;
	padding: 0 20px;
	color: #5a5a5a;
	color: var(--main-grey);
	margin-top: 30px;
	position: relative;
	background: #0001;
	display: block;
}

.order-ticket::before, .order-ticket::after {
	content: '';
	position: absolute;
	top: -10px;
	left: -10px;
	width: 30px;
	height: 30px;
	border: solid #aaa;
	border-width:  2px 0 0 2px;
	transition: 300ms;
}

.order-ticket::after {
	top: auto;
	left: auto;
	right: -10px;
	bottom: -10px;
	border-width:  0 2px 2px 0;
}

.order-ticket:hover::before, .order-ticket:hover::after {
	border-color: #5a5a5a;
	border-color: var(--main-grey);
	width: 60px;
	height: 60px;
}

.order-ticket span:nth-child(2) {
	float: right;
}

.order-ticket svg {
	position: relative;
	float: left;
	top: 15px;
	margin-right: 10px;
	line-height: inherit;
	font-size: 3em;
}

.user-page .list {
	vertical-align: middle;
	line-height: 1.6em;
	color: #000;
	color: var(--quick-silver);
}

.user-page .list .row.head {
	border-width: 2px;
	font-weight: 500;
	margin-top: 2em;
}

.user-page .list .row {
	align-items: center;
	border-bottom: 1px solid #ddd;
	border-bottom: 1px solid var(--main-lightgrey);
	padding: 0 10px 10px;
	margin: 10px 0;
	position: relative;
}

.user-page .list .row .go-detail {
	width: 30px;
	height: 30px;
	line-height: 28px;
	margin-top: -10px;
	border-radius: 30px;
	font-weight: 600;
	background: #eee;
	color: #666;
	text-align: center;
	position: absolute;
	right: 5px;
	bottom: 15px;
}

.user-page .list .edit svg {
	font-size: 2em;
	margin-top: -2px
}

.user-page .list .edit span,
.user-page .list .msg span {
	display: inline-block;
	cursor: pointer;
}

.user-page .list .edit span:nth-child(2) {
	margin-left: 10px;
}

.user-page .list .edit span:nth-child(2):hover {
	color: #f63a6b;
	color: var(--main-active);
}

.user-page .list .edit span:nth-child(1):hover,
.user-page .list .msg span:hover {
	color: #1cab81;
	color: var(--main-hover);
}

.user-page .list .edit .btn {
	padding: 4px 10px;
	font-size: 12px;
	margin-top: 4px;
}

.user-page .info {
	padding: 1% 13%;
	border: 1px solid #ddd;
	border: 1px solid var(--main-lightgrey);
}

.user-page .info .row {
	padding: 10px 0;
	line-height: 1.6em;
}

.user-page .info .row.bdr,
.user-page .list .row.bdr {
	padding: 20px 0;
	border-bottom: 1px solid #ddd;
	border-bottom: 1px solid var(--main-lightgrey);
}

.user-page .info a {
	color: #1cab81;
	color: var(--main-hover);
	text-decoration: underline;
}

.user-page .info h1 {
	text-align: center;
	padding: 10px 0 5px;
	border-bottom: 3px solid #333;
	color: #333!important;
	margin: 15px 0!important;
	font-size: 17px!important;
}

.user-page .info .row span {
	display: block;
}

.user-page .info .row.order-short .col:nth-child(3), .user-page .info .row.order-short .col:nth-child(4) {
	align-items: center;
	display: flex;
	justify-content: flex-end;
}

.user-page .info .row.return {
	padding: 30px 0;
}

.user-page .info .row.return .col:nth-child(4) {
	display: flex;
	justify-content: flex-end;
}

.user-page .info .row:last-child {
	border: none;
}
/** USER PAGE */
/* HEADER VERSİON 2 */

/* HELP */

#help {
	letter-spacing: .03em;
}

#help .card {
	border: none;
	border-bottom: 1px solid #ddd;
	border-bottom: 1px solid var(--main-lightgrey);
}

#help .card:last-child {
	border: none;
}

#help h2 {
	padding: 15px 0;
}

#help .help-content h2 {
	color: #5a5a5a;
	color: var(--main-grey);
	padding: 0;
	font-size: 18px!important;
}

#help p {
	font-size: 14px!important;
}

#help b, strong {
	font-weight: 600;
}

#help svg {
	margin-right: 10px;
	color: #000;
	color: var(--quick-silver);
}

#help .help-content p,
#help .help-content tr {
	line-height: 1.6em;
}

#help .help-content a {
	color: #1cab81;
	color: var(--main-hover);
}

#help .help-content a:hover {
	color: #000;
	color: var(--black);
}

#help .help-content tr {
	height: 70px;
	line-height: 2em;
	border-bottom: 1px solid #ddd;
	border-bottom: 1px solid var(--main-lightgrey);
}

#help .help-content ul {
	padding-left: 40px;
}

#help .help-content ul li {
	list-style: disc;
	margin-bottom: 5px;
	line-height: 1.6;
}

/** HELP **/

.pagination {
	background: #fafafa;
	justify-content: center;
	margin-top: 10px;
	margin-bottom: 30px;
}

.pagination a {
	padding: 20px 15px;
	margin: 0 5px;
	font-weight: 500;
	font-size: 14px;
	color: #3e3e3e;
	position: relative;
	transition: 300ms;
}

.pagination li.disabled a {
	opacity: .3;
	cursor: default;
}
.pagination li.active a:after, .pagination li:not(.disabled):hover a:after {
	content: '';
	height: 1.5px;
	width: 60%;
	background: #3e3e3e;
	position: absolute;
	bottom: 12px;
	left: 20%;
}

.scl-mobile-filter, header .mobile-menu-button {
	display: none;
}
.icons { font-size: 25px;}
.ril__outer {
	background: #fff!important;
}
.ril__toolbar button:hover {
	background-color: #0000004d;
}

.ril__toolbar {
	background: transparent!important;
	height: 100%!important;
	width: 60px!important;
	right: 0!important;
	left: auto!important;
	justify-content: flex-end;
	flex-direction: column-reverse;
}
.ril__toolbarSide {
	height: auto!important;
}
.ril__toolbarItem {
	background-color: #0000004d;
	height: 60px;
	margin-bottom: 5px;
	display: inline-block!important;
}
.ril__toolbarRightSide{
	flex-direction: column-reverse;
	display: flex;
	padding: 0!important;
}
.ril__builtinButton {
	width: 100%!important;
	height: 60px!important;
}

.ril__navButtonNext, .ril__navButtonPrev {
	background-color: #00000080!important;
	opacity: 1!important;
}
.ril__navButtonNext:hover, .ril__navButtonPrev:hover {
	background-color: #00000090!important;
}

.ril__navButtons{
	z-index: 1
}

.poppins,
body {
	font-family: 'Poppins', sans-serif;
}

body {
	line-height: 1;
	font-size: 13px;
	overflow-x: hidden;
}

body.fixed-header {
	padding-top: 155px;
}

.container {
	padding: 0;
	width: calc(100% - 260px);
	max-width: 1460px;
}

header .user-menu span.notif {
	font-size: 10px;
}

header .user-menu svg {
	margin-right: 5px;
}

#detail .detail-right .info,
.user-page .btn.user-title-btn,
footer,
footer ul li,
header .user-menu .list-inline-item,
nav .breadcrumb {
	font-size: 12px;
}

#content .scl-sidebar h3.filter-title,
#detail .detail-content,
.user-panel,
footer h2,
header .top {
	font-size: 14px;
}

#content .scl-product-list .scl-product .price,
#detail .detail-content > ul > li,
#detail .detail-content p,
#detail .detail-right .scl-select-option ul li::before,
#detail .detail-right .share li,
h1,
h2,
h3,
h4,
header .head .search i {
	font-size: 16px;
}

#detail .detail-right .price,
{
	font-size: 18px;
}

#content .scl-product-list .scl-product .favorite,
#detail .detail-left .thump-slider .down,
#detail .detail-left .thump-slider .up,
.scl-modal .scl-modal-close {
	font-size: 24px;
}

svg {
	font-size: 1.6em;
}

a:hover {
	color: #000;
	color: initial;
}

a:not([href]){
	cursor: default;
}

#basketModal .basket-modal-close,
.order-ticket i,
header.basket .secure-pay svg {
	font-size: 2.2rem;
}

[disabled] {
	opacity: .7;
}

.bgw {
	background: white;
}

#detail .detail-left .thump-image::-webkit-scrollbar,
.detail-left::-webkit-scrollbar,
.detail-right::-webkit-scrollbar,
.modal-body::-webkit-scrollbar,
.hide-scroll::-webkit-scrollbar {
	display: none;
}

#detail .detail-left .thump-image,
.detail-left,
.detail-right,
.hide-scroll,
.modal-body {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.scl-scroll {
	height: 200px;
	overflow-x: hidden;
	overflow-y: scroll;
	padding-right: 15px;
	line-height: 2
}

.scl-scroll br {
	display: none
}

#sizeChartModal .product-chart::-webkit-scrollbar,
.quickModal #detail .detail-right::-webkit-scrollbar,
.scl-scroll::-webkit-scrollbar
{
	background: #eee;
	width: 5px;
	height: 5px;
	padding: 10px;
}

#sizeChartModal .product-chart::-webkit-scrollbar-thumb,
.quickModal #detail .detail-right::-webkit-scrollbar-thumb,
.scl-scroll::-webkit-scrollbar-thumb
{
	cursor: pointer;
	background: #6e6e6e;
}

.scl-scroll::-webkit-scrollbar-thumb:hover
{
	background: #3e3e3e;
	cursor: pointer!important;
}

#basketModal .content,
.scl-modal .modal-content {
	border: none;
}

h2.hr span {
	background: transparent;
	letter-spacing: 8px;
}

h2.hr::before {
	width: 20%;
	top: auto;
	bottom: 0;
	left: 50%;
	border-width: 1px;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	display: none;
}

span.hr {
	background: #ddd;
}

form .form-label {
	margin-left: 20px;
}

input[type=checkbox] + span {
	width: 20px;
	height: 20px;
}

input[type=checkbox] + span:after {
	font-size: 14px;
}

.scl-modal .scl-modal-close {
	width: 30px;
	height: 30px;
	line-height: 30px;
	background: #000;
	color: white;
	top: -15px;
	right: -15px;
	border: none;
	border-radius: 0;
}

#basketModal .basket-modal-close {
	border: none;
	border-radius: 0;
}

header {
	top: 0;
	z-index: 23;
}

header .top {
	width: 100%;
	height: 40px;
	line-height: 40px;
	background: #000;
	text-align: center;
	color: white;
}

header .top-left {
	position: absolute;
}

header .campaignSlider .campaignItem {
	height: 30px;
	color: #fff;
	position: absolute;
	top: -50px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	opacity: 0;
	text-transform: uppercase;
}

header .campaignSlider .campaignItem.active {
	opacity: 1;
	top: 0;
}

header .campaignSlider .campaignItem.next {
	top: -50px;
}

header .brands {
	float: left;
}

header .other-brand {
	padding: 0 10px;
	position: relative;
	transition: 300ms;
	color: #fff;
	font-size: 3em;
}

header .other-brand.active {
	background: #fff;
	color: #000;
}

header .other-brand svg {
	height: 20px;
	position: relative;
	top: -2.5px;
}

header .top-right {
	position: absolute;
	top: 0;
	right: 0;
	text-align: right;
	padding-left: 20px;
	z-index: 10;
}

header .top-select {
	float: right;
	margin-left: 20px;
	cursor: pointer;
	position: relative;
}

header .top-select ul {
	height: 0;
	background: #fff;
	color: #000;
	position: absolute;
	top: 30px;
	right: -5px;
	padding: 0 20px 0 10px;
	border-radius: 3px;
	text-align: left;
	border: 1px solid #e6e6e6;
	box-shadow: 0 1px 6px rgba(0,0,0,0.1);
	transition: 300ms opacity;
	opacity: 0;
	display: inline-block;
	overflow: hidden;
	z-index: 21;
}

header .top-select:hover ul {
	height: auto;
	opacity: 1;
}

header .top-select:hover ul li:hover {
	text-decoration: underline;
}

header .top-select > span {
	height: 36px;
	display: inline-block;
}

header .top-select > span > svg {
	margin-left: 5px;
	position: relative;
}

header .head {
	height: 72px;
}

header .head .container {
	height: auto;
}

header .head > .container {
	height: 100%;
	position: relative;
	z-index: 20;
	padding: 0;
}

header .head .logo {
	position: relative;
	left: unset;
	top: unset;
	margin-top: -5px;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	float: left;
	z-index: 21;
	width: auto;
	font-size: 5.5em;
}

header .head .logo img {
	height: 44px;
}

header.basket .logo {
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	font-size: 5.5em;
}

header.basket .secure-pay svg {
	margin: 0 10px;
	color: #aaa;
}

header .head .search {
	background: #fff;
	width: 100%;
	height: 72px;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	z-index: 22;
}

header .head .search.active {
	display: block;
}

header .head .search input {
	background: transparent;
	width: 50%;
	height: 40px;
	position: absolute;
	top: 16px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	padding: 0 55px 0 15px;
	color: #5e5e5e;
	border-bottom: 2px solid #d4d4d4!important;
	font-weight: 500;
	letter-spacing: 1px;
	text-align: center;
	transition: 300ms;
}

header .head .search svg {
	position: absolute;
	left: 75%;
	top: 12px;
	background: #d4d4d4;
	color: #4e4e4e;
	width: 44px;
	height: 44px;
	line-height: 44px;
	padding: 0 10px;
	z-index: 22;
	cursor: pointer;
}

header .head .search .close-search {
	width: 55px;
	background: transparent;
	left: 70%;
	line-height: 30px;
}

header .head .search input:focus + svg {
	color: #333;
}

header .search-complete {
	width: 100%;
	left: 0;
	top: 54px;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
	overflow: hidden;
	border: none;
	padding: 20px 25% 0;
}

header .search-complete .sugges {
	width: 35%;
	float: left;
	border: none;
}

header .search-complete .trends {
	width: 35%;
	float: left;
	border: none;
	padding-top: 20px;
}

header .search-complete .trends a {
	padding: 5px 10px;
	margin: 5px;
	background: #ededed;
	border-radius: 3px;
}

header .search-complete .products {
	width: 65%;
	float: right;
	display: flex;
	justify-content: start;
	align-items: start;
	flex-wrap: wrap;
}

header .search-complete .scl-product {
	width: 25%;
	padding: 0 2.5px;
	margin: 10px 0;
}

header .search-complete .scl-product span.title + span {
	padding-left: 0;
}

header .search-complete .scl-product:hover {
	color: #000;
	color: initial;
}

header .search-complete .btn {
	background: transparent;
	color: var(--main-color);
	margin: 15px 0 0;
	border-top: 1px solid var(--main-lightgrey);
}

header .user-menu {
	position: relative;
	right: 0;
	top: 16.5px;
	z-index: 22;
}

header .user-menu .list-inline-item {
	margin-left: 20px;
	padding: 10px 0;
	position: relative;
}

header .user-menu .list-inline-item:first-child .a::before,
header .user-menu .list-inline-item:first-child a::before {
	display: none;
}

header .user-menu span.notif {
	min-width: 15px;
	height: 15px;
	line-height: 16px;
	background: var(--main-hover);
	border-radius: 50%;
	text-align: center;
	position: absolute;
	left: 15px;
	top: -6px;
	color: #fff;
}

header .user-menu .login-user:hover {
	height: 50px;
}

header .user-menu .login-user ul {
	transition: 300ms opacity ease-in;
	border-radius: 2px;
	height: 0;
	padding: 0;
	overflow: hidden;
	opacity: 0;
}

header .user-menu .login-user:hover ul
{
	top: 45px;
	opacity: 1;
	height: auto;
	padding-bottom: 20px;
	overflow: visible;
	border: 1px solid var(--main-grey);
	padding: 5px 15px 20px;
	-webkit-transform: translateY(2px);
	        transform: translateY(2px);
}

header .user-menu .login-user ul::after,
header .user-menu .login-user ul::before {
	content: '';
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-bottom-color: var(--main-grey);
	display: block;
	position: absolute;
	top: -20px;
	right: 40px;
	z-index: 30;
}

header .user-menu .login-user ul::after {
	content: '';
	border-bottom-color: white;
	top: -19.5px;
}

header .user-menu .login-user ul a {
	text-align: left;
	display: block;
}

nav .breadcrumb {
	padding: 12px 0;
	background: #fff;
}

nav .breadcrumb .breadcrumb-item a, nav .breadcrumb .breadcrumb-item span {
	color: #959595;
	text-transform: uppercase;
}

.breadcrumb-item+.breadcrumb-item::before {
	color: #959595;
}

#main-menu {
	font-weight: 500;
	width: 100%;
	border-bottom: 2px solid #ddd;
	margin-bottom: 10px;
}

header.fixed + #main-menu {
	position: fixed;
	top: 70px;
}

#main-menu .container,
.banner .container {
	padding: 0;
}

.banner span {
	display: inline-block;
}

.banner a {
	color: var(--main-grey);
}

.banner a:not(.underline) {
	width: 100%;
}

.banner img {
	width: 100%;
	text-align: center;
}

.banner:not(.not) a {
	display: inline-block;
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
	position: relative;
}

.banner .underline::before {
	content: '';
	height: 1.5px;
	width: 110%;
	display: block;
	position: absolute;
	bottom: -5px;
	left: -5%;
	background: var(--main-grey);
}

.banner .underline {
	position: relative;
}

.banner .bold {
	font-weight: 600;
}

.banner h2 {
	font-family: 'Montserrat', sans-serif;
	display: block;
	text-align: center;
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: .1em;
	font-weight: 600;
	font-size: 15px;
	position: relative;
	margin-bottom: 20px;
}

.banner h2 span {
	padding: 0 70px;
	background: white;
	position: relative;
	z-index: 10;
}

.banner h2::before {
	content: '';
	height: 1px;
	width: 100%;
	background: #eee;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	z-index: 0;
}

#main-menu > .container > ul {
	height: auto;
	border: 0;
	margin: 0;
}

#main-menu > .container > ul > li > .main-title {
	height: 36px;
	line-height: 36px;
	padding: 0;
	color: var(--main-color);
	font-size: 15px;
	margin: -4px 15px -2px;
	text-transform: uppercase;
	letter-spacing: 1px;
	position: relative;
	transition: unset;
	text-align: center;
	white-space: nowrap;
	opacity: .9;
	display: inline-block;
	cursor: pointer;
}

#main-menu > .container > ul > li > .main-title.menu-red {
	background: #E81932;
	color: white;
	padding: 0 15px;
}

#main-menu > .container > ul > li > .main-title > span:nth-child(2) {
	opacity: 0
}

#main-menu > .container > ul > li > .main-title > span:nth-child(1) {
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}


#main-menu > .container > ul > li > .main-title > span:nth-child(2),
#main-menu > .container > ul > li:hover > .main-title {
	font-weight: 600;
	border-bottom: 2px solid #000;
}

#main-menu > .container > ul > li > a::after {
	display: none;
}

#main-menu > .container > ul > li > .main-title svg {
	display: none;
}

#main-menu .menu-children {
	padding: 15px 0;
	min-width: 200px;
	text-align: left;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
	top: 32px;
	left: calc(-100px + 50%);
	padding: 8px 15px;
	border: 1px solid #ccc;
	border-width: 0 2px 2px;
}

#main-menu .menu-children.big {
	width: 100%;
	position: absolute;
	left: 0;
	padding: 7.5px 0;
	border: none;
}

header.index:not(.fixed) #main-menu .menu-children {
	top: 70px;
}

#main-menu .menu-children:not(.big) .container,
#main-menu .menu-children:not(.big) .container .col-3 {
	width: 100%;
	border: none;
	margin: 0;
}

#main-menu .menu-children h3 {
	text-transform: uppercase;
	font-weight: 600;
	margin-bottom: 20px;
	font-size: 14px;
	color: #000;
	position: relative;
}

#main-menu .menu-children::before {
	content: '';
	background: #ddd;
	width: 100%;
	height: 1.5px;
	position: absolute;
	top: 37px;
	left: 0;
}

#main-menu .menu-children ul li a,
#main-menu .menu-children ul li span {
	display: block;
	margin-bottom: 10px;
	font-weight: 400;
	color: #000;
}

#main-menu .menu-children ul li a:hover,
#main-menu .menu-children ul li span:hover {
	text-decoration: underline;
	font-weight: 500;
}

#main-menu .menu-children ul li a::before {
	display: none;
}

header.fixed .head {
	background: #fff;
	position: fixed;
	top: 0;
	z-index: 20;
}

header.fixed .top {
	display: none;
}

.modal-open .fixed .container {
	padding-right: 17px!important;
}

.modal-open .fixed + #main-menu .container {
	padding-right: 17px!important;
}

.close-page-click,
.close-page-hover {
	background: transparent;
	top: 0;
}

.message-slider {
	border-width: 1px 0;
	padding: 20px;
	margin-bottom: 10px;
	text-align: center;
	border-bottom: 1px solid #ddd;
}

.carousel {
	top: 0;
	margin-bottom: 40px;
}

#content:not(.user-page) .scl-content .scl-content-title {
	background: #fafafa;
	padding: 10px;
	margin-bottom: 20px;
	top: 0;
}

#content:not(.user-page) .scl-content .scl-content-title.title-red {
	padding: 10px 20px;
	background: #E81932
}

#content:not(.user-page) .scl-content .scl-content-title.title-red h1, #content:not(.user-page) .scl-content .scl-content-title.title-red small {
	color: white;
}

#content .scl-content .scl-content-title .scl-content-sort {
	width: 180px;
	padding: 10px;
	background: white;
	border: 1px solid var(--main-lightgrey);
	top: 0;
}

#content .scl-content .scl-content-title .scl-content-sort ul {
	width: 100%;
	top: 40px;
	border-color: var(--main-lightgrey);
}

#content .scl-content .scl-content-title .scl-content-sort ul li span {
	padding: 8px 10px;
	color: var(--main-grey);
	margin: 2px 0;
	transition: 200ms;
}

#content .scl-content .scl-content-title .scl-content-sort ul li span:hover,
#content .scl-content .scl-content-title .scl-content-sort ul li.active span {
	background: var(--main-lightgrey);
	color: var(--main-grey);
}

#content .scl-product-list .scl-product .image {
	border: 1px solid #e6e6e6;
}

#content .scl-product-list .scl-product .title {
	text-transform: uppercase;
}

#content .scl-product-list .scl-product .hover-menu.quick {
	text-align: center;
}

#content .scl-product-list .scl-product .images {
	position: relative;
	height: calc((100vw - 260px) * .75 * .32 * 1.25);
}

#detail nav .breadcrumb {
	margin: 2% 0;
}

#detail .icon-scl-heart,
#detail .icon-scl-heart-full {
	font-size: 2.2rem;
	position: relative;
	top: -8px;
	cursor: pointer;
}

#detail .detail-left .thump-image {
	width: 90%;
	margin: 0;
}

#detail .detail-left .thump-image img {
	width: 100%;
}

#detail .detail-left .thump-image li.active::before {
	background: transparent;
	border: 1px solid var(--main-grey);
}

#detail .detail-left .thump-slider .down,
#detail .detail-left .thump-slider .up {
	position: absolute;
	top: -32px;
	left: 0;
	z-index: 1;
	background: white;
	width: 90%;
	height: 30px;
	line-height: 30px;
	text-align: center;
	cursor: pointer;
	opacity: 0;
}

#detail .detail-left .thump-slider .down {
	top: auto;
	bottom: -33px;
}

#detail .detail-left .thump-slider:hover .down,
#detail .detail-left .thump-slider:hover .up {
	opacity: 1;
}

#detail .detail-right .info {
	margin: 7px 0;
}

#detail .detail-right .info strong {
	display: inline-block;
	font-weight: 500;
	margin-right: 5px;
}


#detail .detail-right .sizes {
	margin: 0 0 15px;
}

#detail .detail-right .description {
	text-align: justify;
}

#detail .detail-right .scl-select-option {
	margin: 15px 0 0;
}

#detail .detail-right .go-basket button.active {
	background: var(--main-color);
	border-color: var(--main-color);
	color: #fff;
}

#detail .detail-content {
	margin: 50px 0;
	width: 100%;
	color: #4e4e4e;
	float: left;
}

#detail .detail-content > ul {
	text-align: center;
	border-bottom: 1px solid #ddd;
}

#detail .detail-content > ul > li {
	padding: 20px 0;
	margin: 0 40px;
	cursor: pointer;
	font-weight: 500;
}

#detail .detail-content .prod-tabs {
	width: 80%;
	margin: 0 auto;
}

#detail .detail-content > ul > li.active {
	color: #000;
	border-bottom: 3px solid #000;
	margin-bottom: -1px;
}

#detail .detail-content .tab-content {
	display: none;
	opacity: 0;
	transition: 300ms;
	line-height: 2;
	margin-top: 50px;
}

#detail .detail-content .tab-content.active {
	display: block;
	opacity: 1;
}

#detail .detail-content .tab-content strong {
	display: inline-block;
	margin-bottom: 5px;
}

#detail .detail-content .tab-content ul li {
	margin-left: 10px;
}

#detail .detail-content .tab-content .col {
	margin-bottom: 40px;
}

#content .scl-sidebar h3.filter-title {
	padding-bottom: 8px;
	margin-top: 30px;
	font-weight: 600;
	cursor: pointer;
}

#content .scl-sidebar h3.filter-title svg {
	display: none;
}

#content .scl-sidebar h3.filter-title:not(.active) svg:nth-child(2) {
	display: inline-block;
}

#content .scl-sidebar h3.filter-title.active svg:nth-child(1) {
	display: inline-block;
}

#content .scl-content h1 {
	font-weight: 600;
	color: var(--main-grey);
	text-align: left;
	margin: 0;
	font-size: 20px;
	vertical-align: middle;
}

#content .scl-content h1 small {
	font-weight: 300;
	font-size: .7rem;
	color: #000;
	margin-left: 5px;
	display: inline-block;
	position: relative;
	top: -1px;
}

#content .scl-sidebar h2 {
	font-size: 18px;
	font-weight: 500;
	text-transform: uppercase;
	padding-top: 10px;
}

#content .scl-content .description {
	font-size: 1em;
	border: 1px solid #ddd;
	padding: 20px 20px 0;
	margin: 10px;
}

#content .scl-content h3 + p {
	padding-bottom: 25px;
}

#content .scl-sidebar h2 + h3.filter-title {
	margin-top: 20px;
}

#content .scl-sidebar ul.filter-list {
	padding-bottom: 20px;
	display: none;
}

input[type=checkbox] + span::after {
	color: var(--main-color);
}

#content .scl-sidebar ul.filter-list li:hover {
	border-color: #aaa;
}

#content .scl-sidebar ul.filter-list li.active {
	border-color: #666;
}

.scl-sidebar .user-panel li a {
	margin: 0;
	padding: 10px 0;
	border: none;
}

.scl-sidebar .user-panel li.active a {
	font-size: 15px;
}

.scl-sidebar .user-panel li:last-child a {
	border: none;
}

#content.user-page .container {
	width: 80%;
}


.scl-modal .modal-content h2 {
	padding: 20px 0;
	margin: 0;
	font-size: 1.5em;
}

.scl-modal .modal-content h2:after {
	display: none;
}

#help .card {
	border-color: #ddd;
}

footer {
	background: #f9f9f9;
	border-top: 1px solid #ddd;
	padding-bottom: 0;
}

footer .container {
	padding: 30px 50px 20px;
}

footer h2 {
	min-height: 25px;
	font-weight: 600;
	color: var(--main-color);
	text-align: left;
	margin: 0;
	padding: 10px 0;
	border-bottom: 1px solid var(--main-lightgrey);
}

footer ul {
	border: none;
	text-align: left;
}

footer,
footer a {
	color: #707070;
}

footer ul li {
	border: none;
	padding: 0 0 15px;
}

footer ul.help {
	margin-top: 50px;
	text-align: center;
}

footer ul.help li {
	font-size: 1.1em;
	text-align: center;
	padding: 5px 15px;
}

footer ul.help li {
	border-right: 1px solid #ddd;
}

footer ul.help li:last-child {
	border-right: none;
}

footer .social a {
	color: var(--main-grey);
	margin: 5px 25px 5px 0;
	font-size: 1.4rem;
}

footer .subscribe form {
	position: relative;
	border: 1px solid var(--main-lightgrey);
	margin-bottom: 1.2em!important
}

footer .subscribe .mb-3 {
	margin-bottom: 0!important
}

footer .subscribe input {
	max-width: 70%;
	border: none;
}

footer .subscribe label {
	position: relative;
	padding-left: 35px;
	margin: 0 5px;
	width: 100%;
	text-align: left;
	display: block;
	float: left;
}

footer .subscribe button {
	position: absolute;
	right: 0;
	top: 0;
	width: 30%;
	padding: 20px 0;
	font-weight: 600;
	background: transparent;
	color: var(--main-grey);
	border: none;
	border-left: 1px solid var(--main-lightgrey);
	cursor: pointer;
}

.go-head {
	width: 45px;
	height: 45px;
	line-height: 44px;
	padding-left: .5px;
	background: var(--main-color);
	color: var(--white);
	font-size: 2.6em;
	border-radius: 2px;
}

.go-head svg {
	position: relative;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.swal2-modal {
	border: 1px solid #000!important;
}

.swal2-modal .swal2-title {
	font-size: 1.1em!important;
	color: #000!important;
	font-weight: 400;
}

.swal2-modal .swal2-styled.swal2-confirm {
	background: transparent!important;
	color: #000!important;
	border: 1px solid #000!important;
	padding: 1em 2em;
	font-size: 1em!important;
}

.swal2-modal .swal2-styled.swal2-deny {
	background: #000!important;
	padding: 1em 2em;
	font-size: 1em!important;
}

.swal2-modal .swal2-styled.swal2-confirm:hover,
.swal2-modal .swal2-styled.swal2-deny:hover {
	opacity: .9;
}

.swal2-modal .swal2-styled.swal2-cancel {
	background: #000!important;
	padding: 1em 2em;
	font-size: 1em!important;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
	color: #000!important;
	background-color: #000!important;
}

.swal2-icon.swal2-success .swal2-success-ring {
	border-color: rgba(0,0,0, .2)!important;
}

.react-slideshow-wrapper .images-wrap > div {
	position: relative;
	z-index: 1;
}

.swiper-container {
	padding-bottom: 50px!important;
}

.swiper-container-horizontal>.swiper-pagination-bullets {
	background: #ffffff;
	padding: 5px 15px;
	width: 100%!important;
	left: 50%!important;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	border-radius: 10px;
}

.swiper-button-next, .swiper-button-prev {
	background: white!important;
	color: #3e3e3e!important;
	left: 0!important;
	width: 54px!important;
	height: 54px!important;
	opacity: 0!important;
	transition: 600ms;
}

.swiper-button-next {
	left: auto!important;
	right: 0!important;
}

.swiper-container:hover .swiper-button-next, .swiper-container:hover .swiper-button-prev {
	opacity: 1!important;
}

.swiper-container:hover .swiper-button-next.swiper-button-disabled, .swiper-container:hover .swiper-button-prev.swiper-button-disabled {
	opacity: 0!important;
}

.swiper-button-next:after, .swiper-button-prev:after {
	font-size: 25px!important;
}

.swiper-lazy-preloader {
	border-color: #aaa!important;
	border-top-color: #ddd!important;
	z-index: -1!important;
}

.homeFullSlider {
	height: calc(100vw / 2.83);
}

.loading {
	width: 100%;
	max-height: 150px;
	text-align: center;
}
.loading img {
	display: inline-block;
	max-height: 150px;
}

.loading.fix {
	width: 100%;
	height: 100%;
	max-height: 100%;
	text-align: center;
	position: absolute;
	background: #eee;
	top: 0;
	left: 0;
}

.loading.fix .loader {
	position: absolute;
	left: 50%;
	top: 50%;
	margin: 0;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%)
}

.loader span,
.loader span:after {
	border-radius: 50%;
	width: 6em;
	height: 6em;
}

.loader span {
	margin: 60px auto;
	font-size: 5px;
	position: relative;
	border-top: .7em solid rgba(0, 0, 0, 0.2);
	border-right: .7em solid rgba(0, 0, 0, 0.2);
	border-bottom: .7em solid rgba(0, 0, 0, 0.2);
	border-left: .7em solid rgba(0, 0, 0, 0.5);
	-webkit-animation: load 1.1s infinite linear;
	animation: load 1.1s infinite linear;
	display: block;
}

@-webkit-keyframes load {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes load {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.pdf-schema {
	line-height: 1.5;
	text-align: left;
}

.pdf-schema h1 {
	text-align: center;
	background: rgba(23,70,200, .2);
	padding: 10px 0;
	margin: 0 0 -20px;
	color: #000;
	position: relative;
	top: -30px
}

.pdf-schema h2 {
	padding: 10px 0;
	font-size: 1.3em;
}

.pdf-schema h3 {
	color: #3a3a3a;
}

.pdf-schema p {
	line-height: 1.5rem;
	padding-left: 2em;
	margin: 2em 0;
}

.pdf-schema h2 + p, .pdf-schema h3 + p, .pdf-schema h1 + p {
	margin: 0 0 2em;
	padding-left: 0;
}

.pdf-schema li p {
	margin: .5em 0 2em;
	padding-left: 0;
}

.pdf-schema ul {
	padding-left: 3em;
	margin-top: 1em;
	margin-bottom: 2em;
}

.pdf-schema li {
	list-style: disc outside none;
	list-style: initial;
	line-height: 1.5;
	margin-bottom: .5em;
}

.pdf-schema table {
	margin-bottom: 2em;
}
.pdf-schema table tr, .pdf-schema table th, .pdf-schema table td {
	border: 1px solid #ccc;
	padding: 10px;
}

@media only screen and (min-width: 1720px) {

	header .search-complete .scl-product .images, header .search-complete img {
		height: calc(1460px * .5 * .65 * .25 * 1.25)
	}

	#content .scl-product-list .scl-product .images {
		height: calc(1460px * .75 * .32 * 1.25);
	}

	#detail .detail-left .big-image {
		height: calc(1460px * .48 * .8 * 1.25)
	}

	#detail .detail-left .thump-slider .thump-image li {
		height: calc(1460px * .48 * .2 * .9 * 1.25)
	}

	#content ._404 + .scl-product-list .scl-product .images {
		height: calc(1460px * .7 * .18 * 1.25)
	}

	.last-enter .lse-product-list .lse-item .img {
		height: calc(1460px * .1166 * 1.25)
	}
}

@media only screen and (max-width:960px) {
	.modal-open {
		padding-right: 0!important;
	}

	.onne {
		display: none
	}

	iframe {
		height: calc(100vw * 0.56)
	}

	header {
		margin-bottom: 10px;
	}

	header:not(.basket) .head {
		border-bottom: 2px solid rgba(193,193,193,.5);
	}

	header .mobile-menu-button {
		display: inline-block;
	}

	header .mobile-menu-button svg:nth-child(2) {
		display: none;
	}

	header .mobile-menu-button .active svg:nth-child(1) {
		display: none;
	}

	header .mobile-menu-button .active svg:nth-child(2) {
		display: inline-block;
		padding-top: 2px;
	}

	.container {
		width: calc(100% - 30px)!important;
	}

	header .campaignSlider .campaignItem {
		left: 0;
		-webkit-transform: unset;
		        transform: unset;
	}

	header .user-menu {
		-webkit-transform: unset;
		        transform: unset;
		top: 10px;
	}

	header .user-menu > ul > li > a, header .user-menu > ul > li > .a {
		font-size: 0;
	}

	header .user-menu > ul > li > a svg, header .user-menu > ul > li > .a svg {
		font-size: 30px;
	}

	header .user-menu > ul > .login-user > .a svg:nth-child(2) {
		display: none;
	}

	header .user-menu > ul > li:not(:first-child):before {
		display: none
	}

	header .user-menu span.notif {
		right: -10px;
		left: auto;
		top: -5px;
		width: 20px;
		height: 20px;
		line-height: 20px;
		font-size: 13px;
	}

	header .user-menu .login-user ul {
		display: block;
		position: fixed;
		width: 70%!important;
		height: 100%!important;
		max-width: 100%!important;
		right: -100%;
		top: 125px!important;
		border: none;
		padding-left: 10px!important;
		padding-bottom: 125px!important;
		border: none!important;
		opacity: 1;
		z-index: 22;
	}

	header .user-menu .login-user:hover ul {
		padding: 0 0 0 10px!important;
	}

	header .user-menu .login-user ul li {
		font-size: 25px;
		margin: 5px 0;
	}

	header .user-menu .login-user ul span.hr {
		margin: 20px;
	}

	header .user-menu .login-user ul::before, header .user-menu .login-user ul::after {
		display: none;
	}

	header .search-complete {
		position: fixed;
		top: 130px;
		padding: 10px 20px;
		overflow: scroll;
		height: 100%;
		padding-bottom: 125px;
		z-index: 23;
	}

	header .head .search {
		left: 0;
		-webkit-transform: unset;
		        transform: unset;
	}

	header .search-complete .products {
		width: 100%;
	}

	header .search-complete .scl-product {
		width: 33%;
	}

	header .search-complete .scl-product .images, header .search-complete img {
		height: calc(((100vw - 40px) * .33 - 5px) * 1.25);
	}

	header .search-complete .scl-product span:not(.old) {
		width: 100%;
	}

	header .search-complete .sugges {
		width: 50%;
		margin-top: 50px;
		font-size: 18px;
	}
	header .search-complete .trends {
		width: 50%;
		margin-top: 30px;
		font-size: 18px;
	}

	header .search-complete .btn {
		padding: 15px;
	}

	#main-menu {
		position: fixed;
		width: 70%;
		height: 100%;
		top: 112px;
		left: -70%;
		box-shadow: 0 10px 10px rgba(0,0,0, .1);
		font-size: 16px;
		border-left: 1px solid rgba(0,0,0, .1);
		overflow: scroll;
		padding-bottom: 125px;
		z-index: 24;
	}

	#main-menu > .container > ul {
		padding-bottom: 100px;
		text-align: left;
	}

	#main-menu > .container > ul > li {
		display: block;
	}

	#main-menu > .container > ul > li > .main-title {
		display: block;
		font-size: 18px;
		border-bottom: 1px solid #ccc!important;
		height: 70px;
		line-height: 70px;
		margin: 0;
	}

	#main-menu > .container > ul > li:hover > .main-title {
		height: 70px;
		border-bottom: 1px solid #ccc!important;
		font-weight: unset;
	}

	#main-menu > .container > ul > li > .main-title > span:nth-child(1) {
		left: 0;
		-webkit-transform: unset;
		        transform: unset;
		width: 100%;
		text-align: left;
	}

	#main-menu > .container > ul > li > .main-title > span:nth-child(2) {
		display: none;
	}

	#main-menu > .container > ul > li > .main-title svg {
		float: right;
		position: relative;
		top: 15px;
		display: none;
	}

	#main-menu > .container > ul > li.active > .main-title  svg:nth-child(2) {
		display: inline-block;
	}

	#main-menu > .container > ul > li:not(.active) > .main-title svg:nth-child(1) {
		display: inline-block;
	}

	#main-menu > .container > ul > li.two .menu-children {
		width: 100%;
	}

	#main-menu > .container > ul > li.notTitle .menu-children {
		padding-top: 0;
	}

	#main-menu .menu-children::before {
		display: none;
	}

	#main-menu .menu-children h3, #main-menu .menu-children ul li a, #main-menu .menu-children ul li span {
		font-size: 20px;
		margin: 10px 5px;
		padding: 5px 0;
	}

	#main-menu .menu-children h3 a, #main-menu .menu-children h3, #main-menu .menu-children ul li a, #main-menu .menu-children ul li span {
		color: currentColor;
		display: block;
	}

	#main-menu .menu-children.big, #main-menu .menu-children {
		position: relative;
		top: 0;
		left: 0;
		opacity: 1;
		display: block!important;
		box-shadow: none;
		padding: 0;
		overflow: hidden;
		height: 0;
		border: none
	}

	#main-menu .menu-children .col-3, #main-menu .menu-children .col {
		width: 100%;
		flex: 0 0 auto;
	}

	#main-menu>.container > ul > li.two .menu-children {
		left: 0
	}

	#content {
		padding: 0;
	}

	#content .scl-sidebar {
		position: fixed;
		width: 70%!important;
		height: 100%!important;
		top: 0px;
		background: #fff;
		padding: 50px;
		left: -100%;
		z-index: 23;
		display: block;
		opacity: 1;
		float: unset;
		padding-bottom: 125px;
		transition: unset;
	}

	#content .scl-content {
		width: 100%;
		position: relative;
	}

	#content .scl-content.list-page {
		padding-top: 50px;
	}

	#content .scl-content.list-page h1 {
		position: absolute;
		top: 0;
		left: 0;
	}

	#content:not(.user-page) .scl-content .scl-content-title {
		padding: 0;
		margin-left: -15px;
		width: calc(100% + 30px);
	}

	#content .scl-content .scl-content-title .scl-content-sort {
		width: 50%;
		padding: 15px 10px;
		text-align: center;
		border-right: 0;
	}

	#content .scl-content .scl-content-title .scl-mobile-filter {
		float: left;
		width: 50%;
		padding: 15px 10px;
		background: white;
		border: 1px solid var(--main-lightgrey);
		border-left: 0;
		top: 0;
		text-align: center;
		display: block;
		cursor: pointer;
	}

	#content .scl-content .scl-content-title .scl-content-sort svg {
		position: relative;
		top: 0;
		right: -5px;
	}

	#content .scl-content .scl-content-title .scl-content-sort ul li span {
		padding: 18px 10px;
	}

	#content .scl-product-list .scl-product .title {
		margin-top: 60px;
	}

	#content .scl-product-list .scl-product .images {
		height: calc((100vw - 30px) * .3233 * 1.25)
	}

	#content .scl-product-list .scl-product .hover-menu.quick {
		opacity: .6;
		display: block;
		padding: 0;
		margin: 0;
		width: 100%;
	}

	#content .scl-product-list .scl-product .colors-num {
		bottom: 130px;
	}

	#content .scl-product-list .scl-product .hover-menu.quick button.quick {
		width: 100%;
	}

	#content .scl-product-list .scl-product .price .old {
		font-size: .9em;
	}

	#content ._404 {
		padding-top: 40px;
	}

	#content ._404 + .scl-product-list {
		padding: 0 5%;
	}

	#content ._404 + .scl-product-list .scl-product .title {
		margin-top: 0;
	}
	#content ._404 + .scl-product-list .scl-product .colors-num {
		bottom: 60px;
	}

	#content.malls .scl-sidebar {
		display: block;
		width: 100%!important;
		float: left;
		left: 0;
		padding: 0 0 20px;
		position: unset;
	}

	#content.malls .scl-sidebar .mb-3 {
		width: 50%;
	}

	#content.malls .row:first-child {
		margin-top: -20px
	}

	#content.malls .row>* {
		margin-top: 20px
	}

	.user-page .btn.user-title-btn {
		padding: 3px;
	}

	.user-page .btn.user-title-btn svg {
		font-size: 20px;
	}

	.user-page .list .row>*:nth-child(1) {
		flex: 1 0 auto;
	}

	.user-page .list .row>* {
		flex: 2 0 auto;
		padding: 0 10px 0 0
	}

	.user-page .list .row > .msg {
		display: none
	}

	.user-page .list .edit span:nth-child(1) {
		color: var(--main-green)
	}

	.user-page .scl-section-content .row > .col {
		width: 100%;
		flex: 0 0 auto;
		padding: 0 20px!important;
	}

	.user-page .scl-section-content .form-btn {
		margin: 10px!important
	}

	.user-page .scl-section-content.pr-5 {

	}

	header.basket {
		margin-bottom: 0;
	}

	#basket .container {
		width: calc(100% - 40px)!important;
		padding-top: 0;
	}

	#basket .container .basket-product-list {
		width: 100%;
		overflow: hidden;
	}

	#basket .basket-info {
		top: 0;
	}

	footer ul.help li {
		margin: 20px 2%;
		border: none;
	}

	footer .container .row:first-child .col-6 {
		width: 40%;
	}

	footer .container .row:first-child .col-5 {
		width: 60%;
	}

	footer .container .row:first-child .col-6 + .col {
		display: none;
	}

	.close-page-hover, .close-page-click {
		z-index: 10;
	}

	.full-banner h1, .full-banner span {
		max-width: 100%;
		width: 80%;
	}

	.full-banner h1 {
		top: 20px
	}

	.full-banner span {
		top: 70px
	}

	.scl-modal {
		 width: 100%!important;
		 max-width: 100%!important;
	 }

	#loginModal .scl-modal {
		width: 500px!important;
	}

 	.scl-modal .scl-modal-close {
 		right: 0;
 		top: 0;
 		width: 40px;
 		height: 40px;
 		line-height: 40px
 	}

	.react-slideshow-container+ul.indicators {
		background: #fff;
		position: absolute;
		left: 50%;
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
		bottom: 0;
		padding: 5px 10px 3px;
		border-radius: 3px;
	}
	.react-slideshow-container+ul.indicators li {
		width: 10px;
		height: 10px;
		background: #ccc;
		overflow: hidden;
		margin: 0 5px!important;
		border-radius: 50%;
	}

	.react-slideshow-container+ul.indicators li.active {
		background: #5e5e5e;
	}

	#detail .detail-left .big-image{
		height: calc((100vw - 30px) * .48 * .8 * 1.25);
	}

	#detail .detail-left .thump-slider .thump-image li {
		height: calc((100vw - 30px) * .48 * .9 * .2 * 1.25)
	}

	.quickModal #detail .detail-left .thump-slider .thump-image li {
		height: calc(100% * .48 * .9 * .2 * 1.25);
	}

	.combimeModal .combine-products {
		width: 100%;
		height: auto;
		border-bottom: 1px solid rgba(0, 0, 0, 1);
		padding-bottom: 5px;
		display: flex;
		flex-wrap: nowrap;
	}

	.combimeModal .scl-modal .modal-content {
		margin-top: -35px!important;
		max-height: 100vh;
	}

	.combimeModal .combine-products::after {
		background: transparent;
		color: black;
		font-size: 14px;
		font-weight: 500;
		line-height: 48px;
	}
	.combimeModal .scl-modal .scl-modal-close {
		background: transparent;
		color: black;
		font-size: 28px;
		line-height: 44px;
		font-family: serif;
		font-family: initial;
	}

	.combimeModal .combine-products {
		margin-top: 45px;
		padding-top: 8px;
		border: 1px solid #ddd;
	}

	.combimeModal .combine-products span {
		width: 17%;
		display: inline-block;
		margin-right: 2%;
	}

	.combimeModal #detail {
		width: 100%;
		padding-bottom: 100px;
	}

	.combimeModal .combine-products::after {
		width: 100%;
	}

	.combimeModal #detail .detail-right {
		overflow: visible;
		height: auto;
		max-height: none;
		padding-right: 0;
	}

	.combimeModal #detail .detail-left .big-image {
		height: calc((100vw - 30px) * 1.25);
	}

	.scl-product.combine:hover .combine-list {
		opacity: 0;
	}

	#content ._404 + .scl-product-list .scl-product .images {
		height: calc((100vw - 30px) * .9 * .185 * 1.25)
	}

	.ril__toolbar {
		width: 45px!important;
	}

	.ril__toolbarItem {
		line-height: 40px!important;
		height: 40px!important;
		width: 40px;
	}

	.ril__builtinButton {
		width: 40px!important;
		height: 40px!important;
	}

	.ril__navButtons {
		top: 135px!important;
		right: 5px!important;
		left: auto!important;
		margin: 0!important;
		padding: 0!important;
		height: 40px!important;
		width: 40px!important;
	background-size: 15px!important;
	}
	.ril__navButtonPrev {
		top: 180px!important;
	}
	.ril__toolbarLeftSide{
		opacity: 0
	}
}

@media only screen and (max-width:768px) {

}

@media only screen and (max-width:640px) {

}

@media only screen and (max-width:540px) {

	.category .col {
		width: 50%;
		flex: 0 0 auto;
	}

	header .campaignSlider  {
		display: none;
	}

	header {
		height: 126px;
		z-index: 19;
	}

	header .head {
		height: 46px;
	}

	header .head .logo {
		margin-top: -18px;
	}

	header .other-brand {
		height: 41px;
	}

	header .head .logo svg {font-size: 1.2em}

	header .head .search {
		width: calc(100% + 30px - 115px);
		left: calc(-15px + 115px);
		top: 40px;
		-webkit-transform: none;
		        transform: none;
		height: 45px;
		display: block;
	}

	header .head .search input {
		width: calc(100% - 30px)!important;
		height: 40px;
		top: 7px;
		border-bottom: 3px solid #000!important;
		-webkit-transform: unset;
		        transform: unset;
		left: 15px;
		line-height: 1;
		padding: 0 0 0 40px;
		text-align: left;
	}

	header .head .search svg {
		width: 20px;
		height: 20px;
		line-height: 30px;
		left: 6%;
		top: 50%;
		-webkit-transform: translateY(-50%);
		        transform: translateY(-50%);
		text-align: center;
		background: unset;
		padding: 0;
	}

	header .head .search .close-search {
		left: 78%;
	}

	header:not(.basket) .head {
		border-bottom: none;
	}

	header .search-complete .products {
		width: 100%;
		float: unset
	}

	header .search-complete .scl-product {
		width: 50%;
	}

	header .search-complete .sugges {
		width: 100%;
		margin-top: 30px;
	}

	header .search-complete .trends {
		width: 100%;
		font-size: 16px;
		margin-bottom: 15px;
	}

	header .search-complete .trends a {
		padding: 10px 15px;
		margin: 8px 10px;
	}

	header .search-complete .scl-product .images, header .search-complete img {
		height: calc(((100vw - 40px) * .5 - 5px) * 1.25);
	}

	header .user-menu {
		top: 2px;
		position: unset;
	}

	header .user-menu .list-inline-item {
		margin-left: 15px;
	}

	header .user-menu .list-inline-item:first-child {
		display: none;
	}

	header .user-menu .login-user {
		width: 30px;
		height: auto!important;
	}

	header .user-menu .login-user:active .a.user-icon {
		border-color: var(--main-green);
	}

	header .user-menu .a.user-icon {
		background: #f0f0f0;
		width: 28px;
		height: 28px;
		line-height: 26px;
		display: block;
		font-size: 20px;
		color: #555;
		border-radius: 50%;
		text-align: center;
		position: absolute;
		border: 1px dashed #ddd;
		top: 6px;
		left: -2px;
		transition: 500ms;
	}

	header .user-menu .login-user ul li {
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 500;
		margin: 0;
	}

	header .user-menu .login-user ul li a {
		height: 50px!important;
		line-height: 30px;
	}

	header .user-menu > ul > li > a svg,
	header .user-menu > ul > li > .a svg {
		font-size: 20px;
	}

	header .user-menu span.notif {
		width: 16px;
		height: 16px;
		line-height: 16px;
		right: -8px;
		top: 0;
		font-size: 12px;
	}

	header .user-menu .login-user ul  {
		top: 0px!important;
		z-index: 900;
		-webkit-transform: unset!important;
		        transform: unset!important;
		width: 80%!important;
		overflow-y: scroll!important;

	}

	header .mobile-menu-button {
		position: absolute!important;
		top: 50px;
		left: -15px;
		z-index: 100;
		padding: 8px 15px!important;
		background: black;
		color: #fff;
		font-size: 20px!important;
		align-items: flex-start;
	}

	header .mobile-menu-button .a {
		font-size: 15px!important;
	}

	header .mobile-menu-button svg {
		margin-right: 10px;
		font-size: 19px!important;
	}

	#main-menu {
		width: 80%;
		height: 100%;
		top: 0px;
		left: -100%;
	}

	#main-menu > .container > ul > li > .main-title {
		font-size: 13px;
		height: 50px!important;
		line-height: 50px;
		margin-bottom: 0!important;
	}

	#main-menu > .container > ul > li > .main-title.menu-red {
		margin: 0 -15px;
		padding: 0 15px!important;
		overflow: hidden;
	}

	#main-menu > .container > ul > li > .main-title.menu-red span {
		left: 15px;
	}

	#content:not(.user-page) .scl-content .scl-content-title.title-red {
		background: white;
		padding: 0;
	}

	#content:not(.user-page) .scl-content .scl-content-title.title-red h1 {
		color: #E81932
	}

	#main-menu .menu-children h3,
	#main-menu .menu-children ul li a,
	#main-menu .menu-children ul li span {
		font-size: 14px;
	}

	nav[aria-label=breadcrumb] .container {
		overflow: hidden;
	}

	nav[aria-label=breadcrumb] .container ol {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: inline-block;
		padding: 12px 0;
		margin-bottom: 0
	}

	nav[aria-label=breadcrumb] .container ol li {
		display: inline-block;
	}

	nav .breadcrumb-item+.breadcrumb-item::before {
		top: -1px;
	}

	#content .scl-sidebar {
		padding: 20px;
	}

	#content .scl-product-list .scl-product .images {
		height: calc((100vw - 30px) * .49 * 1.25)
	}

	#content .scl-product-list .scl-product .hover-menu.quick {
		display: none;
	}

	#content .scl-product-list .scl-product .favorite {
		top: 5px;
		right: 5px;
		font-size: 18px;
	}

	#content .scl-product-list.two-column .scl-product {
		margin-bottom: 2rem;
		width: 48.5%;
	}

	#content .scl-product-list.two-column .scl-product._2, #content .scl-product-list.two-column .scl-product._3 {
		width: 100%;
	}

	#content .scl-product-list .scl-product .colors-num {
		bottom: 70px;
	}

	#content .scl-product-list .scl-product .title {
		margin-top: 0;
	}

	#content.malls .scl-sidebar .mb-3 {
		width: 100%;
	}

	.scl-product.combine .combine-product .image {
		width: 40px;
	}

	.scl-product.combine .combine-product span {
		font-size: 12px;
		padding-left: 45px;
	}

	#content .scl-product-list .scl-product.combine .look-modal {
		width: 100%;
	}

	.user-page .info {
		padding: 0 20px;
	}

	.user-page .basket-empty {
		position: unset;
		top: unset;
		left: unset;
		-webkit-transform: unset;
		        transform: unset;
		margin: 3em 0;
	}

	#detail {
		padding-bottom: 25px;
	}

	#detail + footer {
		margin: 0;
		width: 100%;
		overflow: hidden;
	}

	#detail .detail-right, #detail .detail-left {
		width: 100%!important;
		margin: 0;
	}

	#detail .detail-right {
		padding-top: 20px;
		padding-bottom: 0;
	}

	#detail .detail-right .services {
		margin-top: -5px;
		margin-left: -10px;
		width: 100%
	}

	#detail .detail-right .services ul li {
		font-size: .9em;
		width: 50%!important;
	}

	#detail .detail-right .services ul li svg {
		font-size: 5em;
	}

	#detail .detail-right .services ul li span:nth-child(2){
		margin: 12px 0 8px 0px;
		font-size: 12px;
	}






	#detail .detail-right .go-basket .col-2:last-child {
		left: 8px;
		width: 10%;
		bottom: 18px;
		font-size: .85em;
	}



	#detail .detail-right .ipricee {
		background: #fff;
		height: 68px;
		position: fixed;
		left: 0;
		bottom: 0;
		margin: 0;
		padding-left: 55px!important;
		font-size: 0.93rem;
		border-top: 2px solid #ccc;
		z-index: 15;
		text-align: center;
		justify-content: left !important;
		display: flex !important;
		padding-top:11px;
		padding-bottom:20px;

	}

	#detail .detail-right .pricee {
		background: #fff;
		height: 68px;
		position: fixed;
		left: 0;
		bottom: 0;
		margin: 0;
		padding-left: 10px!important;
		font-size: 1.4rem;
		border-top: 2px solid #ccc;
		display: flex;
		flex-direction: column;
		justify-content: center;
		z-index: 15;
		text-align: center;
	}

	#detail .detail-right .social {
		height: auto;
		padding: 0;
	}

	#detail .detail-right .social > ul {
		margin-bottom: 25px;
		float: left
	}

	#detail .detail-right .social > ul li {
		width: 48%!important;
		margin: 5px 1%!important;
	}

	#detail .detail-right .social ul li  {
		height: 56px;
		text-align: left;
		white-space: nowrap;
		word-wrap: normal;
	}

	#detail .detail-right .social ul li svg {
		width: auto;
		margin-bottom: 5px;
		margin-right: 7px;
	}

	#detail .detail-right .social ul li.friend svg,
	#detail .detail-right .social ul li.phone svg {
		margin-bottom: 8px;
	}

	#detail .detail-right .social ul li:last-child span {
		padding-top: 3px;
		display: inline-block;
	}

	#detail .detail-right .social .share-window {
		bottom: -160px;
		left: 20px;
	}

	#detail .detail-right .scl-select-option ul li:first-child {
		margin-left: 0;
	}

	#detail .detail-left, #detail .detail-left .big-image {
		width: 100%;
		height: calc((100vw - 30px) * 1.25);
		margin-bottom: 25px;
	}

	#detail .detail-left .big-image .swiper-container {
		height: calc((100vw - 30px) * 1.25 + 45px);
	}

	#detail .detail-left .thump-slider {
		display: none;
	}

	.combimeModal .scl-modal .modal-content {
		margin-top: 0!important;
	}

	.swiper-container {
		padding-bottom: 50px!important;
	}

	.complete-dress .product-list .item, .complete-dress .product-list .item a {
		width: 100%;
	}

	.complete-dress .product-list .swiper-slide{
		width: calc((100vw - 30px))!important;
	}

	.complete-dress .product-list .item {
		width: calc((100vw - 40px) * .5)!important;
		float: left;
	}

	.complete-dress .product-list .item .img {
		height: calc((100vw - 40px) * .5 * 1.25 );
		position: relative;
	}

	.add-basket-product .img {
		width: 30%;
	}

	.complete-dress .swiper-container-horizontal>.swiper-pagination-bullets {
			bottom: 0px;
			width: 100%!important;
		}

	.complete-dress .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
		border: 2px solid #000;
		width: 9px;
		height: 9px;
		background: unset;
	}

	#content ._404 {
		width: 100%;
	}

	#content ._404 h1 {
		width: 100%;
		margin-bottom: 10px;
	}

	#content ._404 h1 + .row {
		margin: 2rem 0!important;
	}

	#content ._404 h1 + .row .col-6 {
		width: 80%;
	}

	#content ._404 + .scl-product-list .scl-product {
		width: 49%;
		margin-right: 2%;
	}

	#content ._404 + .scl-product-list .scl-product .images {
		min-height: 190.2px
	}

	#content ._404 + .scl-product-list .scl-product:nth-child(2n) {
		margin-right: 0;
	}

	header.basket {
		height: 60px;
	}

	header.basket .scl-head-msg, header.basket .user-menu {
		display: none;
	}

	#basket h1.tabs {
		text-align: center;
	}

	#basket h1.tabs span, #basket h1.tabs span.active {
		font-size: 14px;
		padding: 5px 0;
		width: 30%;
	}

	#basket .choices-select .item img {
		display: inline-block;
	}

	#basket .choices-select .item {
		padding: 10px 0 10px 40px;
	}

	#basket .choices-select.installment {
		width: calc(50% - 20px);
	}

	#basket .basket-product-list .row:first-child {
		display: none;
	}

	#basket .basket-product-list .row.dashedTB div:nth-child(3) {
		position: absolute;
		top: 75px;
		right: -55px;
	}

	#basket .basket-product-list .row.dashedTB div:nth-child(4) {
		position: absolute;
		top: 20px;
		right: 20px;
	}

	#basket .basket-product-list .input-number {
		width: 60px;
	}

	#basket .basket-product-list .input-number input[type=number] {
		height: 26px;
	}

	#basket .basket-product-list .input-number .plus, #basket .basket-product-list .input-number .sour {
		line-height: 26px;
		padding: 0;
		width: 20px;
	}

	#basket .basket-product-list .input-number .plus > svg, #basket .basket-product-list .input-number .sour > svg {
		position: relative;
	}

	#basket .basket-product-list + .col {
		margin-top: 30px;
	}

	#basket .steps {
		margin: 0;
		padding: 15px 0;
	}

	#basket .steps .check {
		width: 30px;
		height: 30px;
		margin: 0 10px 0 0;
	}

	#basket .steps li {
		margin-right: 0;
		padding: 0 20px 0 0
	}

	#basket .steps li:last-child {
		padding: 0
	}

	#basket .address-select .address {
		width: 100%;
	}

	#basket .address-select .new-address {
		font-size: 18px;
	}

	#basket .basket-success h1 {
		padding-top: 40px;
	}

	#basket .credit-card {
		position: absolute;
		top: 5%;
		left: 50%;
		-webkit-transform: translateX(-50%);
		        transform: translateX(-50%);
		z-index: 10;
		display: none;
	}

	#basket .credit-card.active {
		display: block;
	}

	#basket .credit-card .close {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 30px;
		height: 30px;
		line-height: 30px;
		border-radius: 30px;
		background: #999;
		text-align: center;
	}

	#basket .mobile-fixed {
		width: 100%;
		background: white;
		position: fixed;
		bottom: 0;
		left: 0;
		padding: 10px;
		margin: 0;
		box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.2);
		transition: 300ms all;
		align-items: center;
	}

	#basket .mobile-fixed .price span {
		display: block;
	}

	#basket .mobile-fixed .price span:nth-child(2) {
		margin-top: 8px;
		font-weight: 500;
		font-size: 18px;
		color: var(--main-color)
	}

	#basket .basketMessage {
		font-size: 12px;
		line-height: 1.5em;
	}

	#basket .basketMessage .dangerCampaign {
		padding: 6px 20px 3px;
	}

	#basket .basketMessage .successCampaign {
		padding: 6px 20px;
	}

	.quickModal #detail .detail-right {
		padding: 0;
	}

	.quickModal .scl-modal .modal-content {
		max-height: 100%;
	}

	.ReactModal__Overlay {
		z-index: 1051!important
	}

	.scl-modal {
		width: 100%!important;
		max-width: 100%!important;
		margin: 0;
	}

	.last-enter .swiper-slide {
		max-width: 50%;
	}

	.last-enter h2.hr span {
		padding: 0;
	}

	.last-enter .lse-product-list .lse-item .img {
		height: calc((100vw - 40px) * .5 * 1.25);
		position: relative;
	}

	.last-enter .swiper-container {
		padding-bottom: 30px;
	}

	.last-enter .swiper-container-horizontal>.swiper-pagination-bullets {
		width: 100%!important;
	}

	#loginModal .scl-modal .modal-content {
		padding: 50px 20px 20px;
	}

	#sizeChartModal .modal-content {
		padding: 40px 10px;
	}

	#sizeChartModal .scl-modal .col {
		overflow: scroll;
	}

	#sizeChartModal .product-chart {
		white-space: nowrap;
		overflow-x: scroll;
		margin-bottom: 0;
		padding-bottom: 20px;
	}

	footer .container {
		padding: 30px 10px 20px
	}

	footer .container .row:first-child .col-6, footer .container .row:first-child .col-5 {
		width: 100%;
	}

	footer ul.help {
		text-align: left;
		margin: 10px 0 0;
		font-weight: 500;
		letter-spacing: .5px;
	}

	footer ul.help li {
		text-align: left;
		display: block;
		margin: 10px 0;
	}

	footer .social {
		text-align: center!important;
		margin-bottom: 40px;
		padding-top: 0!important;
	}

	.full-banner {
		margin-top: 0;
	}

	.full-banner h1 {
		font-size: 16px;
		width: 100%;
		top: 0px;
		text-shadow: 8px 4px 4px black, -8px -4px 4px black, 8px -4px 4px black, -8px 4px 4px black
	}

	.full-banner span {
		top: 30px;
		width: 100%;
		font-size: 13px;
	}

	.go-head {
		width: 36px;
		height: 36px;
		line-height: 36px;
		right: 10px;
		bottom: 75px;
	}
	.swal2-actions {
		padding: 0!important;
	}

	.banner h2 {
		font-size: 12px;
	}

	.banner h2 span {
		padding: 0 10px;
	}

	.banner.brands {
		background: #f3f3f3;
		font-size: 16px;
		padding: 2em 0 2.5em;
	}

	.banner.brands h1 {
		padding-bottom: 1.5em;
		font-size: 18px;
		letter-spacing: .3em;
	}

	.banner.one-click a {
		position: relative;
		background: #fff;
		display: block;
		padding: 20px;
		font-size: 14px;
		font-weight: 600;
		border-bottom: 2px solid #eee;
	}

	.banner.one-click a:first-child {
	    border-top: 2px solid #eee;
	}

	.banner.one-click svg {
		position: absolute;
		right: 20px;
		top: 50%;
		-webkit-transform: translateY(-50%) rotate(270deg);
		        transform: translateY(-50%) rotate(270deg);
		opacity: .8;
	}

	.occasion .occa {
		width: 45%;
		margin-right: 10%;
		border: none;
		margin-left: 3%;
	}

	.occasion .occa:nth-child(2n) {
		margin-left: 0;
		margin-right: 0;
		width: 42%
	}

	#ilmio-hashtag-slider .carousel-indicators li {
		width: 10px;
		height: 10px;
		border-top: 0;
		border-radius: 10px;
		border-bottom: 0;
	}

	.swiper-container {
		padding-bottom: 45px!important;
		min-height: 100%;
	}

	.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
		border: 2px solid #000;
		width: 9px;
		height: 9px;
		background: unset;
	}

	.swal2-modal .swal2-title {
		width: 100%;
	}

	.swal2-modal .swal2-styled {
		width: 100%;
	}

	.swal2-actions {
		margin-top: 0;
	}

	.swal2-content {
		padding: 0!important;
		font-size: 16px!important;
	}

	.swal2-validation-message {
		margin: 0 -1.2em!important;
	}

	.close-usermenu {
		color: #000;
		padding: 10px;
	}

	header .user-menu .close-usermenu > svg {
		margin-right: 0;
	}

	#ETBIS {
		position: absolute;
		bottom: 110px;
		right: 0;
	}

	@supports (-webkit-touch-callout: none) {
		#detail .detail-right .price {
			height: 80px;
		}
		#detail .detail-right .go-basket .col-2:last-child {
			bottom: 25px;
		}
		#detail .detail-right .go-basket .col-8 {
			bottom: 17px;
		}
	}
}

@media only screen and (max-width:385px) {

}

@media only screen and (max-width:330px) {

}

.modal_modal__1ZWEZ {
	background: #0005;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1050;
	text-align: center;
	vertical-align: middle;
	transition: .5s opacity;
	opacity: 0;
}

.modal_container__1TsVy {
	width: 50%;
	max-width: 100%;
	position: absolute;
	top: 0;
	left: 25%;
	max-height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 13px 53px;
	transition: 1s top;
}

.modal_container__1TsVy::-webkit-scrollbar{
	background: #eee;
	width: 10px;
	height: 5px;
}

.modal_container__1TsVy::-webkit-scrollbar-thumb {
	cursor: pointer;
	background: #6e6e6e;
}

.modal_container__1TsVy::-webkit-scrollbar-thumb:hover {
	background: #3e3e3e;
	cursor: pointer!important;
}

.modal_container__1TsVy.modal_mini__1UlZm {
	width: 610px;
	left: calc((100% - 610px) / 2)
}

.modal_container__1TsVy.modal_large__3Rewz {
	width: 64%;
	left: 18%
}

.modal_container__1TsVy.modal_full__d4P-m {
	width: 100%;
	left: 0
}

.modal_content__WIbjy {
	background: white;
	padding: 40px 40px 20px;
}

.modal_close__2Sdp2 {
	background: #000;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	position: absolute;
	top: 0;
	right: 40px;
	color: #fff;
	font-size: 26px;
	cursor: pointer;
}

@media screen and (max-width: 768px) {
	.modal_container__1TsVy, .modal_container__1TsVy.modal_mini__1UlZm, .modal_container__1TsVy.modal_large__3Rewz, .modal_container__1TsVy.modal_full__d4P-m {
		width: 100%;
		left: 0;
		padding: 0;
	}

	.modal_close__2Sdp2 {
		right: 0;
	}

	.modal_content__WIbjy {
		padding: 40px 10px;
	}
}

.groupSlider_groupSlider__373y6 {

}

.groupSlider_sliderProduct__25Dgf {
	width: calc(25% - 7.5px)!important;
	margin-right: 10px!important;
}

.groupSlider_sliderProduct__25Dgf:last-child {
	margin-right: 0px!important;
}

.groupSlider_sliderProduct__25Dgf.groupSlider__1__2_KSw {
	width: calc(50% - 5px)!important;
}

.homeText_main__3mUR6 {
	text-align: center;
	font-size: 18px;
	font-weight: 600;
}

@media screen and (max-width: 768px) {
	.homeText_main__3mUR6 {
		font-size: 12px;
	}
}

.homeTrends_col__2P5TL {
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.homeTrends_col__2P5TL:nth-child(1) {
	width: calc(50% - ( 100% / 77.95))
}

.homeTrends_col__2P5TL:nth-child(2) {
	width: 50%;
}

.homeTrends_col__2P5TL>*:nth-child(2) {
}

.sliderText_slider__ELl8d {
	text-align: center;
	padding: 8px 0 20px!important;
	font-weight: 500;
}

.sliderText_slider__ELl8d > div:nth-child(1) {
	bottom: 0!important;
	padding: 0!important
}

.sliderText_slider__ELl8d > div:nth-child(1) > span {
	width: 6px;
	height: 6px;
	margin: 0 2px!important;
}

.sliderText_slider__ELl8d .swiper-pagination-bullets .swiper-pagination-bullet {
	border: none;
	width: 5px;
	height: 5px;
	background: #000;
}

.tooltipPlusButton_area__1Sr-I {
	position: absolute;
	top: 135px;
	left: calc(50% + 40px);
	width: 100%;
	height: 100%;
}

.tooltipPlusButton_button__fgt97 {
	width: 26px;
	height: 26px;
	line-height: 24px;
	text-align: center;
	font-size: 12px;
	color: #fff;
	display: block;
	cursor: pointer;
	position: relative;
}

.tooltipPlusButton_button__fgt97 span {
	width: 100%;
	height: 100%;
	background: black;
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 11;
}

.tooltipPlusButton_button__fgt97::after,
.tooltipPlusButton_button__fgt97::before {
	content: '';
	background: rgb(255,255,255);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	border-radius: 50%;
}

.tooltipPlusButton_button__fgt97::after {
	-webkit-animation: tooltipPlusButton_animate1__2qqHA 5s infinite;
	        animation: tooltipPlusButton_animate1__2qqHA 5s infinite;
}

.tooltipPlusButton_button__fgt97::before {
	-webkit-animation: tooltipPlusButton_animate2__Lq0TB 5s infinite;
	        animation: tooltipPlusButton_animate2__Lq0TB 5s infinite;
}

.tooltipPlusButton_text__2TSZ0 {
	position: absolute;
	top: -1rem;
	width: 40%;
	padding: 5px 15px;
	font-size: 15px;
	background: white;
	color: black;
	line-height: 1.3em;
	letter-spacing: .4px;
	border-radius: 4px;
	display: block;
	border: 1px solid #ddd;
	z-index: 12;
}

.tooltipPlusButton_close__13y_r {
	float: right;
	cursor: pointer;
}

.tooltipPlusButton_title__1Vb7K {
	font-weight: 500;
	font-size: 1.3em;
	margin-bottom: 0;
}

.tooltipPlusButton_left__1rDUm {
	left: -41%;
}

.tooltipPlusButton_right__GrEge {
	left: 50px;
}

@-webkit-keyframes tooltipPlusButton_animate1__2qqHA {
	0% {
		opacity: 1;
		-webkit-transform: scaleY(1) scaleX(1);
		        transform: scaleY(1) scaleX(1);
	}

	20% {
		opacity: .7;
	}

	80% {
		opacity: .4;
		-webkit-transform: scaleY(1.6) scaleX(1.6);
		        transform: scaleY(1.6) scaleX(1.6);
	}

	90% {
		opacity: 0;
		-webkit-transform: scaleY(1) scaleX(1);
		        transform: scaleY(1) scaleX(1);
	}
}

@keyframes tooltipPlusButton_animate1__2qqHA {
	0% {
		opacity: 1;
		-webkit-transform: scaleY(1) scaleX(1);
		        transform: scaleY(1) scaleX(1);
	}

	20% {
		opacity: .7;
	}

	80% {
		opacity: .4;
		-webkit-transform: scaleY(1.6) scaleX(1.6);
		        transform: scaleY(1.6) scaleX(1.6);
	}

	90% {
		opacity: 0;
		-webkit-transform: scaleY(1) scaleX(1);
		        transform: scaleY(1) scaleX(1);
	}
}

@-webkit-keyframes tooltipPlusButton_animate2__Lq0TB {
	0% {
		opacity: 1;
		-webkit-transform: scaleY(1) scaleX(1);
		        transform: scaleY(1) scaleX(1);
	}

	20% {
		opacity: .7;
	}

	80% {
		opacity: .4;
		-webkit-transform: scaleY(1.2) scaleX(1.2);
		        transform: scaleY(1.2) scaleX(1.2);
	}

	90% {
		opacity: 0;
		-webkit-transform: scaleY(1) scaleX(1);
		        transform: scaleY(1) scaleX(1);
	}
}

@keyframes tooltipPlusButton_animate2__Lq0TB {
	0% {
		opacity: 1;
		-webkit-transform: scaleY(1) scaleX(1);
		        transform: scaleY(1) scaleX(1);
	}

	20% {
		opacity: .7;
	}

	80% {
		opacity: .4;
		-webkit-transform: scaleY(1.2) scaleX(1.2);
		        transform: scaleY(1.2) scaleX(1.2);
	}

	90% {
		opacity: 0;
		-webkit-transform: scaleY(1) scaleX(1);
		        transform: scaleY(1) scaleX(1);
	}
}

@media screen and (max-width: 768px) {
	.tooltipPlusButton_text__2TSZ0 {
		width: 100%;
		left: 0%!important;
		position: fixed;
		bottom: 15px;
		top: auto;
	}
}

.combineModals_conatiner__2CxpW {
	width: 100%;
	padding-top: 30px;
	padding-bottom: 20px;
	overflow: hidden;
}

.combineModals_title__3VMXO {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	letter-spacing: .2em;
	font-weight: 600;
	color: var(--main-grey);
	text-align: center;
}

.combineModals_conatiner__2CxpW .scl-product.combine .look-modal {
	bottom: -15px;
}

.combineModals_combine__3Ttq_ {
	position: relative;
	margin-right: 20px;
}

@media screen and (max-width: 768px) {
	.combineModals_conatiner__2CxpW .scl-product.combine .look-modal {
		bottom: -10px;
	}
}

.productGetSearch_groupSlider__3Jz6D .swiper-container {
	padding-bottom: 50px;
	margin-bottom: 30px;
}

.productGetSearch_title__37JQK {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	letter-spacing: .2em;
	font-weight: 600;
	color: var(--main-grey);
	text-align: center;
}

.productGetSearch_sliderProduct__1V55N {
	width: 20%;
	padding: 5px;
}

.productGetSearch_sliderProduct__1V55N .old {
	display: inline-block;
	margin-right: 10px;
	position: relative;
	padding: 0 5px;
}

.productGetSearch_sliderProduct__1V55N .old::before {
	content: '';
	background: rgba(0,0,0,0.5);
	width: 100%;
	height: 1px;
	position: absolute;
	left: 0;
	top: 50%;
	-webkit-transform: translateY(-45%);
	        transform: translateY(-45%);
}

.productGetSearch_sliderProduct__1V55N .old + span {
	display: inline-block;
	margin-top: 8px;
}

.productGetSearch_sliderProduct__1V55N:hover img {
	-webkit-transform: scale(1.02);
	        transform: scale(1.02);
	transition: 700ms;
}

.productGetSearch_sliderProduct__1V55N span {
	display: block;
	margin-top: 5px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.productGetSearch_sliderProduct__1V55N span:last-child {
	font-weight: 600;
	font-size: 15px;
}

.productGetSearch__1__3S09T {
	width: calc(50% - 5px);
}

.productDiscovery_groupSlider__3yoBU .swiper-container {
	padding-bottom: 50px;
	margin-bottom: 30px;
}

.productDiscovery_title__SdS9p {
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	letter-spacing: .2em;
	font-weight: 600;
	color: var(--main-grey);
	text-align: center;
}

.productDiscovery_sliderProduct__3LKBS {
	width: 10%;
	padding: 5px;
}

.productDiscovery_sliderProduct__3LKBS:hover img {
	-webkit-transform: scale(1.02);
	        transform: scale(1.02);
	transition: 700ms;
}

.productDiscovery_sliderProduct__3LKBS span {
	display: block;
	margin-top: 10px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.productDiscovery_sliderProduct__3LKBS span:nth-child(3) {
	font-weight: 600;
	font-size: 15px;
}

.productDiscovery__1__3LBwU {
	width: calc(50% - 5px);
}

.addCoupon_addcoupon__2TzGw {
	font-weight: 600;
	padding: 5px 10px 0;
	margin-bottom: -1em;
	margin: 15px -30px 0;
	border-top: 1px solid #eee;
}

.addCoupon_coupons__1JL2Q {
	margin: 10px;
}

.addCoupon_coupons__1JL2Q span {
	padding: 10px;
	margin: 5px;
	background: #eee;
	color: #777;
	cursor: default;
	display: inline-block;
}

.addCoupon_coupons__1JL2Q span svg {
	background: var(--main-red);
	color: #fff;
	margin-left: 5px;
	border-radius: 50%;
	opacity: .8;
	cursor: pointer;
}

.addCoupon_coupons__1JL2Q span svg:hover {
	opacity: 1;
}

.addCoupon_title__AVLXc {
	padding: 10px 0;
	display: block;
	opacity: .8;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.addCoupon_title__AVLXc:hover {
	opacity: 1;
}

.addCoupon_title__AVLXc.addCoupon_active__Jnh3J s{

}

.addCoupon_title__AVLXc svg {
	font-size: 2.2em;
	float: right;
}

.addCoupon_close__2uYwn {
	display: block;
	font-size: 1.2em;
	height: 30px;
	line-height: 30px;
	text-align: center;
}

.basketGroupList_products__1vwQo {
	border-top: 2px solid #eee;
	padding: 10px 0;
	margin-top: 50px;
}

.basketGroupList_products__1vwQo .basketGroupList_swiper-container__3c2Ne {
	padding: 10px 0!important;
}

.basketGroupList_products__1vwQo .swiper-button-prev, .basketGroupList_products__1vwQo .swiper-button-next {
	width: 34px!important;
	height: 34px!important;
}

.basketGroupList_products__1vwQo .swiper-button-prev::after, .basketGroupList_products__1vwQo .swiper-button-next::after {
	font-size: 12px;
}

.basketGroupList_product__349B0 {
	width: 90%;
	margin: 0 5% 0 0;
	padding: 5px;
	border: 1px solid #eee;
	box-shadow: 2px 3px 7px rgba(0, 0, 0, .1);
	border-radius: 3px;
	position: relative;
}

.basketGroupList_title__2BLqy {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 100%;
}

.basketGroupList_product__349B0 img {
	aspect-ratio: 12 / 15;
	margin-bottom: 10px;
}

.basketGroupList_price__2ylsC {
	margin-top: 10px;
	display: inline-block;
}
.basketGroupList_price__2ylsC .old {
	text-decoration: line-through;
	margin-right: 10px;
}

.basketGroupList_promotion__2clW9 {
	margin-top: 5px;
	padding: 3px;
	border-radius: 5px;
	font-size: .9em;
	color: white;
	background: orange;
	display: inline-block;
}

.basketGroupList_add__j3ISU {
	display: block;
	text-align: center;
	cursor: pointer;
}

.basketGroupList_add__j3ISU span:nth-child(1){
	background: black;
	color: white;
	height: 30px;
	line-height: 30px;
	border-radius: 5px;
	padding: 0 10px;
	display: inline-block;
	position: relative;
	bottom: -15px;
	transition: 300ms;
}

.basketGroupList_add__j3ISU span:nth-child(1):hover{
	background: var(--main-hover);

}

.basketGroupList_colors__3VrER {
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
}

.basketGroupList_color__2Ppun {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin: 0 8px 15px;
	cursor: pointer;
	position: relative;
}

.basketGroupList_color__2Ppun::before {
	content: '';
	border: 1px solid #ccc;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.basketGroupList_color__2Ppun.basketGroupList_active__-ow5G::before {
	border: 1px solid #000;
}

.basketGroupList_sizes__2MRtr {
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
	margin-bottom: 40px;
}

.basketGroupList_colors__3VrER + h2 {
	margin-top: 20px;
}

.basketGroupList_size__TfKMM {
	width: 30px;
	height: 30px;
	line-height: 30px;
	margin: 5px 10px;
	background: #eee;
	border-radius: 50%;
	text-align: center;
	color: #000;
	cursor: pointer;
	position: relative;
}

.basketGroupList_size__TfKMM::before {
	content: '';
	border: 1px solid #ccc;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.basketGroupList_size__TfKMM.basketGroupList_active__-ow5G::before {
	border: 1px solid #000;
}

.basketGroupList_nonStock__1Cn1B {
	position: relative;
	opacity: .5;
	background: #eee;
}

.basketGroupList_nonStock__1Cn1B::before {
	content: '';
	position: absolute;
	left: 0;
	top: 50%;
	height: 1px;
	width: 100%;
	background: black;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
}

.basketGroupList_list__1FaqM {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.basketGroupList_listitem__2sfBj {
	margin: 20px 0;
	width: 30%;
}
@media screen and (max-width: 768px) {
	.basketGroupList_listitem__2sfBj {
		margin: 20px 0;
		width: 50%;
	}
}

.phoneSmsCode_counter___qVbm {
	font-size: 15px;
	margin-top: 10px;
	text-align: left;
}

.basketOrderInfo_orderInfo__2bxDY {
	display: flex;
	justify-content: space-between;
	margin: 50px 0;
	padding: 0 50px;
}

.basketOrderInfo_orderCode__MhQMR {}

.basketOrderInfo_orderInfo__2bxDY > div > span {
	font-weight: 500;
	text-align: left;
	display: block;
}

.basketOrderInfo_orderInfo__2bxDY > div > span:nth-child(1) {
	color: var(--main-green);
	margin-bottom: 5px;
	text-transform: uppercase;
}

.basketOrderInfo_orderInfo__2bxDY span:nth-child(2) > span {
	display: block;
}

.basketOrderInfo_orderCode__MhQMR span:nth-child(2) {
	font-size: 24px;
	font-weight: 500;
}

@media screen and (max-width: 768px) {
	.basketOrderInfo_orderInfo__2bxDY {
		flex-wrap: wrap;
		margin-top: 0px;
		padding: 0;
	}

	.basketOrderInfo_orderInfo__2bxDY > div > span {
		text-align: center;
	}

	.basketOrderInfo_col__kVfWk {
		justify-content: center;
		width: 100%;
		margin-top: 20px;
	}
}

.product_product__3Dydu {
	position: relative;
	width: 100%;
	padding: 10px;
	margin: 10px 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	cursor: pointer;
	border-radius: 5px;
	border: 2px solid #ccc;
	transition: 300ms;
}

.product_product__3Dydu:hover {
	border-color: #aaa;
}

.product_product__3Dydu.product_active__1Kymc {
	border-color: var(--main-hover);
}

.product_product__3Dydu::before {
	content: '';
	border: 2px solid #ccc;
	width: 20px;
	height: 20px;
	position: absolute;
	top: 10px;
	right: 10px;
	color: #fff;
	font-size: 20px;
	transition: 300ms;
	z-index: 5;
}

.product_product__3Dydu:not(.product_active__1Kymc):hover::before  {
	border-color: var(--main-hover);
	opacity: .7;
}

.product_product__3Dydu.product_active__1Kymc::before {
	border-color: var(--main-hover);
}

.product_product__3Dydu.product_active__1Kymc::after {
	content: '✓';
	color: var(--main-hover);
	position: absolute;
	font-weight: 600;
	font-size: 16px;
	right: 14px;
	top: 12.5px;
	border-radius: 50%;
}

.product_product__3Dydu .product_img__QJLil {
	width: 17%;
	float: left;
	margin-right: 10px;
}

.product_product__3Dydu .product_info__1XsIJ {
	width: calc(76% - 10px);
}

.product_product__3Dydu .product_info__1XsIJ > span:first-child {
	line-height: 1.5em;
}

.product_product__3Dydu .product_info__1XsIJ > span {
	display: block;
	margin-bottom: 5px;
	text-align: left;
}

.product_product__3Dydu .product_info__1XsIJ > span > span {
	float: left;
	margin-right: 10px;
	margin-top: 2px;
}

.product_select__dEpQu {
	width: 90%;
	margin-top: 1.5em;
	margin-bottom: -1.5em;
}

.product_select__dEpQu label {
	text-align: left;
}

.product_select__dEpQu .scl-select {
	position: unset;
	height: 40px;
	line-height: 40px;
}

.product_select__dEpQu .scl-select-options {
	height: 100%;
	top: 0px;
	border: none;
}

.iban_iban__1HNf5 {
	border: 2px solid #ccc;
	flex-basis: 30%;
	padding: 10px;
	margin: 10px 15px 10px 0;
	border-radius: 5px;
	text-align: left;
}

.iban_iban__1HNf5 .iban_ibanTitle__2V0gc {
	font-size: 1.1em;
	font-weight: 500;
	margin-top: 0;
}

.iban_iban__1HNf5 span {
	display: block;
	margin: 15px 0 0;
}

.iban_delete__2p750 {
	float: right;
	color: var(--main-red);
	cursor: pointer;
}

.iban_delete__2p750:hover {
	text-decoration: underline;
}

.iban_iban__1HNf5.iban_checking__3bzcK {
	position: relative;
	cursor: pointer;
}

.iban_iban__1HNf5 .iban_check__1y1J5 {
	width: 20px;
	height: 20px;
	border: 2px solid var(--main-grey);
	border-radius: 50%;
	position: absolute;
	top: -8px;
	right: 8px;
}

.iban_iban__1HNf5.iban_active__3Iwx4 .iban_check__1y1J5 {
	border-color: var(--main-hover);
}

.iban_iban__1HNf5 .iban_check__1y1J5::before {
	content: '';
	background: var(--main-hover);
	width: 10px;
	height: 10px;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	opacity: 0;
}
.iban_iban__1HNf5:hover .iban_check__1y1J5::before {
	opacity: .5;
}

.iban_iban__1HNf5.iban_active__3Iwx4 .iban_check__1y1J5::before {
	opacity: 1;
}

.ibanList_ibans__2r6WC {
	display: flex;
	flex-wrap: wrap;
}

.nonItem_nonItem__2YiT4 {
	width: 100%;
	min-height: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.nonItem_nonItem__2YiT4 .nonItem_container__2ulKK {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 400px;
}

.nonItem_nonItem__2YiT4 span {
	font-size: 1.3em;
	font-weight: 500;
	margin: 20px;
	display: block;
}

.nonItem_nonItem__2YiT4 svg {
	font-size: 4em;
}

.ibans_container__1C7D0 h1 {
	color: #000!important;
}

.ibans_container__1C7D0 h2 {
	color: #000!important;
	font-size: 1.6em;
	font-weight: 600;
}

.login_tabs__RUdtt span {
	width: 50%;
	border-bottom: 2px solid #eee;
	display: inline-block;
	padding: 10px 0 15px;
	letter-spacing: .5px;
	cursor: pointer;
	color: black;
	font-size: 14px;
}

.login_tabs__RUdtt span.login_active__14L9Q {
	border-bottom-color: black;
}

.login_login__3_nMP h2 {
	color: black;
	margin: 20px 0;
	font-size: 20px
}

.login_login__3_nMP label {
	text-align: left;
}

.socialLoginButtons_social__2iW1j {
	display: flex;
	justify-content: space-around;
	padding: 10px 0 5px;
}

.socialLoginButtons_social__2iW1j .btn {
	background: transparent;
	color: black;
	margin: 0 12px;
	border-radius: 6px;
	border: 2px solid #ccc;
	color: #999;
	min-width: 45%;
	font-weight: 500;
	font-size: 14px;
	transition: 500ms all;
	padding: 8px;
}

.socialLoginButtons_social__2iW1j .btn:hover {
	border: 2px solid #666;
	color: #333;
}

.socialLoginButtons_social__2iW1j svg {
	margin-right: 10px;
}

.socialLoginButtons_hr__3lKZ5 {
	height: 30px;
	margin: 10px 10px 20px;
	position: relative;
	text-align: center;
	display: block;
}

.socialLoginButtons_hr__3lKZ5::before {
	content: '';
	background: #ccc;
	width: 100%;
	height: 1px;
	position: absolute;
	top: 50%;
	left: 0;
}

.socialLoginButtons_hr__3lKZ5 span {
	background: white;
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
	position: absolute;
	top: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.forgotPassword_forgotPassword__2_l2j {
	line-height: 1.6
}

.forgotPassword_forgotPassword__2_l2j h2 {
	color: black;
	margin: 0 0 20px;
	font-size: 20px
}

.forgotPassword_forgotPassword__2_l2j label {
	text-align: left;
	margin-top: 30px;
}

.forgotPassword_tabs__3N4UZ {
	margin-top: 30px;
	margin-bottom: 20px;
	justify-content: start;
	display: flex;
}

.forgotPassword_tabs__3N4UZ span {
	padding-left: 40px;
	margin-right: 30px;
	position: relative;
	cursor: pointer;
	transition: 500ms;
}

.forgotPassword_tabs__3N4UZ span:hover {
	color: #000;
}

.forgotPassword_tabs__3N4UZ span:before {
	content: '';
	border-radius: 50%;
	width: 20px;
	height: 20px;
	border: 2px solid #ccc;
	position: absolute;
	left: 10px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.forgotPassword_tabs__3N4UZ span.forgotPassword_active__1zQWu {
	font-weight: 600;
}

.forgotPassword_tabs__3N4UZ span.forgotPassword_active__1zQWu:after {
	content: '';
	border-radius: 50%;
	width: 10px;
	height: 10px;
	background: var(--main-green);
	position: absolute;
	left: 15px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.forgotPassword_tabs__3N4UZ span.forgotPassword_active__1zQWu::before {
	border-color: var(--main-green);
}

.subscribe_title__1EbK5 {
	font-size: 2.4em;
	margin-bottom: 1em;
}

.subscribe_subscribeContainer__24MNt {
	justify-content: center;
	align-items: center;
	height: 500px;
}

.subscribe_checkLabel__17_bc {
	position: relative;
	padding-left: 35px;
	margin: 0 5px;
	width: 100%;
	text-align: left;
	display: block;
	float: left;
	padding: 0;
	padding-left: 30px;
	cursor: pointer;
}

.subscribe_checked__t7kA_ {
	padding: 0;

}

.subscribe_success__1oFLc {
	background: var(--main-green);
	color: #fff;
	padding: 20px 0;
	font-weight: 500;
	font-size: 1.5em;
	text-align: center;
	border-radius: 5px;
}


.subscribe_tick__1Bt1K {
	background: #f8f8f8;
	width: 50px;
	height: 50px;
	color: var(--main-green);
	margin-right: 30px;
	border-radius: 50%;
}

.radioButton_button__1lyGq {
	position: relative;
	cursor: pointer;
	padding: 5px 10px 5px 30px;
	text-align: left;
}

.radioButton_check__3yf_r {
	position: absolute;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	border: 2px solid #ccc;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.radioButton_button__1lyGq:hover .radioButton_check__3yf_r {
	border-color: var(--main-hover);
	opacity: .7;
}

.radioButton_button__1lyGq.radioButton_active__X7Dot .radioButton_check__3yf_r {
	border-color: var(--main-hover);
}

.radioButton_button__1lyGq.radioButton_active__X7Dot .radioButton_check__3yf_r::before {
	content: '';
	background: var(--main-hover);
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.radioButton_button__1lyGq.radioButton_active__X7Dot .radioButton_check__3yf_r::before {
	border-radius: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.sliderText_slider__1o4Pt {
	padding: 0!important;
	height: 40px;
}

.sliderText_slider__1o4Pt a {
	color: #fff;
}

.question_tabs__slIpw {
	margin-top: 20px;
	margin-bottom: 20px;
	justify-content: start;
	display: flex;
}

.question_tabs__slIpw span {
	padding-left: 40px;
	margin-right: 30px;
	position: relative;
	cursor: pointer;
	transition: 500ms;
}

.question_tabs__slIpw span:hover {
	color: #000;
}

.question_tabs__slIpw span:before {
	content: '';
	border-radius: 50%;
	width: 20px;
	height: 20px;
	border: 2px solid #ccc;
	position: absolute;
	left: 10px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.question_tabs__slIpw span.question_active__c2yBe {
	font-weight: 600;
}

.question_tabs__slIpw span.question_active__c2yBe:after {
	content: '';
	border-radius: 50%;
	width: 10px;
	height: 10px;
	background: var(--main-green);
	position: absolute;
	left: 15px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
}

.question_tabs__slIpw span.question_active__c2yBe::before {
	border-color: var(--main-green);
}

.login_tabs__3Vebf span {
	width: 50%;
	border-bottom: 2px solid #eee;
	display: inline-block;
	padding: 10px 0 15px;
	letter-spacing: .5px;
	cursor: pointer;
	color: black;
	font-size: 14px;
}

.login_tabs__3Vebf span.login_active__8RLak {
	border-bottom-color: black;
}

.login_guest__2PR4y {
	line-height: 1.6;
	margin-top: 20px;
	text-align: center;
}

.login_guest__2PR4y span {
	width: 90%;
	border-top: 2px solid #eee;
	color: var(--main-grey);
	font-size: 16px;
	text-transform: uppercase;
	padding-top: 17px;
	cursor: pointer;
	display: inline-block;
}

.login_guest__2PR4y span:hover {
	color: var(--main-color);
}

.register_register__1Df5r h2 {
	color: black;
	margin: 20px 0;
	font-size: 20px
}

.register_register__1Df5r label {
	text-align: left;
}

