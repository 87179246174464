.area {
	position: absolute;
	top: 135px;
	left: calc(50% + 40px);
	width: 100%;
	height: 100%;
}

.button {
	width: 26px;
	height: 26px;
	line-height: 24px;
	text-align: center;
	font-size: 12px;
	color: #fff;
	display: block;
	cursor: pointer;
	position: relative;
}

.button span {
	width: 100%;
	height: 100%;
	background: black;
	border-radius: 50%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 11;
}

.button::after,
.button::before {
	content: '';
	background: rgb(255,255,255);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	border-radius: 50%;
}

.button::after {
	animation: animate1 5s infinite;
}

.button::before {
	animation: animate2 5s infinite;
}

.text {
	position: absolute;
	top: -1rem;
	width: 40%;
	padding: 5px 15px;
	font-size: 15px;
	background: white;
	color: black;
	line-height: 1.3em;
	letter-spacing: .4px;
	border-radius: 4px;
	display: block;
	border: 1px solid #ddd;
	z-index: 12;
}

.close {
	float: right;
	cursor: pointer;
}

.title {
	font-weight: 500;
	font-size: 1.3em;
	margin-bottom: 0;
}

.left {
	left: -41%;
}

.right {
	left: 50px;
}

@keyframes animate1 {
	0% {
		opacity: 1;
		transform: scaleY(1) scaleX(1);
	}

	20% {
		opacity: .7;
	}

	80% {
		opacity: .4;
		transform: scaleY(1.6) scaleX(1.6);
	}

	90% {
		opacity: 0;
		transform: scaleY(1) scaleX(1);
	}
}

@keyframes animate2 {
	0% {
		opacity: 1;
		transform: scaleY(1) scaleX(1);
	}

	20% {
		opacity: .7;
	}

	80% {
		opacity: .4;
		transform: scaleY(1.2) scaleX(1.2);
	}

	90% {
		opacity: 0;
		transform: scaleY(1) scaleX(1);
	}
}

@media screen and (max-width: 768px) {
	.text {
		width: 100%;
		left: 0%!important;
		position: fixed;
		bottom: 15px;
		top: auto;
	}
}
