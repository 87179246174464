.addcoupon {
	font-weight: 600;
	padding: 5px 10px 0;
	margin-bottom: -1em;
	margin: 15px -30px 0;
	border-top: 1px solid #eee;
}

.coupons {
	margin: 10px;
}

.coupons span {
	padding: 10px;
	margin: 5px;
	background: #eee;
	color: #777;
	cursor: default;
	display: inline-block;
}

.coupons span svg {
	background: var(--main-red);
	color: #fff;
	margin-left: 5px;
	border-radius: 50%;
	opacity: .8;
	cursor: pointer;
}

.coupons span svg:hover {
	opacity: 1;
}

.title {
	padding: 10px 0;
	display: block;
	opacity: .8;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title:hover {
	opacity: 1;
}

.title.active s{

}

.title svg {
	font-size: 2.2em;
	float: right;
}

.close {
	display: block;
	font-size: 1.2em;
	height: 30px;
	line-height: 30px;
	text-align: center;
}
