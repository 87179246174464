.tabs span {
	width: 50%;
	border-bottom: 2px solid #eee;
	display: inline-block;
	padding: 10px 0 15px;
	letter-spacing: .5px;
	cursor: pointer;
	color: black;
	font-size: 14px;
}

.tabs span.active {
	border-bottom-color: black;
}

.guest {
	line-height: 1.6;
	margin-top: 20px;
	text-align: center;
}

.guest span {
	width: 90%;
	border-top: 2px solid #eee;
	color: var(--main-grey);
	font-size: 16px;
	text-transform: uppercase;
	padding-top: 17px;
	cursor: pointer;
	display: inline-block;
}

.guest span:hover {
	color: var(--main-color);
}
