.product {
	position: relative;
	width: 100%;
	padding: 10px;
	margin: 10px 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	cursor: pointer;
	border-radius: 5px;
	border: 2px solid #ccc;
	transition: 300ms;
}

.product:hover {
	border-color: #aaa;
}

.product.active {
	border-color: var(--main-hover);
}

.product::before {
	content: '';
	border: 2px solid #ccc;
	width: 20px;
	height: 20px;
	position: absolute;
	top: 10px;
	right: 10px;
	color: #fff;
	font-size: 20px;
	transition: 300ms;
	z-index: 5;
}

.product:not(.active):hover::before  {
	border-color: var(--main-hover);
	opacity: .7;
}

.product.active::before {
	border-color: var(--main-hover);
}

.product.active::after {
	content: '✓';
	color: var(--main-hover);
	position: absolute;
	font-weight: 600;
	font-size: 16px;
	right: 14px;
	top: 12.5px;
	border-radius: 50%;
}

.product .img {
	width: 17%;
	float: left;
	margin-right: 10px;
}

.product .info {
	width: calc(76% - 10px);
}

.product .info > span:first-child {
	line-height: 1.5em;
}

.product .info > span {
	display: block;
	margin-bottom: 5px;
	text-align: left;
}

.product .info > span > span {
	float: left;
	margin-right: 10px;
	margin-top: 2px;
}

.select {
	width: 90%;
	margin-top: 1.5em;
	margin-bottom: -1.5em;
}

.select label {
	text-align: left;
}

.select :global .scl-select {
	position: unset;
	height: 40px;
	line-height: 40px;
}

.select :global .scl-select-options {
	height: 100%;
	top: 0px;
	border: none;
}
